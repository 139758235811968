import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { useHistory, useLocation } from "react-router-dom";
import { DisappearedLoading } from "react-loadingg";
import { ToastContainer, toast } from "react-toastify";
import { setLayoutData, setCompStyleData, setTemlpateData, resetState } from "../../../store/templateEditorData";
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import './component/EmailTemplate.scss';
import { SideBarComponent } from "./component/SideBarComponent";
import CanvasComponent from "./component/CanvasComponent";
import PreviewModal from "./component/TemplatePreview/TemplatePreview";

const Add_Mail_template = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { state } = location.state || {};
  const dispatch = useDispatch();

  const { templateLayoutData, parentCompStyle, templateData, childCompStyle } = useSelector((state) => state.emailTemplateEditorData);

  const [previewModal, setPreviewModal] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateId, setTemplateId] = useState(props.match.params.template_id);
  const [loading, setLoading] = useState(false);

  const togglePreModal = () => { setPreviewModal(!previewModal) }

  const getTemplateDetails = async () => {
    let postdata = { template_id: templateId };
    setLoading(true);
    client.post(api.get_mail_templates_details, postdata, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              let templateDetail = { ...response.result.contactDetails };
              delete templateDetail.rawLayoutDatas;
              dispatch(setTemlpateData(templateDetail));
              dispatch(setLayoutData(response.result.contactDetails.rawLayoutData.templateLayoutData));
              dispatch(setCompStyleData({ type: "outercolor", data: response.result.contactDetails.rawLayoutData.parentCompStyle }));
              dispatch(setCompStyleData({ type: "innercolor", data: response.result.contactDetails.rawLayoutData.childCompStyle }));
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (templateId) {
      getTemplateDetails();
    }

  }, [templateId])


  useEffect(() => {
    if (state && !templateData) {
      dispatch(setTemlpateData(state));
    }
  }, [state])


  const generateHtmlCode = (delay = 1000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const canvas = document.getElementById("export_canvas_data");
        const inlineStyle = canvas.getAttribute("style") || "";

        if (!canvas) {
          console.error("Canvas not found!");
          return;
        }

        const htmlContent = `
                    <!DOCTYPE html>
                    <html lang="en">
                        <head>                                       
                            <meta charset="UTF-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <title>Email Template Preview</title>
                            <style>
                                body {
                                    font-family: Arial, sans-serif;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #f5f5f5;
                                }

                                /* #generate-canvas-data {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: center;
                                }

                                .single_column {
                                    display: grid !important;
                                    grid-template-columns: 100% !important;
                                    gap: 0px;
                                }

                                .two_column {
                                    display: grid !important;
                                    grid-template-columns: 50% 50% !important;
                                    gap: 0px;
                                }

                                .three_column {
                                    display: grid !important;
                                    grid-template-columns: repeat(3, 1fr) !important;
                                    gap: 0px;
                                }

                                @media only screen and (max-width: 640px) {
                                    #generate-canvas-data {
                                        width: 100%;
                                        padding: 0;
                                    }
                                    div[style*="grid-template-columns"] {
                                        grid-template-columns: 100% !important;
                                    }
                                } */
                            </style>
                        </head>
                        <body>
                            <div id="generate-canvas-data" class="generated-content" style="${inlineStyle}">
                                ${canvas.innerHTML}
                            </div>
                        </body>
                    </html>
                `;

        resolve(htmlContent);
      }, delay);
    });
  };

  const updatedTemplate = async () => {

    setTemplateLoading(true);

    const getTemplateCode = await generateHtmlCode(2000);

    let postData = {
      template_id: templateData._id,
      templ_layout: Buffer.from(getTemplateCode).toString('base64'),
      rawLayoutData: {
        templateLayoutData,
        parentCompStyle,
        childCompStyle
      }
    };

    client.post(api.update_mail_template_layout, postData, async function (error, response) {
      if (!error) {

        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {

              toast.success("Updated Successfully");

            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
      }
      setTemplateLoading(false);
    });
  }

  const saveTemplate = async () => {

    if (templateLayoutData.length > 0) {

      try {
        setTemplateLoading(true);

        const getTemplateCode = await generateHtmlCode(2000); // Wait for HTML generation

        if (!getTemplateCode) {
          console.error("Failed to generate HTML.");
          return;
        }

        let postData = {
          ...templateData,
          templ_layout: getTemplateCode,
          isNewTemplate: true,
          rawLayoutData: {
            templateLayoutData,
            parentCompStyle,
            childCompStyle
          }
        }
        console.log(postData, "postData");
        client.post(api.add_new_mail_template, postData, async function (error, response) {
          if (!error) {
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                  toast.success(response.message);
                  setTemplateLoading(false);
                } else {
                  toast.error(response.message);
                  setTemplateLoading(false);
                }
              } catch (e) {
                toast.error("Internal error occured. Please contact support");
                setTemplateLoading(false);
              }
            } else {
              toast.error("Internal error occured. Please contact support");
              setTemplateLoading(false);
            }
          } else {
            toast.error("Internal error occured. Please contact support");
            setTemplateLoading(false);
          }

        });

        // const newTab = window.open();
        // if (newTab) {
        //   newTab.document.write(getTemplateCode);
        //   newTab.document.close();
        // } else {
        //   console.error("Popup blocked! Allow pop-ups in your browser.");
        // }
      } catch (error) {
        console.error("Error generating template:", error);
        toast.error("Internal error occurred, please contact support.");
      }
    } else {
      toast.warn('Add atleast one element to save the template !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

  };

  const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = () => {
  //   setScrollPosition(window.scrollY); // For vertical scroll
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);


  // const divRef = useRef(null);
  // const [scrollTop, setScrollTop] = useState(0);
  // const [divTop, setDivTop] = useState(0);

  // const handleScroll = () => {
  //   setScrollTop(window.scrollY); // Get page scroll position

  //   if (divRef.current) {
  //     setDivTop(divRef.current.getBoundingClientRect().top); // Get div position relative to viewport
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   handleScroll(); // Initial position
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);


  // console.log(scrollTop, "scrollTop");
  // console.log(divTop, "divTop");

  return (
    <Fragment>

      {
        loading ? <>
          <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "60vh" }}>
            <BeatLoader color={"# 008fe2"} loading={true} size="15" />
          </div>
        </> : <>
          <div className="w-100 h-100 row mx-0 position-relative">
            <div className="col-lg-12 col-sm-12 my-3 d-flex align-items-center justify-content-between" style={{ fontSize: "12px" }}>
              <div className="w-50">
                <Breadcrumb title={"Mail template details"} parent={"Home"} isRedirectURL={'settings/speed_mails'} />
              </div>
              <div className="w-50 d-flex align-items-center justify-content-end">
                <div
                  className="border mr-2 bg-white"
                  style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(resetState());
                    history.push('/settings/speed_mails')
                  }}
                > Cancel</div>
                <div
                  className="border mr-2 bg-white"
                  style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
                  onClick={() => { templateId ? updatedTemplate() : saveTemplate() }}
                  disabled={templateLayoutData.length < 1 || templateLoading ? true : false}
                >
                  {templateLoading ? <BeatLoader color={"#008fe2"} loading={true} size="7" /> : templateId ? "Update" : 'Save'}
                </div>
                <div
                  className="border bg-white"
                  style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
                  onClick={() => { togglePreModal() }}
                > Preview</div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card mb-0">
                    <div className="card-body" style={{ padding: "20px 15px" }}>
                      <form className="theme-form" onSubmit={() => { }}  >
                        {/* <label className="col-sm-12 col-form-label mb-2" htmlFor="Header_text"><h4>Email Setting of {templateData?.templ_name} </h4></label> */}
                        <div className="form-group row mb-0">
                          <label className="col-sm-1 col-form-label" htmlFor="Templatename">Name</label>
                          <div className="col-sm-5">
                            <input
                              className="form-control"
                              id="templ_name"
                              type="text"
                              value={templateData?.templ_name}
                              name="templ_name"
                              onChange={(event) => {
                                let templateDetail = { ...templateData, templ_name: event.target.value };
                                dispatch(setTemlpateData(templateDetail));
                              }}
                            />
                          </div>

                          <label className="col-sm-1 col-form-label" htmlFor="inputEmail3">Subject</label>
                          <div className="col-sm-5">
                            <input
                              className="form-control"
                              id="inputEmail3"
                              type="text"
                              value={templateData?.templ_subject}
                              name="templ_subject"
                              onChange={(event) => {
                                let templateDetail = { ...templateData, templ_subject: event.target.value };
                                dispatch(setTemlpateData(templateDetail));
                              }}
                            />
                          </div>
                        </div>

                        {/* <div className="form-group row">
                      <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">To</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="inputnumber" name="templ_to" type="text" onChange={() => { }} />
                      </div>

                      <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">Test Mail</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="test_mail" name="templ_test_mail" type="text" value={''} onChange={() => { }} />
                      </div>
                    </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              // ref={divRef}
              className="container-fluid py-3"
              style={{
                backgroundColor: "#EFEFF1",
                // position: scrollTop >= 176 ? "sticky" : "unset"
              }}>
              {/* <div className="py-3" style={{ backgroundColor: "#EFEFF1" }}> */}
              <div className="row template_editor_comp">
                <SideBarComponent />
                <CanvasComponent templateLoading={templateLoading} setTemplateLoading={setTemplateLoading} />
              </div>
              {/* </div> */}
            </div>
          </div >
        </>
      }

      {previewModal && <PreviewModal
        isOpen={previewModal}
        toggle={togglePreModal}
        type={'new-template'}
      />}

    </Fragment >
  )
}

export default Add_Mail_template;
