import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

const validate = (values) => {
    const errors = {};

    if (!values.folder_name) {
        errors.folder_name = "Required!";
    } else if (values.prevFolder?.some(currData => currData.folder_name.toLowerCase() === values.folder_name.toLowerCase())) {
        errors.folder_name = "Folder already exists!";
    }

    return errors;
};

const renderInput = ({ input, label, type, placeholder, meta: { touched, error } }) => (
    <div className="form-group row" style={{ marginTop: "15px", marginBottom: "15px" }}>
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className="form-control"
                style={{
                    border: '1px solid #ced4da',
                    outline: "none",
                    boxShadow: "none"
                }}
            />
            {touched && error && <small className="text-danger" style={{ fontSize: "13px" }}>{error}</small>}
        </div>
    </div>
);


const AddNewFolderModal = ({
    initialize,
    handleSubmit,
    isOpen,
    toggle,
    customFolders,
    moveToFolder,
    setFolderName,
    btnloader,
    folderName,
    renameTemplateFolder,
    isUpdate
}) => {

    useEffect(() => {
        initialize({
            folder_name: folderName ? folderName : '',
            prevFolder: customFolders
        });
    }, [customFolders]);

    const onSubmit = (values) => {
        console.log("Submitted Values:", values);

        if (isUpdate) {
            setFolderName(values.folder_name);
            renameTemplateFolder(values.folder_name);
        } else {
            setFolderName(values.folder_name);
            moveToFolder(true, null, values.folder_name);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <form className="theme-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}>{folderName ? "Rename" : 'Create'} Folder</ModalHeader>
                <ModalBody>
                    <Field
                        name="folder_name"
                        type="text"
                        component={renderInput}
                        placeholder="Folder name"
                        label="Folder Name" />
                </ModalBody>
                <ModalFooter style={{ justifyContent: "center", gap: "2rem" }}>
                    <Button color={'secondary'} className='cancelBtn' onClick={toggle}>Cancel</Button>
                    {
                        isUpdate ?
                            <Button
                                color="danger"
                                type="submit"
                                disabled={btnloader}
                            >
                                {btnloader ? "Saving..." : 'Save'}
                            </Button> : <Button
                                color="danger"
                                type="submit"
                                disabled={btnloader}
                            >
                                {btnloader ? "Moving..." : 'Create & Move'}
                            </Button>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default reduxForm({
    form: "addNewFolderModal",
    validate
})(AddNewFolderModal);
