import React, { useEffect, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";

const CustomPopover = ({
    isOpen,
    toggle,
    targetId,
    deleteTemplateFolder,
    template_data = [],  // Ensure default empty array
    activeFolder = {},   // Ensure default empty object
    renameTemplateFolder,
    toggleUpateFolderName,
    selectedPopover
}) => {

    const [isLinked, setIsLinked] = useState(false);

    useEffect(() => {
        let isExist = template_data.findIndex((currData) => currData.folderRef && currData.folderRef.toLowerCase() === selectedPopover?.folder_name?.toLowerCase());
        if (isExist !== -1) {
            setIsLinked(true);
        } else {
            setIsLinked(false);
        }
    }, [selectedPopover])




    return (
        <>
            <Popover
                isOpen={isOpen}
                target={targetId}
                placement="right"
                trigger="legacy"
                toggle={toggle}
            >
                <PopoverBody className="position-relative p-0">
                    <div className="w-100 folder_list p-0">
                        {/* Rename Button */}
                        <div
                            className="folder_list_item"
                            style={{
                                color: isLinked ? "#8b9ab9" : "#000000",
                                padding: "0.5rem 1rem 0rem 1rem",
                                pointerEvents: isLinked ? "none" : "auto", // Prevent clicks if folder is inactive
                            }}
                            onClick={() => !isLinked && toggleUpateFolderName()}
                        >
                            Rename
                        </div>

                        {/* Delete Button */}
                        <div
                            className="folder_list_item"
                            style={{
                                color: isLinked ? "#8b9ab9" : "#000000",
                                padding: "0.5rem 1rem",
                                pointerEvents: isLinked ? "none" : "auto",
                            }}
                            onClick={() => !isLinked && deleteTemplateFolder()}
                        >
                            Delete
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </>
    );
};

export default CustomPopover;
