import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setStyleElement, updateTemplateLayout } from "../../../../../store/templateEditorData";

const ColumnLayoutElement = ({ elemContent, viewType, isEdit }) => {

    const { numOfCol, content } = elemContent;
    const dispatch = useDispatch();
    const { templateLayoutData } = useSelector((state) => state.emailTemplateEditorData);

    const handleInput = (e, colData) => {
        const textData = e.target.textContent;
        const dataList = content.map((data) => data.id === colData?.id ? { ...data, content: textData } : data);
        let setData = { ...elemContent, content: dataList };
        dispatch(updateTemplateLayout({ data: setData }));
    };


    const getFlexBasis = (numOfCol) => {
        if (viewType === "mobile") return "100%";
        return numOfCol === 2 ? "50%" : "33.3%";
    };

    const flexStyles = {
        display: "flex",
        flexWrap: "wrap",
        gap: "0px",
        justifyContent: viewType === "mobile" ? "center" : "flex-start",
    };

    return (
        <div
            // style={{
            //     display: "grid",
            //     gridTemplateColumns: viewType && viewType === "mobile" ? "100%" : numOfCol === 2 ? "50% 50%" : "repeat(3, 33.3%)",
            //     gap: "0px"
            // }}
            style={flexStyles}
            className={`mb-0 ${viewType && viewType === "mobile" ? 'single_column' : numOfCol === 2 ? "two_column" : "three_column"}`}
        >
            {content.map((colData, index) => (
                <div
                    key={index}
                    contentEditable={isEdit}
                    suppressContentEditableWarning={true}
                    onClick={() => { dispatch(setStyleElement(colData)) }}
                    style={{
                        ...colData.style,
                        flexBasis: getFlexBasis(numOfCol)
                    }}
                    onBlur={(event) => { handleInput(event, colData) }}
                >
                    {colData.content}
                </div>
            ))}
        </div>
    );
};

export default ColumnLayoutElement;