import React, { useEffect, Fragment, useState } from "react";
import Switch from "react-switch";
import { toast } from 'react-toastify';
import "./subscriptionCategory.scss";
import useForm from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";


const SubscriptinCategoryTable = (props) => {


    let {
        defaultVaue,
        result,
        update_subscriprion_cat,
        loadingItemp,
        subsResult,
        // toggleCatDeleteModal,
        // setSelectedData
    } = props;

    let { register, watch } = useForm({ defaultValues: defaultVaue });
    // console.log(JSON.stringify(defaultVaue, undefined, 4))

    const [deleteCategory, setDeleteCategory] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const watchedValues = watch()
    // console.log(watchedValues)
    const [checkboxState, setCheckboxState] = useState([])
    useEffect(() => {
        let temp = []
        result.map(item => {
            let arr = []
            subsResult.map(val => {
                arr.push(watchedValues[`${item.cat_Id}_${val._id}_subscriptionName`])
            })
            temp.push(arr)
        })
        setCheckboxState(temp)
    }, [result])
    console.log(checkboxState)


    const toggleCatDeleteModal = () => { setDeleteCategory(!deleteCategory) }

    // console.log(subsResult, "subsResult")
    // console.log(result, "result");



    return (
        <Fragment>
<style>
{
    `
    #table-subscription td{
                        padding: 10px !important;
                        font-size: 13px;
                        

                    }`
}
</style>
            <div className="row table_class">
                <div className="col-8">

                    <div className="table-responsive" >
                        <table className="table table-border-horizontal" >
                            <thead>
                                <tr style={{ background: '#f9fafe' }}>
                                    <th scope="col" style={{ width: "30%" }}>Category</th>
                                    <th scope="col" style={{ width: "85%" }} >Subscription Plan</th>
                                    <th scope="col" style={{ width: "15%" }} >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    result && result.length > 0 ? result.map((item, index) => {
                                        return (
                                            <tr key={index + 1}>
                                                <td>
                                                    <input
                                                        style={{
                                                            border: "#dfe8f1 solid 1px",
                                                            borderRadius: "3px",
                                                            padding: "10px",
                                                            fontSize: "0.75rem",
                                                            outlineColor: '#dfe8f1',
                                                            textTransform: "capitalize"
                                                            // width: "90px"
                                                        }}
                                                        className="m-2"
                                                        type="text"
                                                        placeholder="Category name"
                                                        name={`${item._id}_subCatTitle`}
                                                        ref={register}
                                                        onBlur={(event) => {

                                                            let getValue = event.target.value;
                                                            if (getValue.length == 0) {
                                                                toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                            } else if (getValue.length > 0 && getValue != item.subCatTitle) {
                                                                let postData = { ...item, subCatTitle: event.target.value, updateType: 1 }
                                                                update_subscriprion_cat(postData);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>

                                                    {
                                                        subsResult.filter(e => e?.status == 1).map((currnSubCat, currIndex) => {
                                                            {/* console.log(watchedValues[`${item.cat_Id}_${currnSubCat._id}_subscriptionName`]) */ }
                                                            let arr = checkboxState[index]

                                                            const trueCount = checkboxState[index]?.reduce((count, currentValue) => {
                                                                return currentValue === true ? count + 1 : count;
                                                            }, 0)
                                                            {/* console.log(checkboxState[index], trueCount, '==>', index) */ }

                                                            const shouldBeDisabled = !watchedValues[`${item.cat_Id}_${currnSubCat._id}_subscriptionName`] && trueCount >= 4;
                                                            return (
                                                                <>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            className="mr-3"
                                                                            type="checkbox"
                                                                            id={`${item.cat_Id}_${currnSubCat._id}_subs`}
                                                                            name={`${item.cat_Id}_${currnSubCat._id}_subscriptionName`}
                                                                            ref={register}
                                                                            onChange={(event) => {

                                                                                currnSubCat.subscription_category.forEach((currElem, currIndex) => {

                                                                                    if (currElem.id == item.cat_Id) {
                                                                                        currnSubCat.subscription_category[currIndex].enable = event.target.checked;
                                                                                    }
                                                                                })
                                                                                // console.log(currnSubCat.subscription_category, "current upadte data")
                                                                                let postData = { subscriptionData: currnSubCat, updateType: 2 }
                                                                                update_subscriprion_cat(postData);
                                                                            }}
                                                                            disabled={shouldBeDisabled}
                                                                        />
                                                                        <label for={`${item.cat_Id}_${currnSubCat._id}_subs`} className="mb-0">{currnSubCat.title}</label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span
                                                            style={{ marginRight: "1rem", fontSize: "1rem", color: "red" }}
                                                            onClick={() => {

                                                                let postData = { ...item, updateType: 3 }
                                                                setSelectedData(postData);
                                                                setDeleteCategory(true);

                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </span>
                                                        {
                                                            loadingItemp && loadingItemp == item._id ?
                                                                <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                : <Switch
                                                                    className="react-switch"
                                                                    height={20}
                                                                    width={40}
                                                                    onChange={(checked) => {
                                                                        let postData = { ...item, enableCategory: checked, updateType: 4 }
                                                                        update_subscriprion_cat(postData);
                                                                    }}
                                                                    checked={item.enableCategory == true ? true : false}
                                                                />
                                                        }
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>

                    {
                        result.length == 0 && <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>No record found !</p>
                        </div>
                    }

                </div>
            </div>

            <Modal isOpen={deleteCategory} toggle={toggleCatDeleteModal} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Subscription Category</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Category?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleCatDeleteModal}>Not sure!</Button>
                        <Button
                            color="primary"
                            onClick={() => { update_subscriprion_cat(selectedData); toggleCatDeleteModal() }}
                        >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>

        </Fragment>
    );

}

export default SubscriptinCategoryTable;
