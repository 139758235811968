import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import useForm from 'react-hook-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { matchPath } from "react-router";
import VerifiedMerchantInfo from "./VerifiedMerchantInfo";
import catsubcat from "../../CatSubcat";
import displayMobile from "../../components/ui-elements/display_mobile";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DisappearedLoading } from "react-loadingg";
import moment from "moment-timezone";

const businessCategory = null;
const subcategory = null;

// const BorderTable = () => {
class BorderTable extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      error: null,
      merchant_id: this.props.match.params.merchant_id,
      isLoaded: false,
      items: [],
      merchant_info: null,
      wallet_info: null,
      transactions: null,
      redirect: false,
      send_mail_modal: false,
      approval_status_to_be_set: null,
      editDetailsInfo: null,
      other_business: null,
      merchant_appr_status: '',
      access_level: localStorage.getItem("accessLevel"),
      tabIndex: 0,
      loading : true,
      merchant_user: null,
      merchant_company: null,
      is_trashed: false,
    };
  }



  // componentDidUpdate(prevProps, prevState) {
  //   const { merchant_id } = this.props.match.params;
  //   if (merchant_id !== prevState.type) {
  //     this.setState({ merchant_id });
  //     this.fetchData();
  //     this.getMerchantDetails();
  //     alert("here")
  //   }
  // }

  componentDidMount() {
    let { merchant_id } = this.state;


    sessionStorage.removeItem('tierLevel')

    if (this.props.location.search != "") {
      let tab;
      const query = new URLSearchParams(this.props.location.search);
      for (let params of query.entries()) {
        tab = +params[1]
      }
      // console.log(tab);
      //console.log(this.state.tabIndex);
      this.setState({ tabIndex: tab })
    }
    let trashed = false;
    if (window.location.pathname.includes("trashed_merchant")) {
      trashed = true
    }

    this.setState({ is_trashed: trashed }, () => {
      this.fetchData();
      this.getMerchantDetails();
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps, prevState)
    if (nextProps.match.params.merchant_id != prevState.merchant_id) {
      return {
        merchant_id: nextProps.match.params.merchant_id
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state.tabIndex);

    if (prevProps.match.params.merchant_id != this.state.merchant_id) {

      let a = matchPath(window.location.pathname, {
        path: "/trashed_merchant/:merchant_id",
        exact: true,
        strict: true,
      });

      if (a && a.isExact) {
        this.setState({ is_trashed: true }, () => {
        
          // if (prevProps.match.params.merchant_id != this.state.merchant_id) {
            this.getMerchantDetails();
            this.fetchData();
            // alert("here")
            // window.location.reload()
          // }

        })
      } else {
        this.setState({ is_trashed: false }, () => {
        
          // if (prevProps.match.params.merchant_id != this.state.merchant_id) {
            this.getMerchantDetails();
            this.fetchData();
            // alert("here")
            // window.location.reload()
          // }

        })
      }
    }

    

  }

  send_mail_toggle = (event) => {
    event.preventDefault();
    this.setState(prevState => {
      return {
        ...prevState,
        send_mail_modal: !prevState.send_mail_modal
      }
    })
  }

  set_user_mail_id(change_status) {
    this.setState({ approval_status_to_be_set: change_status });
  }

  refreshMerchantInfo = (req) => {
    this.setState(prevState => {
      let merchant_info = Object.assign({}, prevState.merchant_info);
      merchant_info.listing_status = req;
      return { merchant_info };
    });
  }

  getMerchantDetails = async () => {
    let current = this;

    if (false) {
      current.setState({ merchant_info: this.props.location.state.merchantData });
    }
    else {

      let { merchant_id } = this.state;
      let postdata = { merchant_id: merchant_id };
      // current.setState({ loading: true });
      client.post(api.merchant_details, postdata, async function (error, response) {
        console.log(error,response)
        if (!error) {
          // alert(JSON.stringify(response)); 
          // console.log(response, "merchant details data")
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                try {
                  const other_business_data = JSON.parse(response.result.contactDetails.other_business);
                  const other_business = other_business_data.map(el => {
                    return el.business_name
                  }).join()

                  current.setState({ merchant_info: response.result.contactDetails, other_business: other_business, merchant_user: response.result.userDetails,merchant_company:response.result.companyDetails });

                } catch (error) {
                  current.setState({ merchant_info: response.result.contactDetails, other_business: "N.A", merchant_user: response.result.userDetails,merchant_company:response.result.companyDetails  });
                }


              } else {
                setTimeout(() => {
                  // toast.error(response.message);
                }, 200);
              }
            } catch (e) {
              setTimeout(() => {
                // toast.error("Internal error occured. Please contact support");
              }, 200);
              // _showToast.error("Internal error occured. Please contact support.");
              // currentComponent.setState({ errormessage: "Internal error." });
            }
          } else {
            setTimeout(() => {
              // toast.error("Internal error occured. Please contact support");
            }, 200);
            //   _showToast.error("Internal error occured. Please contact support.");
          }
        } else {
          setTimeout(() => {
            // toast.error("Internal error occured. Please contact support");
          }, 200);
          // alert(JSON.stringify(error));
          // _showToast.error("Internal error occured. Please contact support.");
        }
        current.setState({ loading: false });
      })
    }
  };

  redirect_success = () => {
    this.setState({
      redirect: true
    });
  }
  /* approval_change  */
  handle_send_mail_submit = (e, status) => {

    e.preventDefault();
    let { merchant_id } = this.state;
    let postdata = { merchant_id: merchant_id };
    let current = this;
    current.setState({ loading: true });

    if (postdata !== '') {
      //setloadingbtn(true);
      client.post(api.webMerc_sendCredentials, postdata, async function (error, response) {
        if (!error) {
          //alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Credentials sent successfully");
                current.refreshPage();
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              //console.log(e);
              toast.error(e.message);
            }
          } else {
            //console.log(response.error);
            toast.error("Internal error occured. Please contact support ");
          }
        } else {
          //console.log(error);

          toast.error("Internal error occured. Please contact support ");
        }
        //setloadingbtn(false);
      });
    }

  }

  refreshPage() {
    window.location.reload(false);
  }

  updateDetailsHandler = (event) => {
    event.preventDefault();
    let { merchant_id, editDetailsInfo, other_business } = this.state;
    const other_business_data = other_business.split(",");
    const otherBusinessData = JSON.stringify(other_business_data.map((el, i) => {
      return { index: i + 1, business_name: el }
    }))

    let postdata = { merchant_id: merchant_id, ...editDetailsInfo, other_business: otherBusinessData };

    client.put(api.update_merchant_details, postdata, (error, response) => {
      if (!error) {
        try {
          if (!response.error) {
            toast.success("Updated Successfully");
            // this.redirect_success();
            this.refreshPage();
          } else {
            // toast.error(response.message);
            // console.log(response.error);
          }
        } catch (e) {
          toast.error(e.message);
        }
      } else {
        toast.error("Internal error occured. Please contact support ");
      }


    });
  }

  detailsChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name == "other_business") {
      this.setState({ other_business: value })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          merchant_info: {
            ...prevState.merchant_info,
            [name]: value
          },
          editDetailsInfo: {
            ...prevState.editDetailsInfo,
            [name]: value
          }
        }
      })

    }
  }

  fetchData = async () => {
    client.post(api.fetch_catsubcat, { active_all: true }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {

              businessCategory = response.result.data.map(el => {
                return el.category_name
              });
              subcategory = response.result.data.map(el => {
                return el.sub_category.map(sub => {
                  return sub.subCategory
                })
              }).reduce((el, nextEl) => {
                return el.concat(nextEl)
              })

              // console.log(businessCategory);

            }
          } catch {

          }
        }
      }
    });
  }
  render_date = (add_date) => {

    const convert_date = new Date(add_date);
    const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD-MM-YYYY");
    return added_date;
  }


  render() {

    const {
      redirect,
      approval_status_to_be_set,
      editDetailsInfo,
      other_business,
      access_level,
      tabIndex,
      merchant_user
    } = this.state;
    //console.log(editDetailsInfo);
    // console.log(merchant_user, "merchant_user");

    if (redirect) {
      return <Redirect to={`${process.env.PUBLIC_URL}/merchants/merchant_requests`} />;
    }
    let { merchant_info, merchant_id,merchant_company } = this.state;
    let approval_message_disp = 'not diplayed';
    if (approval_status_to_be_set == 1) {
      //console.log('approve selected');

      approval_message_disp = 'Are you sure you want to Approve merchant application ?';

    } else if (approval_status_to_be_set == 2) {
      //console.log('disapprove selected');

      approval_message_disp = 'Are you sure you want to Disapprove merchant Application ?';

    }
    //console.log(merchant_info);
    //console.log(merchant_id);
    let subcat;
    if (merchant_info) {
      subcat = catsubcat.find(cat => {
        return cat.categoryTitle == merchant_info.business_category
      });
    }



    // console.log(merchant_info)


    return (
      <Fragment>
        {/* <Breadcrumb title={merchant_info.name} mobile={merchant_info.mobile} email={merchant_info.email}
          ipay_id={merchant_info.ipay_id} parent={"Customers"} /> */}

        {/* <div className="card-body">

          <ol className="breadcrumb m-b-0 p-b-0 p-l-0 ">
            <li className="breadcrumb-item"><h5>Merchant Accounts</h5></li>
            <li className="breadcrumb-item"><a href="#javascript"><i className="fa fa-home"></i></a></li>
            <li className="breadcrumb-item">Merchant Accounts</li>
            <li className="breadcrumb-item active">{merchant_info ? merchant_info.business_name : null}</li>
          </ol>
          
        </div> */}

        {
          merchant_id ? (
            <div className="container-fluid">
              {
                !(this.state.loading)?

                merchant_info ?
                  <Fragment>
                    <div className="col-md-12 col-sm-12" style={{ fontSize: "12px" }}>
                      <Breadcrumb user_email_status={merchant_user?.email_status == 1 ? 1 : 0} user_email={merchant_user?.email} merchant_info={merchant_info} country={merchant_info?.country} title={merchant_info?.business_name} mobile={merchant_info?.mobile} mobile_status={merchant_info?.mobile_status} email={merchant_user?.email} email_status_m={merchant_info?.email_status} merchant_id_m={merchant_info?._id}
                        ipay_id={merchant_info?.ipay_id} account_type='Owner' customClass2='breadCrumbCustom' customClass={'justify-content-between'} parent={"Merchants"} create_date={merchant_info?.added_date} referral_code={merchant_info?.referral_code}
                        merchant_company={merchant_company}
                        />
                    
                        {
                          (this.state.is_trashed) ?
                            <div className="container-fluid" style={{
                              width: "100%",
                              paddingRight: "15px",
                              paddingLeft: "15px",
                              marginRight: "auto",
                              marginLeft: "auto",
                              paddingTop: "4px",
                              color: "#fe0101",
                              fontSize: "13px",
                              paddingBottom: '15px'
                            }}>
                              {merchant_info?.trash_data ?
                                <span> Account deactivated on {this.render_date(merchant_info?.trash_data?.trashed_on)} {merchant_info.trash_data?.ip ? `IP: ${merchant_info.trash_data?.ip}` : ''} Reason : {merchant_info.trash_data?.reason}
                                </span>
                                :
                                <span>
                                  Account deactivated
                                </span>
                              }
                              
                              {
                                <style>
                                  {`
                            .page-header
                            {
                            padding-bottom : 0px !important;
                            }
                            `}
                                </style>
                              }
                            </div>
                              
                            :
                            null
                        }
                      </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-12">
                        {/* <div className="row">
                  <div className="col-sm-12"> */}
                          {false ?
                            // merchant_info.mobile_status == 0
                            //   && merchant_info.request_source == 2
                            //   || merchant_info.mobile_status == 0
                            //   && merchant_info.request_source != 2
                            //   && !this.props.location.state ?
                            <div className="card">

                              <div className="card-body">

                                <form className="theme-form"  >

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Owner/Manager Name:</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" type="text" name="name"
                                        placeholder="Owner/Manager Name" value={merchant_info.name}
                                        onChange={this.detailsChangeHandler} disabled={access_level == 1 ? true : false} />
                                      <input type="hidden" name="merchant_id" value={merchant_info._id}></input>
                                      <input type="hidden" name="approval_status" value={this.state.approval_status}></input>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Contact No</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" type="text" name="mobile" disabled={access_level == 1 ? true : false}
                                        placeholder="Mobile" value={displayMobile(merchant_info.mobile)} onChange={this.detailsChangeHandler} />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Email</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" type="email" name="email" disabled={access_level == 1 ? true : false}
                                        placeholder="Email" value={merchant_info.email} onChange={this.detailsChangeHandler} />
                                    </div>
                                  </div>


                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Business Name</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="business_name" type="text" name="business_name" disabled={access_level == 1 ? true : false}
                                        placeholder="Business Name" value={merchant_info.business_name} onChange={this.detailsChangeHandler} />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >ABN</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="inputnumber" type="text" name="abn" disabled={access_level == 1 ? true : false}
                                        placeholder="ABN" value={merchant_info.abn} onChange={this.detailsChangeHandler} />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Landline No.</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="url" type="text" placeholder="Landline" name="landline"
                                        value={merchant_info.landline} onChange={this.detailsChangeHandler} disabled={access_level == 1 ? true : false} />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Business Category</label>
                                    <div className="col-sm-9">
                                      <select className="form-control" name="business_category" onChange={this.detailsChangeHandler}
                                        value={merchant_info.business_category} disabled={access_level == 1 ? true : false}>
                                        {businessCategory && businessCategory.map((el, i) => {
                                          return <option key={el + i} value={el}>{el}</option>
                                        })}
                                      </select>
                                      {/* <input className="form-control" id="url" type="text" placeholder="Business name" value={merchant_info.business_category} /> */}
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Business Sub-Category</label>
                                    <div className="col-sm-9">
                                      <select className="form-control" name="business_sub_category" onChange={this.detailsChangeHandler}
                                        value={merchant_info.business_sub_category} disabled={access_level == 1 ? true : false}>
                                        {/* {catsubcat.find(cat => {
                                  return cat.categoryTitle == merchant_info.business_category
                                }).subCategory.map((subcat, i) => {
                                  return <option key={subcat.subcatTitle + i}>{subcat.subcatTitle}</option>
                                })} */}
                                        {subcat ? subcat.subCategory.map((subcat, i) => {
                                          return <option key={subcat.subcatTitle + i}>{subcat.subcatTitle}</option>
                                        }) : ""}
                                      </select>
                                      {/* <input className="form-control" id="url" type="text" placeholder="Business name" value={merchant_info.business_sub_category} /> */}
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Website</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="Website" type="text" placeholder="Website" name="website"
                                        value={merchant_info.website} onChange={this.detailsChangeHandler} disabled={access_level == 1 ? true : false} />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Postcode</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="postcode" type="text" placeholder="Postcode" disabled={access_level == 1 ? true : false}
                                        value={merchant_info.postcode} onChange={this.detailsChangeHandler} name="postcode" />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Suburb</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="suburb" type="text" placeholder="Suburb" disabled={access_level == 1 ? true : false}
                                        value={merchant_info.suburb} onChange={this.detailsChangeHandler} name="suburb" />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" >Address</label>
                                    <div className="col-sm-9">
                                      <input className="form-control" id="Website" type="text" placeholder="Address" name="address"
                                        value={merchant_info.address} onChange={this.detailsChangeHandler} disabled={access_level == 1 ? true : false} />
                                    </div>
                                  </div>

                                  {/* <div className="form-group row">
                              <label className="col-sm-3 col-form-label" htmlFor="inputPassword3">Other Businesses</label>
                              <div className="col-sm-9">
                                {merchant_info.other_business != "" ? <input className="form-control" type="text" placeholder="Other Business" name="other_business"
                                  value={this.state.other_business} onChange={this.detailsChangeHandler} disabled={access_level == 1 ? true : false} />
                                  : <input className="form-control" type="text" placeholder="Other Business" name="other_business" disabled={access_level == 1 ? true : false} onChange={this.detailsChangeHandler} value={this.state.other_business}></input>}
                              </div>
                            </div> */}

                                  <div className="card-footer">
                                    <button className="btn btn-success mr-2" onClick={this.updateDetailsHandler} disabled={access_level == 1 ? true : false}>Update</button>
                                    <button className="btn btn-secondary" onClick={this.send_mail_toggle} disabled={access_level == 1 ? true : false}>Send  ID/Password</button>

                                    {/*  <a style={{ color: 'white' }} className="btn btn-success mr-1" id="approve" onClick={() => { this.send_mail_toggle(); this.set_user_mail_id(1) }} >Approve</a>
                              <a style={{ color: 'white' }} className="btn btn-danger mr-1" id="approve" onClick={(e) => { this.send_mail_toggle(); this.set_user_mail_id(2) }} >UnApprove</a>
                              <a style={{ color: 'white' }} className="btn btn-secondary" id="cancel">Cancel</a> */}
                                  </div>

                                </form>

                              </div>

                            </div>
                            :
                            <VerifiedMerchantInfo
                              refreshMerchantInfo={this.refreshMerchantInfo}
                              merchant_id={merchant_id}
                              merchant_info={merchant_info}
                              tabIndex={this.state.tabIndex}
                              getMerchantDetails={this.getMerchantDetails}
                              fetchData={this.fetchData()}
                              merchantDataType={this.state.is_trashed ? {type : "trashMerchant"} : {}}
                            merchant_user={merchant_user}
                          ></VerifiedMerchantInfo>
                        }
                        {/* </div>
                </div> */}
                      </div>

                    </div>
                  </Fragment>
                  : 
                  <Fragment>
                      <div className="col-md-12  d-flex  mt-4 col-sm-12" style={{ fontSize: "12px",minHeight:'50vh' }}>
                        <div className="d-flex flex-column align-items-center justify-content-center mt-4" style={{height:'100%',width:'100%',background:'white',marginTop:'3rem',minHeight:'50vh'}}>
                        <h4 >Sorry, this store isn't available</h4>
                        <Button color="primary" className="mt-2 py-2" style={{width:'12rem'}} onClick={()=>{window.history.back()}}>Ok</Button>
                        </div>
                        </div>
                  </Fragment>:
                  <div className="loader-box" style={{ height: "100px" }}>
                  <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                    <DisappearedLoading size="medium" color="#514F4E" />
                  </div>
                </div>
              }
            </div>

          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/merchants/merchant_requests`} />
          )
        }



        <div className="card-body grid-showcase">
          {/* <!-- Vertically centered modal--> */}




          <Modal isOpen={this.state.send_mail_modal} toggle={this.send_mail_toggle} className="modal-body" centered={true}>
            <form className="theme-form" noValidate="" >
              <ModalHeader toggle={this.send_mail_toggle} style={{ textAlign: 'center' }}>Send ID/Password </ModalHeader>
              <ModalBody>
                <br />
                <h5>Are you sure you want to send the credentials ?</h5>
                <p style={{ color: "red" }}>Note: An email will be sent with ID & temporary password to the user. User is requested to login into the app and create password to proceed. Thanks!</p>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.send_mail_toggle}>Not Sure</Button>
                <Button color="primary" type="submit" onClick={(e) => { this.handle_send_mail_submit(e) }} >Yes Please !</Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default BorderTable;
