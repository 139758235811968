import React from 'react';
import ColoumnElement from "./LayoutElement/ColumnElement"
import TextElement from "./LayoutElement/TextElement"
import ImageElement from "./LayoutElement/ImageElement"
import { ImageAndTextElement } from "./LayoutElement/ImageAndTextElement"
import ButtonElement from "./LayoutElement/ButtonElements"
import TableElement from "./LayoutElement/TableElement";
import SpacerElement from "./LayoutElement/SpacerElement";

const LayoutComponent = ({ elemContent, viewType, isEdit }) => {

    switch (elemContent.type) {
        case "heading1":
        case "heading2":
        case "paragraph":
            return <TextElement elemContent={elemContent} isEdit={isEdit} />;
        case "1image":
        case "1+1image":
        case "2+1image":
            return <ImageElement elemContent={elemContent} isEdit={isEdit} viewType={viewType} />;
        case "10pxspace":
        case "15pxspace":
        case "20pxspace":
            return <SpacerElement elemContent={elemContent} />;
        case "image+text":
        case "text+image":
        case "text+image+text":
        case "image+text+image":
            return <ImageAndTextElement elemContent={elemContent} isEdit={isEdit} viewType={viewType} />;
        case "normalbutton":
        case "roundedbutton":
        case "ovalbutton":
            return <ButtonElement elemContent={elemContent} />;
        case "basictable":
            return <TableElement elemContent={elemContent} isEdit={isEdit} />
        case "2columns":
        case "3columns":
            return <ColoumnElement elemContent={elemContent} isEdit={isEdit} viewType={viewType} />;
        default:
            return null;
    }

}

export default LayoutComponent;