import React, { useRef, useState } from "react";
import Switch from "react-switch";
import Icon from "@mdi/react";
import { Row, Col, Popover, PopoverBody, Button, Modal, ModalBody, ModalHeader, ModalFooter,Input } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { useDrag, useDrop } from 'react-dnd'
import { toast } from "react-toastify";
import { PlusCircle } from "react-feather";
import useForm from "react-hook-form";
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js";
// const style = {
//   backgroundColor: 'white',
//   cursor: 'move',
// }

const RenderSwitch=({checked,tagName,item_id,updateFunc})=>{
    // console.log(checked,tagName,item_id,updateFunc)
    return(<div className="d-flex">
        <Switch
                                                                                                                                
                                                                                                                                checked={checked}
                                                                                                                                className="react-switch mx-1 "
                                                                                                                                name={`${tagName}_${item_id}`}
                                                                    // id={`${item._id}_featureId`}
                                                                    
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onChange={updateFunc}
                                                                                                                                height={20}
                                                                                                                                width={40}
                                                                                                                            /></div>
    )
}

let DatePickerInput = ({ value, onClick }) => {
  return (
    <Input type="text" style={{border:'1px solid #ced4da'}} value={value} onFocus={onClick} onClick={onClick} />
  );
}

const RenderSubscription = ({ result, row_item, loadingpopular, showActive, loadingItem, toggleStatus, updatePopular, updateSortBy, index, moveCard, id,setForceReload,forceReload,activeCategory }) => {

    // console.log(activeCategory)

    const ref = useRef(null)

     const updateSubscription=(data)=>{
            data.subscription_id=data._id
            data.start_date=data.promo_start_date
            data.end_date=data.promo_end_date
            data.fee=data.fees
            data.multifeature=data.descriptive_features?data.descriptive_features:[]
            // console.log(data)

            
                 client.post(api.update_subscription, data, async function (error, response) {
                                    // console.log(data, "Data")
                                    if (!error) {
                                        if (typeof response.error !== "undefined") {
                                            try {
                                                if (!response.error) {
                                                    // console.log(response, "response")
                                                    setForceReload(!forceReload)
                                                    toast.success("Updated Successfully");
                                                    // setcashbackdata(response.result.data);
                                                } else {
                                                    toast.error(response.message);
                                                }
                                            } catch (e) {
                                                console.log(e)
                                                toast.error("Internal error occured. Please contact support");
                                            }
                                        } else {
                                            toast.error("Internal error occured. Please contact support");
                                        }
                                    } else {
                                        toast.error("Internal error occured. Please contact support");
                                    }
                
                                });
            
                            }

                            

    const [enablePopOver, setEnablePopOver] = useState(false);
    const [promoDataOpen, setPromoDataOpen] = useState(false);
    const togglePromoData=(()=>setPromoDataOpen(!promoDataOpen))

    const [start_date, setStartDate] = useState(null);
      const [end_date, setEndDate] = useState(null);
      const [promotorItem, setPromotorItem] = useState(null);
      let [showerrorstatus, setshowerrorstatus] = useState(false);
      const [promo_rate_type, setpromo_rate_type] = useState(1)
      const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const togglePopOver = () => {
        setEnablePopOver(!enablePopOver)
    };

    const [{ handlerId }, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        canDrop(item, monitor) {
            if (row_item.title_id == item.title_id) {
                return true
            } else {
                return false
            }
        },
        hover(item, monitor) {

            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            // console.log(drop, "hover")
            // console.log(id, "id")
            // console.log(row_item, "title_id")
            const hoverIndex = index
            // Don't replace items differenct title id
            if (row_item.title_id != item.title_id) {
                // console.log("")
                return
            }
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex, row_item.title_id)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        drop(item, monitor) {

            moveCard(0, 0, row_item.title_id, true)

        },
    })
    const [{ isDragging }, drag, preview] = useDrag({
        type: "card",
        item: () => {
            return { id, index, title_id: row_item.title_id }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    preview(drop(ref))
    drag(drop(ref))

    if (row_item.static) {
        // console.log(row_item.title, "result title")
        // console.log(result.length, "result")
    }

   
     const onSubmit = data => {
        // console.log(promotorItem,data)
    
    
            if (data != '') {
               
    
                    // if (!moment(start_date).isValid()) {
                    //   return false;
                    // }
                    // console.log(data.promo_rate, "data.promo_rate")
                    data.apply_promo_rate=1
                    if (data.apply_promo_rate && (!moment(end_date).isValid() || !moment(start_date).isValid() || moment(start_date).isAfter(end_date))) {
                        setshowerrorstatus(true)
                        return false;
                    }
    
                    
                    data.subscription_id=promotorItem._id
            data.start_date=start_date
            data.end_date=end_date
            data.fee=promotorItem.fees
            data.multifeature=promotorItem.descriptive_features?promotorItem.descriptive_features:[]
    
                    // setloadingbtn(true);
                    // console.log(data, "Data")
    
                    // return false;
    
                    client.post(api.update_subscription, {...promotorItem,...data}, async function (error, response) {
                        // console.log(data, "Data")
                        if (!error) {
                            if (typeof response.error !== "undefined") {
                                try {
                                    if (!response.error) {
                                        // console.log(response, "response")

                                        setForceReload(!forceReload)
                                        toast.success("updated Successfully");
                                        // setcashbackdata(response.result.data);
                                        togglePromoData()
                                    } else {
                                        toast.error(response.message);
                                    }
                                } catch (e) {
                                    toast.error("Internal error occured. Please contact support");
                                }
                            } else {
                                toast.error("Internal error occured. Please contact support");
                            }
                        } else {
                            toast.error("Internal error occured. Please contact support");
                        }
    
                    });
                
    
                // alert('You submitted the form and stuff!');
            } else {
                setshowerrorstatus(true)
                errors.showMessages();
            }
    
    
        };


    const history = useHistory();

    return (
        <tr ref={ref} key={handlerId} test={id} style={{ cursor: !row_item.static ? 'move' : 'unset', opacity }} data-handler-id={handlerId}>
            <React.Fragment>
                <td className={"title"} style={{ background: '#f9fafe', minWidth: " 150px",maxWidth:'150px',width:'150px' }}>
                    {
                        <div style={{ display: 'flex' }}>
                            {!row_item.static &&
                                <div className='handle'>
                                    <DragVerticalIcon />
                                </div>
                            }

                            <h5 className="featureName">
                                {row_item.title}
                                {!row_item.static &&
                                    <i
                                        class="fa fa-info-circle ml-1"
                                        id={`features-${row_item._id}`}
                                        onClick={() => {
                                            togglePopOver();
                                        }
                                        }
                                        aria-hidden="true"
                                    ></i>
                                }
                            </h5>

                            {!row_item.static &&
                                <Popover
                                    placement="right"
                                    // isOpen={popoverState[item.feature_id]}
                                    isOpen={enablePopOver}
                                    target={`features-${row_item._id}`}
                                    // toggle={() => setPopoverState(prev => {
                                    //     return { [item.feature_id]: !prev[item.feature_id] }
                                    // })}
                                    toggle={() => { togglePopOver() }}
                                >
                                    <PopoverBody className="py-3">
                                        <textarea
                                            className="form-control textAreaa"
                                            defaultValue={row_item?.description}
                                            name="cloud_based_desc"
                                            placeholder="description"
                                            rows={4}
                                            cols={60}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                // let temp = { ...featureDescArr[0] }
                                                // temp.cloud_based_desc = e.target.value
                                                // setFeatureDescArr([{ ...temp }])
                                            }}
                                        />
                                        <div className="d-flex justify-content-between mt-3">
                                            <Button
                                                style={{ width: '45%' }}
                                                color="grey"
                                                onClick={() => {
                                                    togglePopOver();
                                                    // setPopoverState(prev => {
                                                    //     return { [item.feature_id]: !prev[item.feature_id] }
                                                    // })
                                                }}>Cancel
                                            </Button>
                                            <Button
                                                style={{ width: '45%' }}
                                            // onClick={(e) => saveFeaturesDesc(item, featureDescArr[0].cloud_based_desc)}
                                            >Save</Button>
                                        </div>
                                    </PopoverBody>
                                </Popover>
                            }
                        </div>
                    }
                </td>
                {
                    result && result.length > 0 && result.map((item, index) => {

                        // console.log(row_item.title, "row_item.title")
                        // console.log(item.descriptive_features, "row_item.title")
                        // console.log(item,row_item)

                        if (showActive ? item.status === 1 : true) {
                            if (row_item.feature_id == "title") {
                                return (
                                    <td style={{ minWidth: " 120px" }} className={item?.subscription_category?.some(elem=>{

                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''}>

                                        {
                                            <div style={{ display: 'block'}}>
                                                <div className="d-flex w-100 justify-content-between" style={{gap:'10px'}}>
                                                    <span>
                                                        {item.title}
                                                        {item.is_trial &&
                                                            <span style={{ marginLeft: 10, fontSize: 8, backgroundColor: "orange", padding: "1px 4px",  borderRadius: 8, color: "#fff" }}>Trial</span>
                                                        }
                                                    </span>

                                                    <span style={{ marginRight: "10px", float: 'right', right: 0, fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                        history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
                                                    }} >

                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                                </div>
                                                {
                                                    <div style={{ marginTop: 6, display: 'flex', justifyContent: "space-between" }}>
                                                        <span>{item.status == 2 ? 'Activate' : 'Deactivate'} </span>
                                                        {/* <Col className="dataCell" style={{ height: "50px" }}> */}
                                                        {loadingItem && loadingItem == item._id ?
                                                            <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                            :
                                                            <Switch
                                                                onChange={() => toggleStatus(item, index)}
                                                                checked={item.status == 2 ? false : true}
                                                                className="react-switch"
                                                                disabled={item?.is_free === true}
                                                                height={20}
                                                                width={40}
                                                            />

                                                        }
                                                        {/* </Col> */}
                                                    </div>
                                                }
                                                <div style={{ marginTop: 6, display: 'flex' }}>
                                                    {loadingpopular && loadingpopular == item._id ?
                                                        <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                        :
                                                        <>

                                                            {true &&
                                                                <div

                                                                    onClick={() => {
                                                                        updatePopular(item, { checked: !item.popular })
                                                                    }
                                                                    }

                                                                    style={{
                                                                        // marginLeft: "10px",
                                                                        background: item.popular ? "#FED3A1" : 'gray',
                                                                        color: item.popular ? "#313131" : '#fff',
                                                                        padding: "2px 12px",
                                                                        borderRadius: "10px",
                                                                        fontSize: "9px",
                                                                        textTransform: "uppercase",
                                                                        fontWeight: "bold",
                                                                        display: "flex",
                                                                        alignSelf: "center",
                                                                        // marginLeft: 10,
                                                                    }}>Most Popular</div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </td>
                                )
                            } else if (row_item.feature_id == "sub_title") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "80px", overflow: "auto" }} >{item.description}</td>
                            } else if (row_item.feature_id == "fees") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "80px", overflow: "auto" }} >{item.fees}</td>
                            } else if (row_item.feature_id == "promo_details") {
                                // console.log(row_item,item)
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>
                                    {item.apply_promo_rate ?<div className="d-flex justify-content-between">
                                        <span>Fees : {item.promo_rate} {item.promo_rate_type == 2 ? '%' : ''}<br />
                                            End Date : {moment(item.promo_end_date).format("YYYY-MM-DD")}</span>
                                            <div className="d-flex flex-column">

                                            
                                             <Button
                                                                                                outline
                                                                                                style={{
                                                                                                    padding: "4px 6px",
                                                                                                }}
                                                                                                className="d-flex align-items-start m-0 mr-2 mb-2"
                                                                                                color="primary"
                                                                                                // onClick={(e) => {
                                                                                                // 	toggledeleteModal(val);
                                                                                                // }}
                                                                                                onClick={() => {togglePromoData()
                                                                                                    setPromotorItem(item)
                                                                                                    setStartDate(new Date(item?.promo_start_date))
                                                                                                    setEndDate(new Date(item?.promo_end_date))
                                                                                                    setpromo_rate_type(item?.promo_rate_type)
                                                                                                    console.log(item?.promo_rate_type)
                                                                                                }}
                                                                                            >
                                                                                                <Icon path={mdiPencil} className="mx-auto" size={0.6} />
                                                                                            </Button>
                                             <Button
                                                                                                outline
                                                                                                style={{
                                                                                                    padding: "4px 6px",
                                                                                                }}
                                                                                                className="d-flex align-items-start m-0 mr-2"
                                                                                                color="danger"
                                                                                                // onClick={(e) => {
                                                                                                // 	toggledeleteModal(val);
                                                                                                // }}
                                                                                                onClick={() => updateSubscription({...item,promo_start_date:null,promo_end_date:null,promo_rate_type:1,
                                                                                                    apply_promo_rate:0,
                                                                                                    promo_rate:0
                                                                                                })}
                                                                                            >
                                                                                                <Icon path={mdiTrashCanOutline} className="mx-auto" size={0.6}  />
                                                                                            </Button>
                                                                                            </div>

                                            </div>
                                        :
                                        <span>{!(item.is_trial === true || item?.is_free === true ? true : false)?<PlusCircle onClick={()=>{togglePromoData()
                                            setPromotorItem(item)
                                        }}/>:'-'}</span>

                                    }
                                </td>
                            } else if (row_item.feature_id == "sales_per_day") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.unlimited_sales ? `Unlimited` : item.sales_per_day}</td>
                            } else if (row_item.feature_id == "number_of_registers") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.unlimited_registration ? `Unlimited` : item.number_of_registers}</td>
                            } else if (row_item.feature_id == "number_of_users") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.unlimited_users ? `Unlimited` : item.number_of_users}</td>
                            } else if (row_item.feature_id == "guest_visitor_registry") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "60px" }}>{item.unlimited_vregistries ? `Unlimited` : item.number_of_vregistries}</td>
                            } else if (row_item.feature_id == "linky_scan_id") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.unlimited_linkyScan ? `Unlimited` : item.number_of_linkyScan}</td>
                            } else if (row_item.feature_id == "ecommerce_website") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    // console.log(elem,item,elem?.id==activeCategory && elem?.enable,elem?.id,activeCategory)

                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "70px" }}>{item.online_orders ? <RenderSwitch
                                    checked={true}
                                    tagName={'online_orders'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,online_orders:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'online_orders'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,online_orders:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "bookings_reservations") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "60px" }}>{item.table_reservation ? <RenderSwitch
                                    checked={true}
                                    tagName={'table_reservation'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,table_reservation:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'table_reservation'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,table_reservation:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "function_booking") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.function_booking ? <RenderSwitch
                                    checked={true}
                                    tagName={'function_booking'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,function_booking:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'function_booking'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,function_booking:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "offers_vouchers") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.offers_vouchers ? <RenderSwitch
                                    checked={true}
                                    tagName={'offers_vouchers'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,offers_vouchers:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'offers_vouchers'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,offers_vouchers:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "loyalty_cards") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.loyalty_cards ? <RenderSwitch
                                    checked={true}
                                    tagName={'loyalty_cards'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,loyalty_cards:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'loyalty_cards'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,loyalty_cards:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "payment_links") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.payment_links ? <RenderSwitch
                                    checked={true}
                                    tagName={'payment_links'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,payment_links:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'payment_links'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,payment_links:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "price_list") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.price_list ? <RenderSwitch
                                    checked={true}
                                    tagName={'price_list'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,price_list:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'price_list'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,price_list:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "remove_logo") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                    return elem?.id==activeCategory && elem?.enable
                                    
                                })?"orange-border":''} style={{ height: "50px" }}>{item.remove_logo ? <RenderSwitch
                                    checked={true}
                                    tagName={'remove_logo'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,remove_logo:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'remove_logo'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,remove_logo:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "tips_reviews") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.tips_reviews ?  <RenderSwitch
                                    checked={true}
                                    tagName={'tips_reviews'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,tips_reviews:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'tips_reviews'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,tips_reviews:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "call_waiter") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.call_waiter ?  <RenderSwitch
                                    checked={true}
                                    tagName={'call_waiter'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,call_waiter:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'call_waiter'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,call_waiter:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "clean_table") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.clean_table ?  <RenderSwitch
                                    checked={true}
                                    tagName={'clean_table'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,clean_table:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'clean_table'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,clean_table:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "customize_themes_fonts") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.customize_themes_fonts ?  <RenderSwitch
                                    checked={true}
                                    tagName={'customize_themes_fonts'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,customize_themes_fonts:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'customize_themes_fonts'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,customize_themes_fonts:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "review_booster") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.review_booster ?  <RenderSwitch
                                    checked={true}
                                    tagName={'review_booster'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,review_booster:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'review_booster'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,review_booster:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "linky_meet") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.linky_meet ? <RenderSwitch
                                    checked={true}
                                    tagName={'linky_meet'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,linky_meet:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'linky_meet'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,linky_meet:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "event_tickets") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.sell_ticket ? <RenderSwitch
                                    checked={true}
                                    tagName={'sell_ticket'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,sell_ticket:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'sell_ticket'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,sell_ticket:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "xero_integration") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.xero_integration ? <RenderSwitch
                                    checked={true}
                                    tagName={'xero_integration'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,xero_integration:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'xero_integration'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,xero_integration:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "invoice_quote") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.invoice_quote ? <RenderSwitch
                                    checked={true}
                                    tagName={'invoice_quote'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,invoice_quote:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'invoice_quote'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,invoice_quote:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "quick_sms") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.quick_sms ? <RenderSwitch
                                    checked={true}
                                    tagName={'quick_sms'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,quick_sms:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'quick_sms'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,quick_sms:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "linky_sign" || row_item.feature_id == "Linky Sign") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.linky_sign ? <RenderSwitch
                                checked={true}
                                tagName={'linky_sign'}
                                item_id={item?._id}
                                updateFunc={()=>{updateSubscription({...item,linky_sign:false})}}
                                /> : <RenderSwitch
                                checked={false}
                                tagName={'linky_sign'}
                                item_id={item?._id}
                                updateFunc={()=>{updateSubscription({...item,linky_sign:true})}}
                                />}</td>
                            } else if (row_item.feature_id == "forms") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}>{item.minisite_form ? <RenderSwitch
                                    checked={true}
                                    tagName={'minisite_form'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,minisite_form:false})}}
                                    /> : <RenderSwitch
                                    checked={false}
                                    tagName={'minisite_form'}
                                    item_id={item?._id}
                                    updateFunc={()=>{updateSubscription({...item,minisite_form:true})}}
                                    />}</td>
                            } else if (row_item.feature_id == "sort_by") {
                                return <td className={item?.subscription_category?.some(elem=>{
                                                                return elem?.id==activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px", alignItems: "center" }} >
                                    <input style={{ width: "40px", height: "30px", marginLeft: "3px" }} defaultValue={item.sort_by} name="sort_by" id={`sort_${index}`} type="number"
                                        onChange={(e) => {
                                            let sort_no = e.target.value;
                                            if (e.target.value) {
                                                updateSortBy(item, sort_no);
                                            }
                                        }} />
                                </td>
                            } else if (item.descriptive_features && item.descriptive_features.length > 0 && item.descriptive_features.includes(row_item.title)) {
                               

                            

                                return <td className={item?.subscription_category?.some(elem=>{
                                  
                                        
                                                                return elem?.id===activeCategory && elem?.enable
                                                                
                                                            })?"orange-border":''} style={{ height: "50px" }}><RenderSwitch
                                checked={true}
                                tagName={'descriptive_features'}
                                item_id={item?._id}
                                updateFunc={()=>{updateSubscription({...item,descriptive_features:Array.isArray(item?.descriptive_features)?item?.descriptive_features?.filter(elem=>elem!==row_item?.title):[]})}}
                                
                                /> </td>
                            }

                            else {
                                return (
                                    <td className={item?.subscription_category?.some(elem=>{
                                        return elem?.id==activeCategory && elem?.enable
                                        
                                    })?"orange-border":''}>
                                        <RenderSwitch
                                checked={false}
                                tagName={'descriptive_features'}
                                item_id={item?._id}
                                

                                updateFunc={()=>{
                                    
                                    updateSubscription({...item,descriptive_features:Array.isArray(item?.descriptive_features)?[...item?.descriptive_features,row_item.title]:[row_item.title]})}}
                                /> 
                                    </td>
                                )

                            }
                        }
                    })
                }

                 <Modal isOpen={promoDataOpen} toggle={togglePromoData} className="modal-body" centered={true}>
                
                                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Promotional Data</h5></ModalHeader>
                                <ModalBody>
                                    <ModalBody style={{ padding: "16px 0" }}>
                                        <div className="form-group form-row justify-content-around">
                                        
                                                              <>
                                                                <div className="col-md-5 mb-2">
                                                                  <label htmlFor="">Promotional Rate Type</label>
                                                                  <select
                                                                  defaultValue={promo_rate_type}
                                                                  style={{height:'calc(1.5em + .75rem + 2px)'}}
                                                                    onChange={event => {
                                                                      // console.log(event.target.selected, "event.target.selected")
                                                                      // console.log(event.target.value, "event.target.value")
                                                                      setpromo_rate_type(event.target.value)
                                                                    }}
                                                                    className="form-control" name="promo_rate_type" size="1" ref={register({ required: true })}>
                                                                    <option value="1" selected>Amount Based</option>
                                                                    <option value="2">Percentage Based</option>
                                                                  </select>
                                                                  {/* <input className="form-control" id="" step={0.01} disabled={is_trial === "trial" ? true : false} name="promo_rate" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                                                                    required: false,
                                                                    max: parseFloat(fees),
                                                                  })} /> */}
                                                                  {/* <span>{errors.promo_rate && 'Value can not be higher than Subscription Fee'}</span> */}
                                                                </div>
                                                                <div className="col-md-5 mb-2">
                                                                  <label htmlFor="">Promotional Rate</label>
                                                                  <input
                                                                    defaultValue={promotorItem?.promo_rate?promotorItem?.promo_rate:0}
                                                                    className="form-control" id="" step={0.01} name="promo_rate" onWheel={() => document.activeElement.blur()} type="number" ref={register({
                                                                      required: "This field is required", valueAsNumber: true,
                                                                      min: {
                                                                        value: 0,
                                                                        message: "Promotional rate cannot be negative"
                                                                      },
                                                                      max: {
                                                                        value: promo_rate_type == 1 ? parseFloat(promotorItem?.fees) : 100,
                                                                        message: promo_rate_type == 1 ? "Promotional rate cannot pass Subscription Fee amount" : "Percentage discount should be between 0-100"
                                                                      }
                                                                    })} />
                                                                  <span>{errors.promo_rate?.message
                                                                    //  && (promo_rate_type == 1 ? 'Promotional rate cannot pass Subscription Fee amount' : 'Percentage discount should be between 0-100')
                                                                  }</span>
                                                                </div>
                                                                {/* // }
                                                            // {is_trial !== "free" && */}
                                                                <div className="col-md-5 mb-2">
                                                                  <label htmlFor="validationTooltip04">Start Date</label>
                                                                  <DatePicker
                                                                    customInput={<DatePickerInput />}
                                                                    selected={start_date}
                                                                    minDate={new Date()}
                                                                    onChange={date => {
                                                                      if (moment(date).isAfter(end_date)) {
                                                                        setEndDate(null)
                                                                      }
                                                                      setStartDate(date)
                                                                    }
                                                                    }
                                                                  />
                                                                  <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(start_date).isValid()) ? 'Select valid date' : ""}</span>
                                                                </div>
                                                                {/* // }
                                                            // {!(is_trial === "trial" || is_trial === "free" ? true : false) && */}
                                                                <div className="col-md-5 mb-2">
                                                                  <label htmlFor="validationTooltip05">End Date</label>
                                                                  <DatePicker
                                                                    customInput={<DatePickerInput />}
                                                                    selected={end_date}
                                                                    minDate={start_date ? start_date : new Date()}
                                                                    onChange={date => setEndDate(date)
                                                                    }
                                                                  />
                                                                  <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(end_date).isValid()) ? 'Select valid date' : ""}</span>
                                                                </div>
                                                              </>
                                                            </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="default" onClick={togglePromoData}>Cancel</Button>
                                        <Button color="primary"  onClick={handleSubmit(onSubmit)}>Save</Button>
                                    </ModalFooter>
                                </ModalBody>
                
                            </Modal>
            </React.Fragment>
        </tr>
    )
}

export default RenderSubscription
