import React, { Fragment, useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import { DisappearedLoading } from "react-loadingg";
import {
    Button, Modal,
    ModalHeader, ModalBody,
    ModalFooter, TabContent,
    TabPane, Nav, NavItem,
    NavLink as NavLinkTab,
    Popover, PopoverHeader,
    PopoverBody
} from 'reactstrap';
import { FaList, FaStar } from "react-icons/fa";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import { CiEdit } from "react-icons/ci";
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import RightBar from '../../../components/social-app/rightBar';
import GridViewTemplateCard from "./GridViewTemplateCard";
import { MdClose } from "react-icons/md";
// import customMultiSelect from "./selectComp/CustomOptionsMultiSelect";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { TiArrowSortedDown } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import AddTemplateCategoryModal from './AddTemplateCategoryModal';
import PopoverComponent from "./component/TablePopoverComp";
import './SpeedMail.scss';
import { FaRegStar } from "react-icons/fa6";
import { MdOutlineEdit } from "react-icons/md";
import moment from "moment";
import { Field, FieldArray, reduxForm, submit, reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { VscNewFolder } from "react-icons/vsc";
import { MdOutlineDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import AddTemplateModal from "./component/AddTemplateModal"
import SecondPage from "./SpeedMailSecPage";
import { setLayoutData, setCompStyleData, setTemlpateData, resetState } from "../../../store/templateEditorData";
import { v4 as uuidv4 } from "uuid";
import { IoMdMore } from "react-icons/io";
import FolderSettingPopover from "./component/FolderSettingPopover";
import AddFolderModal from "./component/AddFolderModal";

// var Knob = require('knob')// browserify require
// var primary = localStorage.getItem('primary_color') || '#327ebc';

const renderCheckbox = ({ input }) => {
    return (
        <input
            type="checkbox"
            {...input}
            style={{ height: "15px", width: "15px", border: "1px solid #C0C6CC" }}
            checked={input.value}
        />
    );
};


const EmailTemplates = (props) => {

    const { handleSubmit, initialize, change } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const templateFormdata = useSelector((state) => state.form.templateTableForm?.values);
    // console.log(templateFormdata, "templateFormdata");
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))
    const userData = JSON.parse(localStorage.getItem("user") || "{}");

    const [modal, setModal] = useState();
    const [delete_modal, set_delete_toggle] = useState();
    const [btnloader, setBtnloader] = useState(false);
    const [searchData, setSearchData] = useState('');
    const [template_data, set_template_data] = useState([]);
    const [templatesData, setTemplateData] = useState([]);
    const [openRow, setOpenRow] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checking, setchecking] = useState(false);
    const [selcectedItems, setselcectedItems] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [loading, setloading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [viewType, setViewType] = useState('listView');
    const [catModal, setCatModal] = useState(false);
    const [catName, setCateName] = useState('');
    const [catError, setCatError] = useState(false);
    const [catgoryData, setCategoryData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({ title_name: 'all' });
    const [categoryDataId, setCategoryDataId] = useState(null);
    const [activeTableRow, setActiveTableRow] = useState(null);
    const [addTempModal, setAddTempModal] = useState(false);
    const [folderPopOver, setFolderPopOver] = useState(false);
    const [customFolders, setCustomFolder] = useState([]);
    const [activeFolder, setActiveFolder] = useState({ folderName: 'All Templates', _id: 'all_templates', isCustom: false });
    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [folderName, setFolderName] = useState('');
    const [error, setError] = useState("");
    const [optionPopOver, setOptionPopOver] = useState(false);
    const [selectedPopover, setSelectedPopover] = useState(null);
    const [hoverFolder, setHoverFolder] = useState(null);
    const [editFolder, setEditFolder] = useState(null);

    const toggleOptionPopover = () => setOptionPopOver(!optionPopOver);
    const toggleCreateFolder = () => { setCreateFolderModal(!createFolderModal) }
    const toggleFolderPopOver = () => setFolderPopOver(!folderPopOver);
    const toggle = () => { setModal(!modal) };
    const delete_toggle = () => { set_delete_toggle(!delete_modal) };
    const set_del_id = (temp_id) => {
        // set_del_templ_id(temp_id)
    };
    const toggleAddTemplateModal = () => setAddTempModal(!addTempModal);
    const toggleCatModal = () => { setCatModal(!catModal) }

    useEffect(() => {
        let getData = {};
        let ids = []
        templatesData && templatesData.length > 0 && templatesData.map(val => {
            ids.push(val._id);

            if (checked) {
                getData['select-all'] = checked
                getData[`${val._id}-checkitem`] = checked;
                setselcectedItems(ids)
            } else {
                // getData['select-all'] = checked
                // getData[`${val._id}-checkitem`] = checked;
                setselcectedItems([])
                setChecked(false)
            }
        });

        initialize(getData);

    }, [checked]);




    // const handleDeleteSubmit = (e, templ_id) => {
    //     e.preventDefault();
    //     //alert(`Submitting Name ${templ_name}`)
    //     let data = { template_id: templ_id };
    //     setBtnloader(true)
    //     if (data !== '') {
    //         client.post(api.delete_mail_template, data, async function (error, response) {
    //             if (!error) {
    //                 if (typeof response.error !== "undefined") {
    //                     try {
    //                         if (!response.error) {
    //                             toast.success("Template Deleted Successfully");
    //                             refreshPage();
    //                             delete_toggle();
    //                             setBtnloader(false)
    //                         } else {
    //                             toast.error(response.message);
    //                             setBtnloader(false)
    //                         }
    //                     } catch (e) {
    //                         toast.error("Internal error occured. Please contact support");
    //                         setBtnloader(false)
    //                     }
    //                 } else {
    //                     toast.error("Internal error occured. Please contact support");
    //                     setBtnloader(false)
    //                 }
    //             } else {
    //                 toast.error("Internal error occured. Please contact support");
    //                 setBtnloader(false)
    //             }

    //         });
    //     } else {
    //         // errors.showMessages();
    //     }

    // }

    const handleDeleteManyTemplate = () => {

        let postData = { template_ids: selcectedItems };

        setBtnloader(true);

        client.post(api.delete_many_mail_template, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Template Deleted Successfully");
                            setselcectedItems([]);
                            delete_toggle();
                            refreshPage();
                            setBtnloader(false)
                        } else {
                            toast.error(response.message);
                            setBtnloader(false)
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }
        });
    }

    const get_all_templates = async () => {
        let postdata = { auto_reminder: false };
        let current = this;
        setloading(true);
        client.post(api.get_all_mail_templates, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let newData = response.result.data.filter((curData) => curData.isNewTemplate == true);
                            set_template_data(newData);
                            setTemplateData(newData);
                            setloading(false);
                            setselcectedItems([])
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    };

    const geta_all_folders = async () => {
        let postdata = { auto_reminder: false };
        // let current = this;
        // setloading(true);
        client.post(api.get_all_folder, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setCustomFolder(response.result);
                            console.log(response.result, "folder fetch data")
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    };

    useEffect(() => {
        get_all_templates();
        geta_all_folders();
    }, []);

    const refreshPage = () => {
        get_all_templates();
        geta_all_folders();
    }

    const toggleupdateModal = () => {
        setUpdateModal(!updateModal)
    }

    const onUpdateTemplate = (event) => {
        event.preventDefault();
        let $ = [...templatesData]?.map(e => {
            delete e.templ_layout;
            return e;
        })
        const postdata = $;
        setBtnloader(true)
        client.post(api.update_many_template, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            refreshPage()
                            setUpdateModal(!updateModal)
                            setBtnloader(false)
                        } else {
                            toast.error(response.message);
                            setBtnloader(false)
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }

        });

    }

    const renderUpdateModal = () => {
        return (
            <Modal isOpen={updateModal} toggle={toggleupdateModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={onUpdateTemplate}>
                    <ModalHeader>Update Changes</ModalHeader>
                    <ModalBody>
                        <h6>Do you really want to update the changes ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleupdateModal}>Not sure!</Button>
                        <Button color="primary" type="submit" disabled={btnloader}>Yes, Please!</Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }

    const onChangeMultipleTemplate = (event, id) => {
        let { name, value } = event.target;
        if (name == "showInPromotionList") {
            value = event.target.checked
        }
        const updatedTemplateData = [...template_data]
        const template = updatedTemplateData.find(el => {
            return el._id == id
        });
        const templateIndex = updatedTemplateData.findIndex(el => {
            return el._id == id
        });
        const updatedTemplate = {
            ...template,
            [name]: value
        }
        updatedTemplateData[templateIndex] = updatedTemplate;
        set_template_data(updatedTemplateData);
        setTemplateData(updatedTemplateData);
    }

    useEffect(() => {
        if (searchData != "") {
            const handler = setTimeout(() => {
                const filteredData = template_data.filter((item) => item.templ_name.toLowerCase().includes(searchData.toLowerCase()));
                set_template_data(filteredData);
            }, 800);

            return () => { clearTimeout(handler) };
        } else {
            set_template_data(templatesData);
        }

    }, [searchData]);

    const updateTempCatRef = async (data) => {

        let postData = {
            update_id: selectedData._id,
            templateCategory: data
        };

        client.post(api.update_cate_mail_template, postData, async function (error, response) {
            // console.log(response, "response");
            if (!error) {
                if (!response.error) {
                    setPopoverOpen(false);
                    setOpenRow(null);
                    setSelectedData();
                    refreshPage();
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    const toggleDropdown = (data) => {
        setSelectedData(data);
        setPopoverOpen(true);
        setOpenRow(data._id);
    }

    const updateTemplate = (templateDetails) => {

        let postData = {
            template_id: templateDetails._id,
            templ_layout: templateDetails.templ_layout,
            rawLayoutData: templateDetails.rawLayoutData,
            isFavourite: !templateDetails.isFavourite,
            lastModifiedBy: userData.full_name,
        };

        client.post(api.update_mail_template_layout, postData, async function (error, response) {
            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Updated Successfully");
                            refreshPage();
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            //   setTemplateLoading(false);
        });
    }


    let tabStyle = {
        width: "fit-content",
        backgroundColor: "#327EBC",
        color: "#fff",
        padding: "7px 10px",
        borderRadius: "0.2rem",
        cursor: "pointer",
        fontSize: "13px"
    };

    const resetData = () => {
        setCatError(false);
        toggleCatModal();
        setCateName('');
    }

    const fetchCatData = async () => {
        let postdata = { auto_reminder: false };
        client.post(api.fetch_template_category, postdata, async function (error, response) {
            // console.log(response, "response");
            if (!error) {
                if (!response.error) {
                    setCategoryData(response.result);
                    setCategoryDataId(response.category_Id);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    useEffect(() => {
        fetchCatData();
    }, [])


    let folderItems = [
        { folderName: 'All Templates', _id: 'all_templates', isCustom: false },
        { folderName: 'Favorites', _id: 'favorites', isCustom: false },
        { folderName: 'Associated Templates', _id: 'associated_templates', isCustom: false },
        { folderName: 'Created by me', _id: 'created_by_me', isCustom: false },
        { folderName: 'Shared with me', _id: 'shared_with_me ', isCustom: false },
        { folderName: 'Public Email Templates', _id: 'public_mmail_templates', isCustom: false }
    ];

    useEffect(() => {

        if (activeFolder.isCustom) {
            let sortData = template_data.filter((filters) => filters.folderRef && filters.folderRef.toLowerCase() === activeFolder.folder_name.toLowerCase());
            setTemplateData(sortData);
        } else {

            if (activeFolder._id === "all_templates") {
                setTemplateData(template_data);
            } else if (activeFolder._id === "favorites") {
                let sortData = template_data.filter((filters) => filters.isFavourite === true);
                setTemplateData(sortData);
            } else if (activeFolder._id === "associated_templates") {
                setTemplateData([]);
                // let sortData = template_data.filter((filters) => filters.isFavourite === true);
            } else if (activeFolder._id === "created_by_me") {
                setTemplateData([]);
                // let sortData = template_data.filter((filters) => filters.lastModifiedBy.toLowerCase() === userData.full_name.toLowerCase());
            } else if (activeFolder._id === "shared_with_me") {
                setTemplateData([]);
                // let sortData = template_data.filter((filters) => filters.lastModifiedBy.toLowerCase() === userData.full_name.toLowerCase());
            } else if (activeFolder._id === "public_mmail_templates") {
                setTemplateData([]);
                // let sortData = template_data.filter((filters) => filters.lastModifiedBy.toLowerCase() === userData.full_name.toLowerCase());
            }
        }
    }, [activeFolder])

    useEffect(() => {

        if (template_data.length > 0) {

            if (selectedCategory.title_name.toLowerCase() == 'all') {
                setTemplateData(template_data);
            } else {
                let filterTemplate = template_data.filter((filData) => filData.template_category_ref?.label.toLowerCase() === selectedCategory.title_name.toLowerCase());
                setTemplateData(filterTemplate);
            }
        }

    }, [selectedCategory])

    // console.log(templatesData, "templatesData");
    // console.log(activeFolder, "activeFolder");
    // console.log(selectedCategory, "selectedCategory");
    // console.log(customFolders, "customFolders");

    const resetStates = () => {
        setCreateFolderModal(false)
        setActiveFolder({ folderName: 'All Templates', _id: 'all_templates', isCustom: false });
        setselcectedItems([]);
        setFolderName('');
        setBtnloader(false);
        setIsUpdateFolderName(false);
        setEditFolder(null);
        refreshPage();
    }

    const deleteTemplateFolder = () => {

        let postData = { folder_id: activeFolder._id };

        client.post(api.delete_folder, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Folder Deleted Successfully");
                            setCustomFolder(response.result);
                            refreshPage();
                        } else {
                            toast.error(response.message);
                            setBtnloader(false)
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }
        });
    }

    const moveToFolder = (isNewFolder, folderData, newFolderName) => {

        try {

            setBtnloader(true);

            let postData = {
                templateIds: selcectedItems,
                folderData: folderData ? folderData : activeFolder,
                newFolderName: newFolderName ? newFolderName.trim() : '',
                moveToNew: isNewFolder
            };

            // console.log(postData, "postData");

            client.post(api.move_to_folder, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success("Template Moved Successfully");
                                setCustomFolder(response.result);
                                resetStates();
                            } else {
                                toast.error(response.message);
                                setBtnloader(false)
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                            setBtnloader(false)
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            });
        } catch (error) {
            console.log(error, "move to folder error")
        }
    }

    const [isUpdateFolderName, setIsUpdateFolderName] = useState(false);


    const renameTemplateFolder = (newFolderName) => {

        let postData = {
            folder_id: editFolder._id,
            new_folder_name: newFolderName.trim()
        };

        setBtnloader(true)

        client.post(api.rename_folder, postData, async function (error, response) {
            // console.log(error, "error");
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response, "response---")
                            toast.success("Folder Name Updated Successfully");
                            setCustomFolder(response.result);
                            resetStates();

                        } else {
                            toast.error(response.message);
                            setBtnloader(false);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }
        });
    }


    const toggleUpateFolderName = () => {
        toggleCreateFolder();
        setFolderName(selectedPopover?.folder_name);
        setIsUpdateFolderName(true);
    }




    return (
        <Fragment>

            {
                pageNo === 2 ?
                    <>
                        <SecondPage
                            template_data={templatesData}
                            activeTableRow={activeTableRow}
                            setActiveTableRow={setActiveTableRow}
                            setselcectedItems={setselcectedItems}
                            setPageNo={setPageNo}
                            refreshPage={refreshPage}
                        />
                    </> :
                    <div className="p-0 w-100">
                        {
                            Array.isArray(selcectedItems) && selcectedItems.length > 0 ? <>
                                <div className='d-flex align-items-center justify-content-between' style={{ padding: "1rem" }}>
                                    <div className='d-flex action_section' style={{ gap: "15px", marginLeft: "270px" }}>
                                        <span
                                            style={{ cursor: "pointer" }}
                                            id='new-folder'
                                            onClick={() => { toggleFolderPopOver() }}
                                        ><VscNewFolder style={{ color: "#000000", fontSize: "20px" }} /></span>
                                        <span style={{ cursor: "pointer" }} onClick={() => { delete_toggle() }}><MdOutlineDelete style={{ color: "#000000", fontSize: "20px" }} /></span>
                                        {(folderPopOver && document.getElementById('new-folder')) && <Popover
                                            isOpen={folderPopOver}
                                            target={'new-folder'}
                                            placement="bottom"
                                            trigger="legacy"
                                            toggle={() => { toggleFolderPopOver() }}
                                        >
                                            <PopoverBody className="position-relative p-0">
                                                <h2 style={{ fontSize: "1rem", borderBottom: "1px solid gainsboro", padding: "1rem 1rem 0.4rem 1rem" }}>MOVE TO FOLDER</h2>
                                                <div className='w-100 folder_list p-0' style={{ minHeight: "130px" }}>
                                                    {
                                                        customFolders.length > 0 && customFolders.map((mapData) => {
                                                            return (
                                                                <div
                                                                    className='folder_list_item'
                                                                    style={{ color: mapData._id === activeFolder._id ? "#194471" : "#000000" }}
                                                                    onClick={() => {
                                                                        setActiveFolder(mapData);
                                                                        moveToFolder(false, mapData, null);
                                                                    }}
                                                                >
                                                                    {mapData.folder_name}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div
                                                    className='w-100 d-flex align-items-center justify-content-center'
                                                    style={{ padding: "0.7rem 1rem", borderTop: "1px solid gainsboro", cursor: "pointer" }}
                                                    onClick={() => {
                                                        toggleCreateFolder();
                                                        toggleFolderPopOver();
                                                    }}
                                                >
                                                    <IoMdAdd style={{ color: "#21578A", fontSize: "20px" }} />
                                                    <span style={{ color: "#21578A" }}>New Folder</span>
                                                </div>
                                            </PopoverBody>
                                        </Popover>}
                                    </div>
                                    <div style={{ fontSize: "15px", fontWeight: "600" }}>{selcectedItems.length} Template selected</div>
                                </div>
                            </> :
                                <>
                                    <div className='d-flex align-items-center justify-content-between' style={{ padding: "1rem" }}>
                                        <div className='w-100 d-flex align-items-center' style={{ flexWrap: "wrap" }}>
                                            <div className='mt-2' style={tabStyle} onClick={() => toggleCatModal()}>Add Group</div>
                                            <div
                                                className='mx-2 mt-2'
                                                style={{
                                                    ...tabStyle,
                                                    padding: "7px 10px",
                                                    backgroundColor: selectedCategory?.title_name.toLowerCase() == 'all' ? '#327EBC' : "#fff",
                                                    color: selectedCategory?.title_name.toLowerCase() == 'all' ? "#fff" : "#000000",
                                                    border: selectedCategory?.title_name.toLowerCase() == 'all' ? "1px solid #327EBC" : "1px solid rgb(35, 39, 43)"
                                                }}
                                                onClick={() => { setSelectedCategory({ title_name: 'all' }) }}
                                            >All</div>
                                            {catgoryData && catgoryData.length > 0 && catgoryData.map((currItem) => {
                                                return (
                                                    <div
                                                        className='mr-2 mt-2 text-capitalize'
                                                        style={{
                                                            ...tabStyle,
                                                            backgroundColor: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? '#327EBC' : "#fff",
                                                            color: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? "#fff" : "#000000",
                                                            border: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? "1px solid #327EBC" : "1px solid rgb(35, 39, 43)"
                                                        }}
                                                        onClick={() => { setSelectedCategory({ title_name: currItem.title_name }) }}
                                                    >
                                                        {currItem.title_name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='ml-auto d-flex align-items-center'>
                                            <div className='mr-2 position-relative'>
                                                <style>
                                                    {`input::placeholder {color: gray;}`}</style>
                                                <input
                                                    name='search'
                                                    className='search_field'
                                                    placeholder='Search...'
                                                    style={{
                                                        border: "#dfe8f1 solid 1px",
                                                        borderRadius: "3px",
                                                        padding: "10px",
                                                        fontSize: "0.75rem",
                                                        outlineColor: '#dfe8f1',
                                                        minWidth: "250px"
                                                    }}
                                                    value={searchData}
                                                    onChange={(event) => { setSearchData(event.target.value) }}
                                                />
                                                {searchData.length > 0 && <span
                                                    style={{ position: "absolute", right: "7px", top: "10px", cursor: "pointer" }}
                                                    onClick={() => { setSearchData('') }}
                                                >
                                                    <MdClose style={{ fontSize: "17px" }} />
                                                </span>}
                                            </div>
                                            <div className=' p-1 border rounded'>
                                                <div className='d-flex align-items-center'>
                                                    <span
                                                        style={{ padding: "0.4rem 0.5rem", backgroundColor: viewType === "listView" ? "#327ebc " : "#fff" }}
                                                        onClick={() => { setViewType('listView') }}
                                                    >
                                                        <FaList
                                                            style={{
                                                                color: viewType === "listView" ? "#fff" : "grey",
                                                                backgroundColor: viewType === "listView" ? "327ebc" : "#fff",
                                                                width: '18px',
                                                                height: '18px',
                                                                verticalAlign: "middle"
                                                            }}
                                                        />
                                                    </span>
                                                    <span
                                                        style={{ padding: "0.4rem 0.5rem", backgroundColor: viewType === "gridView" ? "#327ebc " : "#fff" }}
                                                        onClick={() => { setViewType('gridView') }}
                                                    >
                                                        <TfiLayoutGrid3Alt
                                                            style={{
                                                                color: viewType === "gridView" ? "#fff" : "grey",
                                                                backgroundColor: viewType === "gridView" ? "327ebc" : "#fff",
                                                                width: '18px',
                                                                height: '18px',
                                                                verticalAlign: "middle"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr className='m-0' />

                        {!loading ? <>
                            {
                                templatesData ?
                                    <div>
                                        {
                                            viewType == "gridView" ? <>
                                                <div className="row">
                                                    {templatesData.map((item, index) => {
                                                        return (
                                                            <GridViewTemplateCard
                                                                item={item}
                                                                index={index}
                                                                access_level={access_level}
                                                                delete_toggle={delete_toggle}
                                                                set_del_id={set_del_id}
                                                                onChangeMultipleTemplate={onChangeMultipleTemplate}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </> : <>

                                                <div className="d-flex w-100" style={{ padding: "0rem" }}>
                                                    <div className='folder_list' style={{ minWidth: "260px", padding: "1rem 0rem", borderRight: "1px solid gainsboro" }}>
                                                        <div
                                                            className='my-2 d-flex align-items-center'
                                                            style={{ cursor: "pointer", backgroundColor: "#327ebc", width: "fit-content", gap: "7px", borderRadius: "0.3rem", padding: "0.4rem 0.7rem", margin: "0rem 1rem" }}
                                                            onClick={() => toggleAddTemplateModal()}
                                                        >
                                                            <IoMdAdd style={{ color: "#fff", fontSize: "20px" }} />
                                                            <span style={{ fontSize: "15px", color: "#fff" }}>Add Template</span>
                                                        </div>
                                                        {folderItems.map((folData) => {
                                                            return (
                                                                <div
                                                                    className='folder_list_item'
                                                                    style={{ color: folData._id === activeFolder._id ? "#194471" : "#000000" }}
                                                                    onClick={() => {
                                                                        setActiveFolder({ ...folData, isCustom: false });
                                                                    }}
                                                                >
                                                                    {folData.folderName}
                                                                </div>
                                                            )
                                                        })}
                                                        {customFolders.length > 0 &&
                                                            customFolders.map((mapData, index) => (
                                                                <div
                                                                    key={mapData._id}
                                                                    className="folder_list_item"
                                                                    style={{ color: mapData._id === activeFolder?._id ? "#194471" : "#000000" }}
                                                                    onClick={() => setActiveFolder({ ...mapData, isCustom: true })}
                                                                    onMouseOver={() => setHoverFolder(mapData)}
                                                                    onMouseLeave={() => {
                                                                        setHoverFolder(null);
                                                                        setSelectedPopover(null);
                                                                    }}
                                                                >
                                                                    <span className="text-capitalize">{mapData.folder_name}</span>

                                                                    {/* More Options Button */}
                                                                    {hoverFolder && hoverFolder._id === mapData._id ? (
                                                                        <span
                                                                            id={`folder_option_${index}`} // 🔹 Unique ID for Popover target
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Prevents parent div click
                                                                                setSelectedPopover(mapData);
                                                                                setEditFolder(mapData);
                                                                                toggleOptionPopover();
                                                                            }}
                                                                        >
                                                                            <IoMdMore style={{ fontSize: "20px" }} />
                                                                        </span>
                                                                    ) : (
                                                                        // 🔹 Placeholder when hover is inactive
                                                                        <span
                                                                            id={`folder_option_${index}`}
                                                                            style={{ fontSize: "12px", color: "#aaa", paddingLeft: "5px", width: "30px" }}
                                                                        >
                                                                        </span>
                                                                    )}

                                                                    {/* Popover Component */}
                                                                    {optionPopOver &&
                                                                        selectedPopover?._id === mapData._id &&
                                                                        document.getElementById(`folder_option_${index}`) && (
                                                                            <FolderSettingPopover
                                                                                isOpen={optionPopOver}
                                                                                targetId={`folder_option_${index}`} // 🔹 Pass the correct target ID
                                                                                toggle={toggleOptionPopover}
                                                                                template_data={template_data}
                                                                                activeFolder={activeFolder}
                                                                                deleteTemplateFolder={deleteTemplateFolder}
                                                                                renameTemplateFolder={renameTemplateFolder}
                                                                                toggleUpateFolderName={toggleUpateFolderName}
                                                                                selectedPopover={selectedPopover}
                                                                            />
                                                                        )}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='w-100'>
                                                        {
                                                            templatesData.length > 0 ? <div className="table-responsive speedMailTableList">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr style={{ background: '#f9fafe' }}>
                                                                            {/* <th scope="col" style={{ textAlign: "center" }}>#</th> */}
                                                                            <th scope="col">
                                                                                <div scope="d-flex" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={'select-all'}
                                                                                        style={{ height: "15px", width: "15px", border: "1px solid #C0C6CC" }}
                                                                                        checked={checked}
                                                                                        onChange={(e) => {
                                                                                            let isChecked = e.target.checked;
                                                                                            setChecked(isChecked)
                                                                                            setchecking(true)
                                                                                        }}
                                                                                    /> Template Name
                                                                                </div>
                                                                            </th>
                                                                            <th scope="col">Modified By</th>
                                                                            <th scope="col">Last Used</th>
                                                                            {/* <th scope="col">Action</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {templatesData.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className='table_row_style'
                                                                                    onMouseOver={() => { setActiveTableRow(item) }}
                                                                                    onMouseLeave={() => { setActiveTableRow(null) }}
                                                                                >
                                                                                    {/* <th scope="row" style={{ verticalAlign: "middle", textAlign: "center" }}>{index + 1}</th> */}
                                                                                    <td>
                                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                                            <div className='d-flex' style={{ gap: "10px" }}>
                                                                                                <div className='mt-1 d-flex align-items-start' style={{ gap: "7px" }}>
                                                                                                    {activeTableRow?._id == item._id || (selcectedItems.length > 0 && selcectedItems.includes(`${item._id}`)) ?
                                                                                                        <>
                                                                                                            <Field
                                                                                                                name={`${item._id}-checkitem`}
                                                                                                                component={renderCheckbox}
                                                                                                                type="checkbox"
                                                                                                                onChange={(e) => {
                                                                                                                    setselcectedItems((prevData) => {
                                                                                                                        if (e.target.checked) {
                                                                                                                            return [...prevData, item._id];
                                                                                                                        } else {
                                                                                                                            return prevData.filter((currFil) => currFil !== item._id);
                                                                                                                        }
                                                                                                                    });
                                                                                                                }}
                                                                                                            />
                                                                                                        </> :
                                                                                                        <span style={{ display: "inline-block", width: "15px", height: "15px" }}></span>
                                                                                                    }
                                                                                                    <span
                                                                                                        style={{ marginTop: "-3px" }}
                                                                                                        onClick={() => { updateTemplate(item) }}
                                                                                                    >
                                                                                                        {
                                                                                                            item?.isFavourite ?
                                                                                                                <FaStar style={{ fontSize: "17px" }} color='#FAA81A' />
                                                                                                                : <FaRegStar style={{ fontSize: "17px" }} color='#DCDCDC' />
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className='mb-0 templateNameStyle'>{item.templ_name}</p>
                                                                                                    <div className='d-flex align-items-center' style={{ gap: "4px" }}>
                                                                                                        <p
                                                                                                            className='text-capitalize'
                                                                                                            style={{ color: "#327ebc", fontWeight: "600" }}
                                                                                                            onClick={() => {
                                                                                                                setPageNo(2);
                                                                                                                //   dispatch(setTemlpateData(templateDetail));
                                                                                                                dispatch(setLayoutData(item.rawLayoutData.templateLayoutData));
                                                                                                                dispatch(setCompStyleData({ type: "outercolor", data: item.rawLayoutData.parentCompStyle }));
                                                                                                                dispatch(setCompStyleData({ type: "innercolor", data: item.rawLayoutData.childCompStyle }));
                                                                                                            }}
                                                                                                        >{item?.template_category_ref?.label}</p>
                                                                                                        <span style={{ fontWeight: "800", color: "#8b9ab9" }}>.</span>
                                                                                                        <p className='' style={{ color: "#8b9ab9" }}>{item.templ_subject}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {activeTableRow?._id == item._id ? (<span
                                                                                                className='mr-2'
                                                                                                style={{ verticalAlign: "middle", height: "fit-content" }}
                                                                                                onClick={() => {
                                                                                                    history.push(item.isNewTemplate ? `/settings/update_template_details/${item._id}` : `/settings/mail_template_details/${item._id}`);
                                                                                                }}
                                                                                            >
                                                                                                <MdOutlineEdit style={{ fontSize: "22px", color: "#7C7C7C" }} />
                                                                                            </span>) : (
                                                                                                <span className="mr-2" style={{ opacity: 0.5, cursor: "not-allowed" }}>
                                                                                                    <MdOutlineEdit style={{ fontSize: "22px", color: "#ffffff" }} />
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <p style={{ textTransform: "capitalize", color: "#000000" }}>{userData?.full_name}</p>
                                                                                            <p style={{ color: "#8b9ab9" }}>{moment(item.action_date).format("MMM YYYY")}</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>-</td>
                                                                                    {/* <td style={{ textAlign: "center" }}>
                                                                                        {access_level == 3 && <i
                                                                                            className="btn fa fa-trash"
                                                                                            style={{ color: "rgb(228, 86, 110)", maxWidth: "30px", padding: "0px" }}
                                                                                            onClick={() => {
                                                                                                delete_toggle();
                                                                                                set_del_id(item._id);
                                                                                            }}
                                                                                        >
                                                                                        </i>}
                                                                                    </td> */}
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                                : <div className='d-flex align-items-center justify-content-center' style={{ height: "200px" }}>
                                                                    <h6 style={{ textAlign: "center" }}>No template added!</h6>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                    : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>
                            }
                        </> : <>
                            <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                    <DisappearedLoading size="medium" color="#514F4E" />
                                </div>
                            </div>
                        </>}
                    </div>
            }

            {renderUpdateModal()}

            {addTempModal && <AddTemplateModal
                isOpen={addTempModal}
                toggle={toggleAddTemplateModal}
                template_data={template_data}
                catgoryData={catgoryData}
            />}

            <Modal isOpen={delete_modal} toggle={delete_toggle} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader toggle={delete_toggle}>Delete Template</ModalHeader>
                    <ModalBody>
                        <h6 className='text-center'>Do you really want to delete selected Templates ?</h6>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: "center", gap: "2rem" }}>
                        <Button color="danger" type="submit" onClick={(e) => { handleDeleteManyTemplate() }} disabled={btnloader}>Delete</Button>
                        <Button color={'secondary'} className='cancelBtn' onClick={delete_toggle}>Cancel</Button>
                    </ModalFooter>
                </form>
            </Modal>


            {createFolderModal && <AddFolderModal
                isOpen={createFolderModal}
                toggle={toggleCreateFolder}
                customFolders={customFolders}
                moveToFolder={moveToFolder}
                setFolderName={setFolderName}
                btnloader={btnloader}
                folderName={folderName}
                renameTemplateFolder={renameTemplateFolder}
                isUpdate={isUpdateFolderName}
            />}

            {catModal && <AddTemplateCategoryModal
                isOpen={catModal}
                toggle={toggleCatModal}
                catgoryData={catgoryData}
                fetchCatData={fetchCatData}
                categoryDataId={categoryDataId}
            />}
        </Fragment >
    );

}

export default reduxForm({
    form: "templateTableForm",
    initialized: true,
})(EmailTemplates);

// export default EmailTemplates;