import React, { useState, useRef, useEffect } from 'react';
import {
    MdFormatBold, MdFormatItalic,
    MdFormatUnderlined, MdStrikethroughS,
    MdSubscript, MdSuperscript,
    MdFormatAlignLeft, MdFormatAlignCenter,
    MdFormatAlignRight, MdOutlineBorderColor
} from 'react-icons/md';
import {
    AiOutlineBorder, AiOutlineBorderTop,
    AiOutlineBorderBottom, AiOutlineBorderLeft,
    AiOutlineBorderRight, AiOutlineFontColors
} from "react-icons/ai";
import { IoIosLink } from "react-icons/io";
import { LuUnlink } from "react-icons/lu";
import { BiSolidColorFill } from "react-icons/bi";
import { RiLineHeight } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import './EmailTemplate.scss';
import { setLayoutData, updateTemplateLayout } from "../../../../store/templateEditorData";
import CustomDropdown from "./CustomDropdown/CustomDropDown";
import ColorPicker from "./ColorPickerComp/ColorPickerComp";


export const ToolBarComponent = () => {

    const dispatch = useDispatch();
    const { templateLayoutData, styleElement, mainElement } = useSelector((state) => state.emailTemplateEditorData);

    const [editorState, setEditorState] = useState({
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        strikethrough: false,
        subScript: false,
        superScript: false,
        textAlign: 'left',
        fontFamily: 'Arial',
        fontSize: '16px',
        color: '#000000',
        lineHeight: '1',
        backgroundColor: '#ffffff',
        background: "#ffffff"
    });

    const updateStyle = (property, value) => {
        setEditorState(prevState => ({ ...prevState, [property]: value }));

        if (
            styleElement.type === "heading1"
            || styleElement.type === "heading2"
            || styleElement.type === "paragraph"
            || styleElement.type === "normalbutton"
            || styleElement.type === "roundedbutton"
            || styleElement.type === "ovalbutton"
        ) {
            const updatedLayout = templateLayoutData.map((item) => {
                if (item.id === styleElement.id) {
                    if (property == "background" || property == 'textAlign') {
                        return { ...item, containerStyle: { ...item.containerStyle, [property]: value } }
                    } else {
                        return { ...item, style: { ...item.style, [property]: value } }
                    }
                } else {
                    return item;
                }
            });

            dispatch(setLayoutData(updatedLayout));
        } else if (styleElement.toolBarRef === "column" || styleElement.toolBarRef === "image" || styleElement.toolBarRef === "text" || styleElement.toolBarRef === 'spacer') {

            let currData = templateLayoutData.find((currData) => currData.type === mainElement.type);
            if (currData) {
                // console.log(currData, "currData");
                // console.log(styleElement, "styleElement");
                let parentElement = { ...currData };
                let hasBorder = property.includes("border");

                if (property == "background" || property == 'textAlign' || hasBorder) {
                    let updatedLayout = { ...parentElement, containerStyle: { ...parentElement.containerStyle, [property]: value } };
                    dispatch(updateTemplateLayout({ data: updatedLayout }));
                } else {

                    const styleData = parentElement.content.map((currData) => currData.id === styleElement?.id ? { ...currData, style: { ...currData.style, [property]: value } } : currData);
                    let updatedLayout = { ...parentElement, content: styleData };
                    dispatch(updateTemplateLayout({ data: updatedLayout }));
                }

            } else {
                console.log("Data not found");
            }
        }
    };

    let fontFamily = [
        { label: "Arial", value: "Arial", style: { fontFamily: "Arial" }, Icon: null, type: "fontFamily" },
        { label: "Calibri", value: "Calibri", style: { fontFamily: "Calibri" }, Icon: null, type: "fontFamily" },
        { label: "Courier New", value: "Courier New", style: { fontFamily: "Courier New" }, Icon: null, type: "fontFamily" },
        { label: "Comic Sans MS", value: "Comic Sans MS", style: { fontFamily: "Comic Sans MS" }, Icon: null, type: "fontFamily" },
        { label: "Georgia", value: "Georgia", style: { fontFamily: "Georgia" }, Icon: null, type: "fontFamily" },
        { label: "Lucida Console", value: "Lucida Console", style: { fontFamily: "Lucida Console" }, Icon: null, type: "fontFamily" },
        { label: "Lucida Sans Unicode", value: "Lucida Sans Unicode", style: { fontFamily: "Lucida Sans Unicode" }, Icon: null, type: "fontFamily" },
        { label: "MS Serif", value: "MS Serif", style: { fontFamily: "MS Serif" }, Icon: null, type: "fontFamily" },
        { label: "Tahoma", value: "Tahoma", style: { fontFamily: "Tahoma" }, Icon: null, type: "fontFamily" },
        { label: "Times New Roman", value: "Times New Roman", style: { fontFamily: "Times New Roman" }, Icon: null, type: "fontFamily" },
        { label: "Trebuchet MS", value: "Trebuchet MS", style: { fontFamily: "Trebuchet MS" }, Icon: null, type: "fontFamily" },
        { label: "Verdana", value: "Verdana", style: { fontFamily: "Verdana" }, Icon: null, type: "fontFamily" }
    ];

    let fontSize = [
        { label: "8", value: "8px", style: null, Icon: null, type: "fontSize" },
        { label: "10", value: "10px", style: null, Icon: null, type: "fontSize" },
        { label: "12", value: "12px", style: null, Icon: null, type: "fontSize" },
        { label: "14", value: "14px", style: null, Icon: null, type: "fontSize" },
        { label: "16", value: "16px", style: null, Icon: null, type: "fontSize" },
        { label: "18", value: "18px", style: null, Icon: null, type: "fontSize" },
        { label: "20", value: "20px", style: null, Icon: null, type: "fontSize" },
        { label: "22", value: "22px", style: null, Icon: null, type: "fontSize" },
        { label: "24", value: "24px", style: null, Icon: null, type: "fontSize" },
        { label: "28", value: "28px", style: null, Icon: null, type: "fontSize" },
        { label: "36", value: "36px", style: null, Icon: null, type: "fontSize" }
    ];

    let lineHeight = [
        { label: "1 (Single)", value: "1", style: null, Icon: null, type: "lineHeight" },
        { label: "1.2 (Normal)", value: "1.2", style: null, Icon: null, type: "lineHeight" },
        { label: "1.5", value: "1.5", style: null, Icon: null, type: "lineHeight" },
        { label: "2.0 (Double)", value: "2.5", style: null, Icon: null, type: "lineHeight" }
    ];

    let borders = [
        { label: "", value: "1px solid black", style: null, Icon: <AiOutlineBorderTop style={{ color: "#000000", fontSize: "21px", marginRight: "" }} />, type: "borderTop" },
        { label: "", value: "1px solid black", style: null, Icon: <AiOutlineBorderBottom style={{ color: "#000000", fontSize: "21px", marginRight: "" }} />, type: "borderBottom" },
        { label: "", value: "1px solid black", style: null, Icon: <AiOutlineBorderLeft style={{ color: "#000000", fontSize: "21px", marginRight: "" }} />, type: "borderLeft" },
        { label: "", value: "1px solid black", style: null, Icon: <AiOutlineBorderRight style={{ color: "#000000", fontSize: "21px", marginRight: "" }} />, type: "borderRight" }
    ];

    let borderTypes = [
        { label: "Solid", value: "solid", style: null, Icon: null, type: "borderStyle" },
        { label: "Dotted", value: "dotted", style: null, Icon: null, type: "borderStyle" },
        { label: "Dashed", value: "dashed", style: null, Icon: null, type: "borderStyle" },
        { label: "Double", value: "double", style: null, Icon: null, type: "borderStyle" }
    ];

    let borderSize = [
        { label: "1px", value: "1px", style: null, Icon: null, type: "borderWidth" },
        { label: "2px", value: "2px", style: null, Icon: null, type: "borderWidth" },
        { label: "3px", value: "3px", style: null, Icon: null, type: "borderWidth" },
        { label: "4px", value: "4px", style: null, Icon: null, type: "borderWidth" },
        { label: "5px", value: "5px", style: null, Icon: null, type: "borderWidth" },
        { label: "6px", value: "6px", style: null, Icon: null, type: "borderWidth" },
        { label: "7px", value: "7px", style: null, Icon: null, type: "borderWidth" },
        { label: "8px", value: "8px", style: null, Icon: null, type: "borderWidth" },
        { label: "9px", value: "9px", style: null, Icon: null, type: "borderWidth" },
        { label: "10px", value: "10px", style: null, Icon: null, type: "borderWidth" }
    ];

    let imageWidth = [
        { label: "Banner", value: "0rem", style: null, Icon: null, type: "padding" },
        { label: "Fit", value: "100%", style: null, Icon: null, type: "width" },
        { label: "Small", value: "25%", style: null, Icon: null, type: "width" },
        { label: "Medium", value: "50%", style: null, Icon: null, type: "width" },
        { label: "Original", value: "100%", style: null, Icon: null, type: "width" },
    ];

    const handleSelection = (data) => {
        updateStyle(data.type, data.value)
    };


    let hasBorder = Object.keys(editorState).some(key => key.includes("border"));

    // const toolbarRef = useRef(null);
    // const [isFixed, setIsFixed] = useState(false);
    // const [toolbarWidth, setToolbarWidth] = useState("auto");
    // console.log(isFixed, "isFixed");
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (toolbarRef.current) {
    //             const toolbarRect = toolbarRef.current.getBoundingClientRect();

    //             // Fix the toolbar when it reaches the top of the viewport
    //             if (toolbarRect.top <= 0) {
    //                 setIsFixed(true);
    //                 setToolbarWidth(`${toolbarRef.current.offsetWidth}px`); // Maintain width
    //             } else {
    //                 setIsFixed(false);
    //             }
    //         }
    //     };

    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, []);

    return (
        <div
            className={`p-2 rounded shadow-sm text_tool_bar flex items-center gap-2 `}
        // ref={toolbarRef}
        >
            {(styleElement.toolBarRef == "text" || styleElement.toolBarRef == "button") && <CustomDropdown dropDownStyle={{ width: "190px" }} options={fontFamily} onSelect={handleSelection} />}
            {(styleElement.toolBarRef == "text" || styleElement.toolBarRef == "button") && <CustomDropdown dropDownStyle={{ width: "auto" }} options={fontSize} onSelect={handleSelection} />}
            {(styleElement.toolBarRef == "image") && <CustomDropdown dropDownStyle={{ width: "190px" }} options={imageWidth} onSelect={handleSelection} />}

            {[
                { icon: <MdFormatBold />, prop: 'fontWeight', value: editorState.fontWeight === 'bold' ? 'normal' : 'bold', editType: ["text", "button"] },
                { icon: <MdFormatItalic />, prop: 'fontStyle', value: editorState.fontStyle === 'italic' ? 'normal' : 'italic', editType: ["text", "button"] },
                { icon: <MdFormatUnderlined />, prop: 'textDecoration', value: editorState.textDecoration === 'underline' ? 'none' : 'underline', editType: ["text", "button"] },
                { icon: <MdStrikethroughS />, prop: 'textDecoration', value: editorState.textDecoration === 'line-through' ? 'none' : 'line-through', editType: ["text"] },
                { icon: <MdSubscript />, prop: 'subScript', value: !editorState.subScript, editType: ["text"] },
                { icon: <MdSuperscript />, prop: 'superScript', value: !editorState.superScript, editType: ["text"] },
                { icon: <MdFormatAlignLeft />, prop: 'textAlign', value: 'left', editType: ["text", "button", "image"] },
                { icon: <MdFormatAlignCenter />, prop: 'textAlign', value: 'center', editType: ["text", "button", "image"] },
                { icon: <MdFormatAlignRight />, prop: 'textAlign', value: 'right', editType: ["text", "button", "image"] }
            ]
                .filter(({ editType }) => editType.includes(styleElement.toolBarRef.toLowerCase()))
                .map(({ icon, prop, value }, index) => (
                    <div
                        key={index}
                        className={`element ${editorState[prop] === value ? 'active' : ''}`}
                        onClick={() => updateStyle(prop, value)}
                    >
                        {icon}
                    </div>
                ))}

            {
                styleElement.toolBarRef == "image" ? <>
                    <div
                        className={`element`}
                    // onClick={() => updateStyle(prop, value)}
                    >
                        <IoIosLink style={{ color: "#000000", fontSize: "21px" }} />
                    </div>
                    <div
                        className={`element`}
                    // onClick={() => updateStyle(prop, value)}
                    >
                        <LuUnlink style={{ color: "#000000", fontSize: "21px" }} />
                    </div>
                </> : <></>
            }

            {
                styleElement.toolBarRef == "text" && <CustomDropdown
                    type="lineHeight"
                    dropDownStyle={{ width: "150px" }}
                    options={lineHeight}
                    onSelect={handleSelection}
                    icon={<RiLineHeight style={{ color: "#000000", fontSize: "21px" }} />}
                />
            }

            {(styleElement.toolBarRef == "text" || styleElement.toolBarRef == "button") && <ColorPicker
                colorValue={editorState.backgroundColor}
                icon={<AiOutlineFontColors style={{ color: "#000000", fontSize: "21px" }} />}
                handleOnChange={(color) => updateStyle('color', color.hex)}
                popUpStyle={{ top: "2.4rem", left: "-110px" }}
            />}

            {(styleElement.toolBarRef == "text" || styleElement.toolBarRef == "button") && <ColorPicker
                colorValue={editorState.backgroundColor}
                icon={<AiOutlineFontColors style={{ backgroundColor: "#fff", color: "#000000", fontSize: "21px" }} />}
                handleOnChange={(color) => updateStyle('backgroundColor', color.hex)}
                popUpStyle={{ top: "2.4rem", left: "-110px" }}
            />}

            <ColorPicker
                colorValue={editorState.background}
                icon={<BiSolidColorFill style={{ color: "#000000", fontSize: "21px" }} />}
                handleOnChange={(color) => updateStyle('background', color.hex)}
                popUpStyle={{ top: "2.4rem", left: "-110px" }}
            />


            {(styleElement.toolBarRef == "text" || styleElement.toolBarRef == "button" || styleElement.toolBarRef == "image" || styleElement.toolBarRef === 'spacer') && <CustomDropdown
                type="border"
                dropDownStyle={{ width: "auto" }}
                options={borders}
                onSelect={handleSelection}
                icon={<AiOutlineBorder style={{ color: "#000000", fontSize: "21px" }} />}
            />}

            {hasBorder && <CustomDropdown
                type="borderSize"
                dropDownStyle={{ width: "auto" }}
                options={borderSize}
                onSelect={handleSelection}
            />}

            {hasBorder && <CustomDropdown
                type="borderTypes"
                dropDownStyle={{ width: "auto" }}
                options={borderTypes}
                onSelect={handleSelection}
            />}


            {hasBorder && <ColorPicker
                colorValue={editorState?.borderColor}
                icon={<MdOutlineBorderColor style={{ color: "#000000", fontSize: "21px" }} />}
                handleOnChange={(color) => updateStyle('borderColor', color.hex)}
                popUpStyle={{ top: "2.4rem", left: "-110px" }}
            />}

        </div>
    );
};
