import { v4 as uuidv4 } from "uuid";

const textIcon = "/assets/images/email-template-icons/text.png";
const heading1 = "/assets/images/email-template-icons/heading1.png";
const heading2 = "/assets/images/email-template-icons/heading2.png";

const image1 = "/assets/images/email-template-icons/image.png";
const image2 = "/assets/images/email-template-icons/image2.png";
const image3 = "/assets/images/email-template-icons/image3.png";

const spacerIcon = "/assets/images/email-template-icons/spacer.png";
const spacer1 = "/assets/images/email-template-icons/10pxspacer.png";
const spacer2 = "/assets/images/email-template-icons/15pxspacer.png";
const spacer3 = "/assets/images/email-template-icons/20pxspacer.png";

const image_text = "/assets/images/email-template-icons/image+text.png";
const text_image = "/assets/images/email-template-icons/text+image.png";
const text_image_text = "/assets/images/email-template-icons/text+image+text.png";
const image_text_image = "/assets/images/email-template-icons/image+text+image.png";

const rounded_button = "/assets/images/email-template-icons/rounded+button.png";
const normal_button = "/assets/images/email-template-icons/normal+button.png";
const oval_button = "/assets/images/email-template-icons/oval+button.png";

const columns = "/assets/images/email-template-icons/columns.png";
const two_columns = "/assets/images/email-template-icons/2+text+columns.png";
const three_columns = "/assets/images/email-template-icons/3+text+columns.png";

const table = "/assets/images/email-template-icons/table.png";
const subformstable = "/assets/images/email-template-icons/subforms.png";

const outercolor = "/assets/images/email-template-icons/outer+color.png";
const innercolor = "/assets/images/email-template-icons/inner+colour.png";
const outertheme = "/assets/images/email-template-icons/outer+theme.png";

const placeholderImage = "https://dl.dropboxusercontent.com/scl/fi/p6ce77ewa6hajqthmo22p/image-placeholder.png?rlkey=d1ln9ks8yp0cp3b8846tvngzd&dl=0"


let componentData = [

    {
        label: "HEADING1",
        type: "heading1",
        content: "Type your text here...",
        imgData: { img: heading1, height: "30px", width: "30px" },
        style: { fontSize: '32px', color: '#000', margin: "0px", padding: '0rem', width: "100%", border: "none", outline: "none", lineHeight: "normal" },
        containerStyle: { padding: '0.3rem' },
        toolBarRef: 'text'
    },
    {
        label: "HEADING2",
        type: "heading2",
        content: "Type your text here...",
        imgData: { img: heading2, height: "30px", width: "30px" },
        style: { fontSize: '24px', color: '#000', margin: "0px", padding: '0.3rem', width: "100%", border: "none", outline: "none", lineHeight: "normal" },
        containerStyle: { padding: '0.3rem' },
        toolBarRef: 'text'
    },
    {
        label: "TEXT",
        type: "paragraph",
        imgData: { img: textIcon, height: "30px", width: "30px" },
        content: "Type your text here...",
        style: { fontSize: '14px', color: '#000', margin: "0px", padding: '0.3rem', width: "100%", border: "none", outline: "none", lineHeight: "normal" },
        containerStyle: { padding: '0.3rem' },
        toolBarRef: 'text'
    },
    {
        label: "IMAGE1",
        type: "1image",
        imgData: { img: image1, height: "28px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                src: placeholderImage,
                type_ref: "1image",
                type: "image",
                alt: "",
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "1rem", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "IMAGE2",
        type: "1+1image",
        imgData: { img: image2, height: "28px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                src: placeholderImage,
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                src: placeholderImage,
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "IMAGE3",
        type: "2+1image",
        imgData: { img: image3, height: "28px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                src: placeholderImage,
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                src: placeholderImage,
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                src: placeholderImage,
                style: { width: "100%", height: "100%", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "10PX SPACER",
        type: "10pxspace",
        imgData: { img: spacer1, height: "40px", width: "fit-content" },
        content: '',
        style: { height: "10px", width: "100%" },
        containerStyle: {},
        toolBarRef: 'spacer'
    },
    {
        label: "15PX SPACER",
        type: "15pxspace",
        imgData: { img: spacer2, height: "40px", width: "fit-content" },
        content: '',
        style: { height: "15px", width: "100%" },
        containerStyle: {},
        toolBarRef: 'spacer'
    },
    {
        label: "20PX SPACER",
        type: "20pxspace",
        imgData: { img: spacer3, height: "40px", width: "fit-content" },
        content: '',
        style: { height: "20px", width: "100%" },
        containerStyle: {},
        toolBarRef: 'spacer'
    },
    {
        label: "IMAGE+TEXT",
        type: "image+text",
        imgData: { img: image_text, height: "27px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type: "image",
                type_ref: "image+text",
                src: placeholderImage,
                alt: '',
                style: { width: "100%", height: "auto", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                type: "text",
                type_ref: "image+text",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.7rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "IMAGE+TEXT",
        type: "text+image",
        imgData: { img: text_image, height: "27px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type: "text",
                type_ref: "image+text",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.7rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type: "image",
                type_ref: "image+text",
                src: placeholderImage,
                alt: '',
                style: { width: "100%", height: "auto", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "IMAGE+TEXT",
        type: "text+image+text",
        imgData: { img: text_image_text, height: "27px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type: "text",
                type_ref: "image+text",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.7rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type: "image",
                type_ref: "image+text",
                src: placeholderImage,
                alt: '',
                style: { width: "100%", height: "auto", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                type: "text",
                type_ref: "image+text",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.7rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "IMAGE+TEXT",
        type: "image+text+image",
        imgData: { img: image_text_image, height: "27px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type: "image",
                type_ref: "image+text",
                src: placeholderImage,
                alt: '',
                style: { width: "100%", height: "auto", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            },
            {
                id: uuidv4(),
                type: "text",
                type_ref: "image+text",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.7rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type: "image",
                type_ref: "image+text",
                src: placeholderImage,
                alt: '',
                style: { width: "100%", height: "auto", borderRadius: "0.5px", padding: "0.7rem", display: "block", maxWidth: "-webkit-fill-available" },
                toolBarRef: 'image'
            }
        ],
        containerStyle: {},
        toolBarRef: 'image'
    },
    {
        label: "NORMAL",
        type: "normalbutton",
        imgData: { img: normal_button, height: "40px", width: "fit-content" },
        content: 'Sample normal button',
        href: "#",
        style: { backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', cursor: "pointer", borderRadius: '0px', margin: "5px", textAlign: "center", textDecoration: "none", display: "inline-block" },
        containerStyle: {},
        toolBarRef: 'button'
    },
    {
        label: "ROUNDED",
        type: "roundedbutton",
        imgData: { img: rounded_button, height: "40px", width: "fit-content" },
        content: 'Sample rounded button',
        href: "#",
        style: { backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', cursor: "pointer", borderRadius: '5px', margin: "5px", textAlign: "center", textDecoration: "none", display: "inline-block" },
        containerStyle: {},
        toolBarRef: 'button'
    },
    {
        label: "OVAL",
        type: "ovalbutton",
        imgData: { img: oval_button, height: "40px", width: "fit-content" },
        content: 'Sample oval button',
        href: "#",
        style: { backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', cursor: "pointer", borderRadius: '20px', margin: "5px", textAlign: "center", textDecoration: "none", display: "inline-block" },
        containerStyle: {},
        toolBarRef: 'button'
    },
    {
        label: "2 COLUMNS",
        type: "2columns",
        numOfCol: 2,
        imgData: { img: two_columns, height: "30px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type_ref: "2columns",
                type: "column",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.5rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type_ref: "2columns",
                type: "column",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.5rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            }
        ],
        containerStyle: {},
        toolBarRef: 'column'
    },
    {
        label: "3 COLUMNS",
        type: "3columns",
        numOfCol: 3,
        imgData: { img: three_columns, height: "30px", width: "fit-content" },
        content: [
            {
                id: uuidv4(),
                type_ref: "3columns",
                type: "column",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.5rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type_ref: "3columns",
                type: "column",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.5rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            },
            {
                id: uuidv4(),
                type_ref: "3columns",
                type: "column",
                content: "Type your text here...",
                style: { fontSize: '16px', color: '#000', margin: "0px", padding: '0.5rem', width: "100%", border: "none", outline: "none", maxWidth: "fit-content", height: "fit-content" },
                toolBarRef: 'text'
            }
        ],
        containerStyle: {},
        toolBarRef: 'column'
    },
    {
        label: "BASIC",
        type: "basictable",
        imgData: { img: table, height: "30px", width: "fit-content" },
        tableData: {
            headers: ["", "", ""],
            data: [
                ["", "", ""],
                ["", "", ""],
                ["", "", ""],
            ]
        },
        style: { width: '100%', borderCollapse: 'collapse' },
        containerStyle: {},
        toolBarRef: 'table'
    },
    {
        label: "INNER COLOR",
        type: "innercolor",
        imgData: { img: innercolor, height: "30px", width: "fit-content%" },
        content: "",
        style: {
            width: "100%",
            maxWidth: "600px",
            backgroundColor: "#FFFFFF",
            minHeight: "80vh"
        },
        containerStyle: {},
        toolBarRef: ''
    },
    {
        label: "OUTER COLOR",
        type: "outercolor",
        imgData: { img: outercolor, height: "30px", width: "fit-content" },
        content: "",
        style: {
            padding: "3.5rem 5rem",
            minHeight: "80vh",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            backgroundColor: "#F2F2F2"
        },
        containerStyle: {},
        toolBarRef: ''
    },
    // {
    //     label: "OUTER THEME",
    //     type: "outertheme",
    //     img: outertheme,
    //     content: "",
    //     style: { backgroundColor: "#F0F0F0" }
    // }
];

export { componentData };
