import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import AddForm from './addsubscriptions';
// import EditForm from './EditForm';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Addperiod from "./addperiod";
import AddPromoCodes from "./addPromoCodes";
import Adddescriptive from "./adddescriptive";
import AddFeatureTitle from "./AddFeatureTitle";
import { DisappearedLoading, CommonLoading } from "react-loadingg";
import { Link } from "react-router-dom";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import ClipLoader from "react-spinners/ClipLoader";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Popover, PopoverBody } from "reactstrap";
import { toast } from 'react-toastify';
import EditDescriptiveModal from "./editDescriptive";
import EditPromoCodes from "./editPromoCodes";
import SubscriptionCategoryTable from "./subscriptionCategories/SubscriptionCategoryTable";
import AddSubsCategory from "./subscriptionCategories/AddSubsCategory";
import FeatureHeadingTable from "./featureHeadings/FeatureHeadingTable";
import Subscription from "./Subscription";
import AddSubsCategory2 from "./subscriptionCategories/AddSubsCategory2";

const Countries = () => {

    const history = useHistory();

    let [loading, setloading] = useState(true);
    let [loadingItem, setloadingItem] = useState(null);
    let [loadingItemp, setloadingItemp] = useState(null);
    let [periodFormVisible, setPeriodFormVisible] = useState(false);
    let [promoCodesVisible, setPromoCodesVisible] = useState(false);
    let [descriptiveFormVisible, setDescriptiveFormVisible] = useState(false);
    let [result, setResult] = useState([]);
    let [periods, setperiods] = useState([]);
    let [promoCodesData, setPromoCodesData] = useState([]);
    let [features, setfeatures] = useState([]);
    let [forceReload, setForceReload] = useState(1);

    // console.log(features, "features");
    const [loadingupdate, setloadingupdate] = useState(false)

    const [editFeatureDesc, setEditFeatureDesc] = useState(null);
    const [editPromoData, setEditPromoData] = useState(null);
    const [deleteDFId, setdeleteDFId] = useState(null);
    const [deleteSubId, setdeleteSubId] = useState(null);
    const [deletePerId, setdeletePerId] = useState(null);
    const [promoDataId, setPromoDataId] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [editPromoModal, setEditPromoModal] = useState(false);
    const [deleteDFModal, setDeleteDFModal] = useState(false); //DF - descriptive Feature
    const [deleteSubModal, setDeleteSubModal] = useState(false); // - Subscription Feature
    const [deletePerModal, setDeletePerModal] = useState(false); // - Period Feature
    const [deletePromoModal, setDeletePromoModal] = useState(false); // - promoCode delete modal
    const [featureDescArr, setFeatureDescArr] = useState([
        {
            cloud_based_desc: "",
            loyalty_card_desc: "",
            offers_vouchers_desc: "",
            online_order_desc: "",
            payment_link_desc: "",
            register_desc: "",
            table_reservation_desc: "",
            users_roles_desc: "",
            visitor_registry_desc: "",
            linky_scan_desc: "",
            price_list_desc: "",
        }
    ])
    const [popoverState, setPopoverState] = useState({
        // cloud_based: false,
        // loyalty_card: false,
        // offers_vouchers: false,
        // online_order: false,
        // payment_link: false,
        // register: false,
        // table_reservation: false,
        // users_roles: false,
        // visitor_registry: false,
        // linky_scan: false,
        // price_list: false,

        sales_per_day: false,
        number_of_registers: false,
        number_of_users: false,
        guest_visitor_registry: false,
        linky_scan_id: false,
        ecommerce_website: false,
        bookings_reservations: false,
        offers_vouchers: false,
        loyalty_cards: false,
        payment_links: false,
        price_list: false,
        remove_logo: false
    })

    const popoverToggle = (id) => {
        if (id == 1) {
            setPopoverState(prev => {
                return {
                    cloud_based: !prev.cloud_based,
                }
            })
        }
        if (id == 2) {
            setPopoverState(prev => {
                return {
                    register: !prev.register,
                }
            })
        }
        if (id == 3) {
            setPopoverState(prev => {
                return {
                    users_roles: !prev.users_roles,
                }
            })
        }
        if (id == 4) {
            setPopoverState(prev => {
                return {
                    visitor_registry: !prev.visitor_registry,
                }
            })
        }
        if (id == 5) {
            setPopoverState(prev => {
                return {
                    linky_scan: !prev.linky_scan,
                }
            })
        }
        if (id == 6) {
            setPopoverState(prev => {
                return {
                    online_order: !prev.online_order,
                }
            })
        }
        if (id == 7) {
            setPopoverState(prev => {
                return {
                    table_reservation: !prev.table_reservation,
                }
            })
        }
        if (id == 8) {
            setPopoverState(prev => {
                return {
                    offers_vouchers: !prev.offers_vouchers,
                }
            })
        }
        if (id == 9) {
            setPopoverState(prev => {
                return {
                    loyalty_card: !prev.loyalty_card,
                }
            })
        }
        if (id == 10) {
            setPopoverState(prev => {
                return {
                    payment_link: !prev.payment_link,
                }
            })
        }
        if (id == 11) {
            setPopoverState(prev => {
                return {
                    price_list: !prev.price_list,
                }
            })
        }
    }

    const [popular, setPopular] = useState({});

    const [enableFeatureTitleModal, setEnableFeatureTitleModal] = useState(false);
    const toggleFeatureTitleModal = () => { setEnableFeatureTitleModal(!enableFeatureTitleModal) };


    const forceReloadFunc = () => {
        if (forceReload == 1) {
            setForceReload(0)
        } else {
            setForceReload(1)
        }
    }
    const fetchPromoCodes = () => {

        client.post(api.getAllPromoCodes, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setPromoCodesData(response.result.data)
                            // console.log('promoCodes ==>', response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }
    useEffect(() => {
        fetchPromoCodes();
    }, [])
    // console.log('promos',promoCodesData)


    useEffect(() => {

        client.get(api.fetch_subscription, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setResult(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            setloading(false);
        });

        fetchperiod();
        fetchfeatures();

        // console.log('Hello')

    }, [forceReload])


    useEffect(() => {

        const fetchFeatureDesc = () => {
            client.post(api.fetch_featureDesc, {}, async function (error, response) {
                // console.log(response, "res")
                if (!error) {
                    if (!response.error) {
                        setFeatureDescArr(response.result)
                    }
                }
            })
        }

        fetchFeatureDesc();

    }, [])

    // console.log(result)

    const mergeSubscription = () => {
            setloadingupdate(true)
    
            client.post(api.update_merchants_subscription, {}, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success("Updated Successfully!");
                               
                            } else {
                                toast.error("Internal error occured. Please contact support 1");
                                
                            }
                        } catch (e) {
                            // alert(e)
                            toast.error("Internal error occured. Please contact support 1");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 1");
                }
                togglemergeModal()
                setloadingupdate(false)
            })
    
        }

    const toggleDeleteModal = () => {
        setDeleteDFModal(!deleteDFModal)
    }
    const toggleSubDeleteModal = () => {
        setDeleteSubModal(!deleteSubModal)
    }
    const togglePerDeleteModal = () => {
        setDeletePerModal(!deletePerModal)
    }
    const togglePromoDeleteModal = () => {
        setDeletePromoModal(!deletePromoModal)
    }

    // console.log(featureDescArr, "fe")
    const handleSubDelete = () => {
        let postData = {
            subscription_id: deleteSubId
        }
        client.post(api.delete_subscription, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    toast.success("Deleted Successfully")
                    forceReloadFunc()
                    // setfeatures(response.result)
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")

            }
            setDeleteSubModal(false)
        })
    }
    const handlePerDelete = () => {
        let postData = {
            period_id: deletePerId
        }
        client.post(api.deleteperiod, postData, async function (error, response) {
            // console.log(postData, response, error, "pre")
            if (!error) {
                if (!response.error) {
                    toast.success("Deleted Successfully")
                    forceReloadFunc()
                    // setfeatures(response.result)
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")

            }
            setDeletePerModal(false)
        })
    }
    const handlePromoDataDelete = () => {
        let postData = {
            promoCode_Id: promoDataId
        }
        client.post(api.deletePromoData, postData, async function (error, response) {
            // console.log(postData, response, error, "PROMO DELETE")
            if (!error) {
                if (!response.error) {
                    toast.success("Deleted Successfully")
                    // forceReloadFunc()
                    fetchPromoCodes()
                    // setfeatures(response.result)
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")

            }
            setDeletePromoModal(false)
        })
    }

    const toggleStatusPeriod = (item, index) => {

        setloadingItemp(item._id);

        client.post(api.toggle_status_period, { period_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = periods;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setperiods(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItemp(null)
        });
    }

    const toggleStatusFeatures = (item, index) => {

        setloadingItemp(item._id);

        client.post(api.toggle_status_features, { features_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = features;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setfeatures(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        //alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItemp(null)
        });
    }

    const toggleStatus = (item, index) => {

        setloadingItem(item._id);

        client.post(api.toggle_status, { subscription_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = result;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setResult(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItem(null)
        });
    }

    const fetchperiod = () => {

        client.get(api.fetchperiod, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setperiods(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const handleDFDelete = () => {
        let postData = {
            feature_id: deleteDFId
        }
        client.post(api.delete_features, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    toast.success("Deleted Successfully")
                    setfeatures(response.result)
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")

            }
            setDeleteDFModal(false)
        })
    }

    const fetchfeatures = () => {

        client.get(api.fetch_features, { showall: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setfeatures(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const saveFeaturesDesc = (obj, desc) => {

        let postData = { ...featureDescArr[0] }
        client.post(api.save_featureDesc, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    // console.log(response, "Res")
                    toast.success(response.message)
                    setPopoverState({
                        // cloud_based: false,
                        // loyalty_card: false,
                        // offers_vouchers: false,
                        // online_order: false,
                        // payment_link: false,
                        // register: false,
                        // table_reservation: false,
                        // users_roles: false,
                        // visitor_registry: false,
                        // linky_scan: false,
                        // price_list: false,

                    })

                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")
            }
        })
        let desc_data = {
            feature_id: obj?._id,
            title: obj?.title,
            description: desc,
            status: obj?.status
        }
        // console.log(desc_data)
        // updating to features_descriptions
        client.post(api.edit_features, desc_data, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // toast.success("Updated Successfully");
                            // setcashbackdata(response.result.data);
                            fetchfeatures()
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    const updateSortBy = async (item, sortNo) => {
        const sort_id = item._id
        let data = { sort_id: sort_id, sort_number: sortNo }
        // console.log(data, 'post Data');
        await client.post(api.sort_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("updated Successfully");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }


        });
    }

    const updatePopular = async (item, e) => {
        const popular_id = item._id
        let data = { popular_id: popular_id, state: e.checked }
        // console.log(data, "Data")
        await client.post(api.popular_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("updated Successfully");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }


        });

    }

    const renderDeleteModal = () => {

        return (
            <Modal isOpen={deleteDFModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Descriptive Feature</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Descriptive Feature?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleDeleteModal}>Not sure!</Button>
                        <Button color="primary" onClick={handleDFDelete}  >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>
        );
    }
    const renderSubscriptionDeleteModal = () => {

        return (
            <Modal isOpen={deleteSubModal} toggle={toggleSubDeleteModal} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Subscription Plan</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Subscription Plan?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleSubDeleteModal}>Not sure!</Button>
                        <Button color="primary" onClick={handleSubDelete}  >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>
        );
    }
    const renderPeriodDeleteModal = () => {

        return (
            <Modal isOpen={deletePerModal} toggle={togglePerDeleteModal} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Subscription Period</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Subscription Period?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={togglePerDeleteModal}>Not sure!</Button>
                        <Button color="primary" onClick={handlePerDelete}  >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>
        );
    }
    const renderPromoDeleteModal = () => {

        return (
            <Modal isOpen={deletePromoModal} toggle={togglePromoDeleteModal} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete PromoCode Data</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Promo Code Data?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={togglePromoDeleteModal}>Not sure!</Button>
                        <Button color="primary" onClick={handlePromoDataDelete}  >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>
        );
    }


    const [defaultVaue, setDefaultValue] = useState(null);
    const [catLoading, setCatLoading] = useState(false);
    const [subCatResult, setSubCatResult] = useState([]);
    const [loadingItemp2, setloadingItemp2] = useState(null);
    const [categoryItem, setCategoryItem] = useState(null);
    const [enableAddCatModal, setEnableAddCatModal] = useState(false);
    const [selectCatModal, setSelectCatModal] = useState(false);

    const [featureTitles, setFeatureTitles] = useState({})
    const [defaultTitleNames, setDefaultTitleNames] = useState(null);
 const [mergeModal, setmergeModal] = useState(false)
    const [featureTitleLoading, setFeatureTitleLoading] = useState(false);
    const [subsCategory2, setSubsCategory2] = useState(false);

     const togglemergeModal = () => {
            setmergeModal(!mergeModal)
        }

    const fetchFeaturesTitles = () => {
        setFeatureTitleLoading(true);
        client.get(api.fetch_features_titles, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setFeatureTitles(response.result)
                            setTimeout(() => {
                                setFeatureTitleLoading(false);
                            }, 2000);
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                    setTimeout(() => {
                        setFeatureTitleLoading(false);
                    }, 2000);
                }
            } else {
                setTimeout(() => {
                    setFeatureTitleLoading(false);
                }, 2000);
            }
        })
    }
    useEffect(() => {
        fetchFeaturesTitles()
    }, [])

    const settingDefaultValues = () => {
        let temp;
        let getData = {};

        if (featureTitles.length > 0) {
            featureTitles.map((currData) => {
                getData[`${currData._id}_${currData.title_id}`] = currData.custom_title;
                features.map(ele => {
                    let checkboxState = ele.title_id ? ele.title_id == currData.title_id ? true : false : false
                    getData = { ...getData, [`${currData.title_id}_${ele._id}`]: checkboxState }
                })
            })
        }

        // let keys = Object.keys(featureTitles)
        // let featureCount = [
        //     featureTitles?.minisite?.title_id,
        //     featureTitles?.payments?.title_id,
        //     featureTitles?.event_management?.title_id,
        //     featureTitles?.pos?.title_id,
        //     featureTitles?.ecommerce?.title_id,
        //     featureTitles?.others?.title_id
        // ]

        // if (keys.length !== 0) {
        //     temp = {
        //         [featureTitles?.minisite?.title_id]: featureTitles.minisite?.custom_title,
        //         [featureTitles?.payments?.title_id]: featureTitles.payments?.custom_title,
        //         [featureTitles?.event_management?.title_id]: featureTitles.event_management?.custom_title,
        //         [featureTitles?.pos?.title_id]: featureTitles.pos?.custom_title,
        //         [featureTitles?.ecommerce?.title_id]: featureTitles.ecommerce?.custom_title,
        //         [featureTitles?.others?.title_id]: featureTitles.others?.custom_title
        //     }
        // }
        // featureCount.map(val => {
        //     features.map(ele => {
        //         // temp[`${val}`]
        //         let checkboxState = ele.title_id ? ele.title_id == val ? true : false : false
        //         // console.log(`${val}_${ele._id} = ${checkboxState}`)
        //         temp = { ...temp, [`${val}_${ele._id}`]: checkboxState }
        //     })
        // })
        setDefaultTitleNames(getData)
    }

    useEffect(() => {
        settingDefaultValues()
    }, [featureTitles, features])

    const updateFeatureTitle = (postData, type) => {
        if (type == 1) {
            client.put(api.edit_feature_titles, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success('Updated Successfully')
                                fetchFeaturesTitles()
                            } else {
                                toast.error("Internal error occured. Please contact support 1", { autoClose: 2000, hideProgressBar: true });
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support 2", { autoClose: 2000, hideProgressBar: true });
                        }
                    } else {
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3", { autoClose: 2000, hideProgressBar: true });
                }
            })
        }
        if (type == 2) {
            client.put(api.edit_features_state, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success('Updated Successfully')
                                fetchfeatures()
                                settingDefaultValues()
                            } else {
                                toast.error("Internal error occured. Please contact support 1", { autoClose: 2000, hideProgressBar: true });
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support 2", { autoClose: 2000, hideProgressBar: true });
                        }
                    } else {
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3", { autoClose: 2000, hideProgressBar: true });
                }
            })
            // console.log('state edit', postData)
        }
    }

    const toggleAddCatModal = () => { setEnableAddCatModal(!enableAddCatModal)
        if(selectCatModal)
        {
            setSelectCatModal(false)
        }
        if(subsCategory2)
        {
            setSubsCategory2(false)
        }

     };

    const fetchSubscriptionCategory = () => {

        setCatLoading(true)

        client.post(api.fetchSubscriptionCategory, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setSubCatResult(response.result)
                        } else {
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
                    }
                } else {
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }

            setCatLoading(false);
        });

    }

    useEffect(() => {

        fetchSubscriptionCategory();

    }, [])


    useEffect(() => {

        if (subCatResult.length > 0) {

            let getData = {};

            subCatResult.map((val, index) => {

                getData[`${val._id}_subCatTitle`] = val.subCatTitle;

                if (result.length > 0) {

                    result.map((currElem) => {

                        let enableBox = currElem.subscription_category.find((getRef) => getRef.id == val.cat_Id);
                        if (enableBox) {
                            getData[`${val.cat_Id}_${currElem._id}_subscriptionName`] = enableBox.enable;
                        }
                    })
                }
            });

            setDefaultValue(getData);
        };


    }, [subCatResult, result]);

    // console.log(subCatResult)

    const update_subscriprion_cat = (postData) => {
        if (postData.updateType == 4) {
            setloadingItemp2(postData._id)
        }
        console.log(postData)

        client.put(api.updateSubscriptionCategory, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                            setSubCatResult(response.result)
                            setCatLoading(false);
                            setloadingItemp2(null);
                        } else {
                            toast.error("Internal error occured. Please contact support 1", { autoClose: 2000, hideProgressBar: true });
                            setCatLoading(false);
                            setloadingItemp2(null);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 2", { autoClose: 2000, hideProgressBar: true });
                    }
                } else {
                }
            } else {
                toast.error("Internal error occured. Please contact support 3", { autoClose: 2000, hideProgressBar: true });
                setCatLoading(false);
                setloadingItemp2(null);
            }
        });

    }

    const deleteSubscriptionCategory = () => {

        setCatLoading(true)

        client.put(api.deleteAllSubscriptionCategory, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setSubCatResult(response.result)
                        } else {
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
                    }
                } else {
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }

            setCatLoading(false);
        });

    }

    const titleCheck = (val) => {
        if (val === 'minisite') {
            return 'Minisite'
        }
        if (val === 'payments') {
            return 'Payments'
        }
        if (val === 'event_management') {
            return 'Event Management'
        }
        if (val === 'payments') {
            return 'Payments'
        }
        if (val === 'pos') {
            return 'POS'
        }
        if (val === 'ecommerce') {
            return 'E-commerce & Marketing tools'
        }
        if (val === 'others') {
            return 'Others'
        }
    }
    const [staticFeatures, setStaticFeatures] = useState({});

    // console.log(features, "features");

    // console.log(staticFeatures, "staticFeatures");

    useEffect(() => {
        let temp = {};
        let filtered = features.filter(item => item.descriptive_feature == false)
        let order = [
            'sales_per_day',
            'number_of_registers',
            'number_of_users',
            'guest_visitor_registry',
            'linky_scan_id',
            'ecommerce_website',
            'bookings_reservations',
            'function_booking',
            'offers_vouchers',
            'loyalty_cards',
            'payment_links',
            'price_list',
            'remove_logo',
            'tips_reviews',
            'call_waiter',
            'clean_table',
        ]

        filtered.sort((a, b) => a.sort_index - b.sort_index)
        // {
        //     const aIndex = order.indexOf(a.feature_id)
        //     const bIndex = order.indexOf(b.feature_id)
        //     if (aIndex !== -1 && bIndex !== -1) {
        //         return aIndex - bIndex;
        //     }
        //     if (aIndex !== -1) {
        //         return -1;
        //     }
        //     if (bIndex !== -1) {
        //         return 1;
        //     }
        //     return 0;
        // })
        // console.log(filtered)
        // filtered && filtered.map(item => {
        //     let data = {
        //         id: item._id,
        //         title: item.title,
        //         description: item.description,
        //         status: item.status
        //     }
        //     temp[item.title] = data;
        // })
        // console.log(temp)
        // setStaticFeatures(temp)
        // console.log(filtered, "filtered")
        setStaticFeatures(filtered)
    }, [features])


    const deleteFeatureTitle = (data) => {

        let postData = { _id: data._id };

        client.put(api.delete_feature_titles, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success('Deleted Successfully')
                            fetchFeaturesTitles()
                        } else {
                            toast.error("Internal error occured. Please contact support 1", { autoClose: 2000, hideProgressBar: true });
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 2", { autoClose: 2000, hideProgressBar: true });
                    }
                } else {
                }
            } else {
                toast.error("Internal error occured. Please contact support 3", { autoClose: 2000, hideProgressBar: true });
            }
        })
    }

    return (
        <>
            {deleteDFModal && renderDeleteModal()}
            {deleteSubModal && renderSubscriptionDeleteModal()}
            {deletePerModal && renderPeriodDeleteModal()}
            {deletePromoModal && renderPromoDeleteModal()}


            <div className="row ">
                <div className=" subscriptions_home_title" style={{ fontSize: "12px" }}>
                    <Breadcrumb title="Subscriptions" parent="Home" />
                </div>
                <div className=" subscriptions_tabs_section d-flex  justify-content-end flex-grow-1 p-0">
                    <div className="btn-group" style={{ paddingBlock: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
                        {true && <button className="btnCashback custBtnAdmin" onClick={() => {toggleAddCatModal()
                        setSelectCatModal(true)
                        setCategoryItem(null)
                        }

                        } ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Category</button>}
                        {true && <button className="btnCashback custBtnAdmin" onClick={() => setPeriodFormVisible(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Periods</button>}

                        {true && <button className="btnTopup custBtnAdmin" onClick={() => {
                            history.push(`${process.env.PUBLIC_URL}/subscriptions/add`);
                        }}>
                            <i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Subscriptions</button>}

                        {true && <button className="btnCashback custBtnAdmin" onClick={() => setDescriptiveFormVisible(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Descriptive Feature</button>}
                        {true && <button className="btnCashback custBtnAdmin" onClick={() => setPromoCodesVisible(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Create Promo code</button>}

                        {/* <Button
                    className="pull-right w-auto"
                    color="primary"
                    style={{fontSize:'12px'}}
                    onClick={(e) => togglemergeModal()}
                >Update Subscription Plans</Button> */}

                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="container-fluid">
                        <div className="row theme-tab">
                            <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} >
                                <div className="d-flex justify-content-between align-items-center">
                                
                                <TabList className="tabs tab-title">
                                    <Tab style={{ display: "flex", alignItems: "center" }}>
                                        Subscriptions
                                    </Tab>

                                    <Tab style={{ display: "flex", alignItems: "center" }}>
                                        Feature Title/Heading
                                    </Tab>

                                    <Tab
                                        style={{ display: "flex", alignItems: "center" }}
                                        onClick={() => { fetchSubscriptionCategory() }}
                                    >
                                        Category
                                    </Tab>

                                    <Tab style={{ display: "flex", alignItems: "center" }}>
                                        Periods
                                    </Tab>

                                    <Tab style={{ display: "flex", alignItems: "center" }}>
                                        Descriptive Features
                                    </Tab>
                                    <Tab style={{ display: "flex", alignItems: "center" }}>
                                        Promo Codes
                                    </Tab>
                                    
                                </TabList>
                                <Button
                    className="pull-right w-auto mr-3"
                    color="primary"
                    onClick={(e) => togglemergeModal()}
                    style={{fontSize:'14px'}}
                >Update Subscription Plans</Button>
                                </div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 xl-100">
                                            <div className="card">
                                                <div className="card-body" style={{ padding: " 0.5rem 1rem" }}>
                                                    <div className="tab-content-cls">
                                                        <TabPanel>
                                                            {!loading ?
                                                                <Subscription
                                                                    staticFeatures={staticFeatures}
                                                                    fetchfeatures={fetchfeatures}
                                                                    subCatResult={subCatResult}
                                                                    update_subscriprion_cat={update_subscriprion_cat}
                                                                    toggleAddCatModal={toggleAddCatModal}
                                                                    setSelectCatModal={setSelectCatModal}
                                                                    setCategoryItem={setCategoryItem}

                                                                />
                                                                :
                                                                <div className="loader-box" style={{ height: "100px" }}>
                                                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                                        <DisappearedLoading size="medium" color="#514F4E" />
                                                                    </div>
                                                                </div>
                                                            }

                                                        </TabPanel>

                                                        {/* feature heading/title */}
                                                        <TabPanel>
                                                            <div className="container-fluid main_class">
                                                                <div className="row">
                                                                    <div className="col-10 ml-auto d-flex align-items-center justify-content-between my-3">
                                                                        <p className="mb-0" style={{ fontSize: "16px", fontWeight: "600" }}>Feature title/heading</p>
                                                                        <div>
                                                                            <button className="btnCashback custBtnAdmin" onClick={() => { toggleFeatureTitleModal() }} >
                                                                                <i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>
                                                                                Add Feature Title
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    featureTitleLoading ?
                                                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "300px", width: "100%" }}>
                                                                            <div className="loader">
                                                                                <DisappearedLoading size="medium" color="#514F4E" />
                                                                                <div style={{ fontSize: "15px" }}>Loading please wait...</div>
                                                                            </div>
                                                                        </div> :
                                                                        <FeatureHeadingTable 
                                                                            features={features}
                                                                            featureTitles={featureTitles}
                                                                            defaultTitleNames={defaultTitleNames}
                                                                            result={result}
                                                                            updateFeatureTitle={updateFeatureTitle}
                                                                            deleteFeatureTitle={deleteFeatureTitle}
                                                                            featureTitleLoading={featureTitleLoading}
                                                                        />
                                                                }
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="container-fluid main_class">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex align-items-center justify-content-between my-3">
                                                                        <p className="mb-0" style={{ fontSize: "16px", fontWeight: "600" }}>Category</p>
                                                                        <div>
                                                                            <button className="btnCashback custBtnAdmin" onClick={() => {toggleAddCatModal()
                                                                                setSubsCategory2(true)
                                                                            }
                                                                            } >
                                                                                <i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>
                                                                                ADD CATEGORY
                                                                            </button>
                                                                            {/* <button className="btnCashback custBtnAdmin" onClick={() => deleteSubscriptionCategory()} >
                                                                                <i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>
                                                                                Delete All
                                                                            </button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !catLoading ?
                                                                        <SubscriptionCategoryTable
                                                                            defaultVaue={defaultVaue}
                                                                            result={subCatResult}
                                                                            loading={catLoading}
                                                                            update_subscriprion_cat={update_subscriprion_cat}
                                                                            loadingItemp={loadingItemp2}
                                                                            subsResult={result}
                                                                        /> :
                                                                        <div className="loader-box d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
                                                                            <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                                                <DisappearedLoading size="medium" color="#327EBC" />
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            {!loading ?
                                                                <div className="table-responsive" >
                                                                    <table className="table table-border-horizontal" >
                                                                        <thead>
                                                                            <tr style={{ background: '#f9fafe' }}>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col" >Title</th>
                                                                                <th scope="col" >Period</th>
                                                                                <th scope="col" >Discount Percent (%)</th>
                                                                                <th scope="col" >Renewal Discount (%)</th>
                                                                                <th scope="col" ></th>
                                                                                <th scope="col" >Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {periods && periods.length > 0 ? periods.map((item, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>#</td>
                                                                                        <td>{item.title}</td>
                                                                                        <td>{item.period}</td>
                                                                                        <td>{item.discount_percent}%</td>
                                                                                        <td>{item.renewal_discount}%</td>
                                                                                        <td>
                                                                                            {loadingItemp && loadingItemp == item._id ?
                                                                                                <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                                                :
                                                                                                <Switch
                                                                                                    onChange={() => toggleStatusPeriod(item, index)}
                                                                                                    checked={item.status == 2 ? false : true}
                                                                                                    className="react-switch"
                                                                                                    height={20}
                                                                                                    width={40}
                                                                                                />

                                                                                            }

                                                                                        </td>
                                                                                        <td>
                                                                                            <span style={{ marginLeft: "10px", fontSize: "0.75rem", color: "red" }} onClick={() => {
                                                                                                setdeletePerId(item._id);
                                                                                                setDeletePerModal(true)
                                                                                            }}>
                                                                                                <i className="fa fa-trash"></i>
                                                                                            </span>
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                                : null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="loader-box" style={{ height: "100px" }}>
                                                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                                        <DisappearedLoading size="medium" color="#514F4E" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </TabPanel>

                                                        <TabPanel>
                                                            {!loading ?
                                                                <div className="table-responsive" >
                                                                    <table className="table table-border-horizontal">
                                                                        <thead>
                                                                            <tr style={{ background: '#f9fafe' }}>
                                                                                <th scope="col" className="feaCell">#</th>
                                                                                <th scope="col" className="feaCell">Title</th>
                                                                                <th scope="col" className="feaCell">Description</th>
                                                                                <th scope="col" className="feaCell">Status</th>
                                                                                <th scope="col" className="feaCell">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {features && features.length > 0 ? features.map((item, index) => {
                                                                                if (item.descriptive_feature)
                                                                                    return (
                                                                                        <tr>
                                                                                            <td className="feaCell">#</td>
                                                                                            <td className="feaCell">{item.title}</td>
                                                                                            <td className="feaCell">{item.description}</td>
                                                                                            <td className="feaCell">
                                                                                                {loadingItemp && loadingItemp == item._id ?
                                                                                                    <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                                                    :
                                                                                                    <Switch
                                                                                                        onChange={() => toggleStatusFeatures(item, index)}
                                                                                                        checked={item.status == 2 ? false : true}
                                                                                                        className="react-switch"
                                                                                                        height={20}
                                                                                                        width={40}
                                                                                                    />

                                                                                                }
                                                                                            </td>
                                                                                            <td className="feaCell">
                                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                                    <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                                                                        setEditFeatureDesc(item);
                                                                                                        setEditModal(true)
                                                                                                    }} >
                                                                                                        <i className="fa fa-edit"></i>
                                                                                                    </span>
                                                                                                    <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "red" }} onClick={() => {
                                                                                                        setdeleteDFId(item._id)
                                                                                                        setDeleteDFModal(true)
                                                                                                    }} >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                    )
                                                                            })
                                                                                : null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="loader-box" style={{ height: "100px" }}>
                                                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                                        <DisappearedLoading size="medium" color="#514F4E" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </TabPanel>

                                                        <TabPanel>
                                                            {!loading ?
                                                                <div className="table-responsive" >
                                                                    <table className="table table-border-horizontal" >
                                                                        <thead>
                                                                            <tr style={{ background: '#f9fafe' }}>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col" >Promo code</th>
                                                                                <th scope="col" >Discount type</th>
                                                                                <th scope="col" >Amount/Percentage</th>
                                                                                <th scope="col" >Valid from</th>
                                                                                <th scope="col" >Valid till</th>
                                                                                <th scope="col" >Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {promoCodesData && promoCodesData.length > 0 ? promoCodesData.map((item, index) => {
                                                                                {/* console.clear() */ }
                                                                                // console.log(promoCodesData, 'PRO')
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>#</td>
                                                                                        <td>{item.promo_code}</td>
                                                                                        <td>{item.discount_type == 1 ? 'Percentage based' : 'Amount based'}</td>
                                                                                        {item.discount_type == 0 ?
                                                                                            <td>{item.amount}</td> :
                                                                                            <td>{item.percentage}%</td>}
                                                                                        <td>{item.valid_from}</td>
                                                                                        <td>{item.valid_till}
                                                                                        </td>
                                                                                        <td>
                                                                                            <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                                                                setEditPromoData(item);
                                                                                                // setEditModal(true)
                                                                                                setEditPromoModal(true)
                                                                                            }} >
                                                                                                <i className="fa fa-edit"></i>
                                                                                            </span>
                                                                                            <span style={{ marginLeft: "10px", fontSize: "0.75rem", color: "red" }} onClick={() => {
                                                                                                setPromoDataId(item._id);
                                                                                                setDeletePromoModal(true)
                                                                                            }}>
                                                                                                <i className="fa fa-trash"></i>
                                                                                            </span>
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            })
                                                                                : null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="loader-box" style={{ height: "100px" }}>
                                                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                                        <DisappearedLoading size="medium" color="#514F4E" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </TabPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tabs>


                            
                        </div>
                        
                    </div>
                </div>
            </div>

            <Addperiod isOpen={periodFormVisible} toggleModal={(reload = false) => {
                setPeriodFormVisible(!periodFormVisible)
                if (reload) {
                    fetchperiod();
                }
            }} />
            <AddPromoCodes isOpen={promoCodesVisible} promoCodesData={promoCodesData} toggleModal={(reload = false) => {
                setPromoCodesVisible(!promoCodesVisible)
                if (reload) {
                    fetchPromoCodes();
                }
            }} />
            <EditPromoCodes isOpen={editPromoModal} promoCodesData={promoCodesData} toggleModal={(reload = false) => {
                setEditPromoModal(!editPromoModal)
                if (reload) {
                    fetchPromoCodes();
                }
            }} editPromoData={editPromoData} setEditPromoData={setEditPromoData} />
            <Adddescriptive isOpen={descriptiveFormVisible} toggleModal={(reload = false) => {
                setDescriptiveFormVisible(!descriptiveFormVisible)
                if (reload) {
                    fetchfeatures();
                }
            }} />
            <EditDescriptiveModal isOpen={editModal} toggleModal={(reload = false) => {
                setEditModal(!editModal)
                if (reload) {
                    fetchfeatures();
                }
            }} editFeatureDesc={editFeatureDesc} setEditFeatureDesc={setEditFeatureDesc} />
            {
                subsCategory2?
                <AddSubsCategory2
                isOpen={enableAddCatModal}
                selectCatModal={selectCatModal}
                result={result}
                toggleModal={toggleAddCatModal}
                setSubCatResult={setSubCatResult}
                setCatLoading={setCatLoading}
                categoryItem={categoryItem}
                fetchSubscriptionCategory={fetchSubscriptionCategory}
                forceReloadFunc={forceReloadFunc}
                update_subscriprion_cat={update_subscriprion_cat}
                subCatResult={subCatResult}

            />
                :
                <AddSubsCategory
                isOpen={enableAddCatModal}
                selectCatModal={selectCatModal}
                result={result}
                toggleModal={toggleAddCatModal}
                setSubCatResult={setSubCatResult}
                setCatLoading={setCatLoading}
                categoryItem={categoryItem}
                fetchSubscriptionCategory={fetchSubscriptionCategory}
                forceReloadFunc={forceReloadFunc}
                update_subscriprion_cat={update_subscriprion_cat}
                subCatResult={subCatResult}

            />
            }
           

            <AddFeatureTitle
                isOpen={enableFeatureTitleModal}
                toggleModal={toggleFeatureTitleModal}
                fetchFeaturesTitles={fetchFeaturesTitles}
            />

<Modal isOpen={mergeModal} toggle={togglemergeModal} className="modal-body" centered={true}>
                <ModalBody>
                    {!loadingupdate ?
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <img style={{ width: 82 }} src="https://dl.dropboxusercontent.com/scl/fi/l3lio2vr9ryq76kgh8ngc/warning-icon-yellow.png?rlkey=kypnauptl3uw58tr28ossojl2&dl=0" alt="" />
                                <span style={{
                                    fontSize: "24px",
                                    fontWeight: 500
                                }}>Attention!</span>
                            </div>
                            <hr />
                            <h5 style={{
                                textAlign: "center",
                                fontWeight: "unset"
                            }}>Are you sure you want to update the merchant's subscription plan with the current subscription details? < br />The changes can't be reverted.</h5>
                            {/* <div>The changes can't be reverted.</div> */}
                            <Button
                                disabled={loadingupdate}
                                color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={togglemergeModal}>
                                Not Sure
                            </Button>
                            <Button
                                color="danger"
                                disabled={loadingupdate}
                                style={{ marginTop: "20px", marginRight: "20px", float: "right" }}
                                name="unflag"
                                onClick={(event) => mergeSubscription()}
                            >
                                Yes, please
                            </Button>
                        </>
                        :
                        <div className="w-100 h-100 text-center">
                            <BeatLoader color="#45C7A2" loading={true} size={15} />
                            <div style={{ textAlign: 'center' }}>Updating subscription, please wait.</div>
                        </div>
                    }
					
                </ModalBody>
            </Modal>
        </>
    );

}

export default Countries;


// <table className="table table-border-horizontal" >
//                                                                         <thead>
//                                                                             <tr style={{ background: '#f9fafe' }}>
//                                                                                 <th scope="col">#</th>
//                                                                                 <th scope="col" >Title of subscription</th>
//                                                                                 <th scope="col" >Sub Title</th>
//                                                                                 <th scope="col" >Subscription Fee</th>
//                                                                                 <th scope="col" >Promotional Details</th>
//                                                                                 <th scope="col" >Cloud-based POS</th>
//                                                                                 <th scope="col" >Number Of Registers</th>
//                                                                                 <th scope="col" >Number of Users</th>
//                                                                                 <th scope="col" >Online Ordering System</th>
//                                                                                 <th scope="col" >Table Reservation</th>
//                                                                                 <th scope="col" >Offers & Vouchers</th>
//                                                                                 <th scope="col" >Loyalty cards</th>
//                                                                                 <th scope="col" >Payment links</th>
//                                                                                 {/* <th scope="col" >Modifiers</th> */}
//                                                                                 <th scope="col" >Action</th>
//                                                                                 <th scope="col" ></th>
//                                                                             </tr>
//                                                                         </thead>
//                                                                         <tbody>
//                                                                             {result && result.length > 0 ? result.map((item, index) => {
//                                                                                 return (
//                                                                                     <tr>
//                                                                                         <td>#</td>
//                                                                                         <td>{item.title}
//                                                                                             {item.is_trial &&
//                                                                                                 <span style={{ marginLeft: 10, fontSize: 8, backgroundColor: "orange", padding: "1px 4px", border: "1px solid orange", borderRadius: 8, color: "#fff" }}>Trial</span>
//                                                                                             }
//                                                                                         </td>
//                                                                                         <td>{item.description}</td>
//                                                                                         <td>{item.fees}</td>
//                                                                                         <td>
//                                                                                             {item.promo_rate > 0 ?
//                                                                                                 <span>Fees : {item.promo_rate}<br />
//                                                                                                     End Date : {moment(item.promo_end_date).format("YYYY-MM-DD")}</span>
//                                                                                                 :
//                                                                                                 <span>-</span>

//                                                                                             }
//                                                                                         </td>
//                                                                                         <td>{item.unlimited_sales ? `Unlimited` : item.sales_per_day}</td>
//                                                                                         <td>{item.unlimited_registration ? `Unlimited` : item.number_of_registers}</td>
//                                                                                         <td>{item.unlimited_users ? `Unlimited` : item.number_of_users}</td>
//                                                                                         <td>{item.online_orders ? `Yes` : "No"}</td>
//                                                                                         <td>{item.table_reservation ? `Yes` : "No"}</td>
//                                                                                         <td>{item.offers_vouchers ? `Yes` : "No"}</td>
//                                                                                         <td>{item.loyalty_cards ? `Yes` : "No"}</td>
//                                                                                         <td>{item.payment_links ? `Yes` : "No"}</td>
//                                                                                         <td>
//                                                                                             <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
//                                                                                                 history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
//                                                                                             }}>
//                                                                                                 <i className="fa fa-edit"></i>
//                                                                                             </span>
//                                                                                         </td>
//                                                                                         <td>
//                                                                                             {/* {item.status} */}
//                                                                                             {loadingItem && loadingItem == item._id ?
//                                                                                                 <ClipLoader size={20} color="#36D7B7" loading={true} />
//                                                                                                 :
//                                                                                                 <Switch
//                                                                                                     onChange={() => toggleStatus(item, index)}
//                                                                                                     checked={item.status == 2 ? false : true}
//                                                                                                     className="react-switch"
//                                                                                                     height={20}
//                                                                                                     width={40}
//                                                                                                 />

//                                                                                             }
//                                                                                         </td>

//                                                                                     </tr>
//                                                                                 )
//                                                                             })
//                                                                                 : null
//                                                                             }
//                                                                         </tbody>
//                                                                     </table>



// <Row className="border mx-2">
//                                                                     <Col style={{ padding: "0px" }}>
//                                                                         <Col className="headerCell" style={{ height: "50px" }} >Title of subscription</Col>
//                                                                         <Col className="headerCell" style={{ height: "150px", overflow: "auto" }} >Sub Title</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Subscription Fee</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Promotional Details</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Cloud-based POS</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Number Of Registers</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Number of Users</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }} >Online Ordering System</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Table Reservation</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Offers & Vouchers</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Loyalty cards</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Payment links</Col>
//                                                                         {/* <Col  >Modifiers</Col> */}
//                                                                         <Col className="headerCell" style={{ height: "70px" }}>Action</Col>
//                                                                         <Col className="headerCell" style={{ height: "70px" }}></Col>
//                                                                     </Col>

//                                                                     {result && result.length > 0 && result.map((item, index) => {
//                                                                         return (
//                                                                             <Col style={{ padding: "0px" }}>
//                                                                                 <Col className="dataCell" style={{ height: "50px" }}>{item.title}
//                                                                                     {item.is_trial &&
//                                                                                         <span style={{ marginLeft: 10, fontSize: 8, backgroundColor: "orange", padding: "1px 4px", border: "1px solid orange", borderRadius: 8, color: "#fff" }}>Trial</span>
//                                                                                     }
//                                                                                 </Col>
//                                                                                 <Col className="dataCell" style={{ height: "150px", overflow: "auto" }} >{item.description}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.fees}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>
//                                                                                     {item.promo_rate > 0 ?
//                                                                                         <span>Fees : {item.promo_rate}<br />
//                                                                                             End Date : {moment(item.promo_end_date).format("YYYY-MM-DD")}</span>
//                                                                                         :
//                                                                                         <span>-</span>

//                                                                                     }
//                                                                                 </Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.unlimited_sales ? `Unlimited` : item.sales_per_day}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.unlimited_registration ? `Unlimited` : item.number_of_registers}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.unlimited_users ? `Unlimited` : item.number_of_users}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.online_orders ? `Yes` : "No"}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.table_reservation ? `Yes` : "No"}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.offers_vouchers ? `Yes` : "No"}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.loyalty_cards ? `Yes` : "No"}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>{item.payment_links ? `Yes` : "No"}</Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>
//                                                                                     <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
//                                                                                         history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
//                                                                                     }}>
//                                                                                         <i className="fa fa-edit"></i>
//                                                                                     </span>
//                                                                                 </Col>
//                                                                                 <Col className="dataCell" style={{ height: "70px" }}>
//                                                                                     {/* {item.status} */}
//                                                                                     {loadingItem && loadingItem == item._id ?
//                                                                                         <ClipLoader size={20} color="#36D7B7" loading={true} />
//                                                                                         :
//                                                                                         <Switch
//                                                                                             onChange={() => toggleStatus(item, index)}
//                                                                                             checked={item.status == 2 ? false : true}
//                                                                                             className="react-switch"
//                                                                                             height={20}
//                                                                                             width={40}
//                                                                                         />

//                                                                                     }
//                                                                                 </Col>


//                                                                             </Col>
//                                                                         )
//                                                                     })}

//                                                                 </Row>