export let api = {
    //Customers
    get_all_customers_data: "backendapi/customers/getAllCustomersData",
    customerCountByCountry: "backendapi/customers/customerCountByCountry",
    get_all_notify_data: "backendapi/customers/get_all_notify",
    customer_list: "backendapi/customers/get_all_customers",
    customers_wallet: "backendapi/customers/customers_wallet",
    delete_customer_data: "backendapi/customers/delete_customer_data",
    delete_selected_customer: "backendapi/customers/delete_select_customer",
    update_customer: "backendapi/customers/update_customer",
    change_ipayPin: "backendapi/customers/changeIpayPin",
    change_password: "backendapi/customers/changePassword",
    get_customer_cards: "backendapi/customers/get_customer_cards",
    get_customer_documents: "backendapi/customers/fetchdocuments",
    update_customer_addInfo: "backendapi/customers/submit_info_req_form",
    upload_customer_document: "backendapi/customers/upload_document",
    verify_customer: "backendapi/customers/verify_customer",
    get_cust_visits_data: "backendapi/customers/getVisitsdata",
    unverCust_sendCredentials: "backendapi/customers/unverCust_sendCredentials",
    search_profile: "backendapi/customers/search_profile",
    getRefuse_visitorRegistry: "api/customers/visitor_refuse_data",
    getAllRoles: "api/role/getallroles",
    updateDefaultRoles: "api/role/updateDefaultRoles",

    // subscription
    create_subscription: "api/subscription/create",
    update_subscription: "api/subscription/update",
    popular_update: "api/subscription/popular-update",
    sort_update: "api/subscription/sort-update",
    fetch_subscription: "api/subscription/fetch",
    fetch_plans: "api/subscription/fetchplans",
    delete_subscription: "api/subscription/delete",
    update_feature_sort_index: "featureDesc/update_feature_sort_index",
    fetch_featureDesc: "featureDesc/fetchFeatureDesc",
    save_featureDesc: "featureDesc/saveFeatureDesc",
    fetch_invoice: "api/subscription/invoice",
    add_period: "api/subscription/add_period",
    add_features: "api/subscription/add_features",
    edit_features: "api/subscription/edit_features",
    delete_features: "api/subscription/delete_features",
    fetch_features: "api/subscription/fetch_features",
    add_feature_title: 'api/subscription/add_feature_title',
    fetch_features_titles: "api/subscription/fetch_feature_titles",
    edit_feature_titles: "api/subscription/edit_feature_titles",
    delete_feature_titles: "api/subscription/delete_feature_titles",
    edit_features_state: "api/subscription/edit_features_state",
    toggle_status_features: "api/subscription/toggle_status_features",
    fetchperiod: "api/subscription/fetchperiod",
    deleteperiod: "api/subscription/deleteperiod",
    toggle_status: "api/subscription/toggle_status",
    toggle_status_period: "api/subscription/toggle_status_period",
    update_merchants_subscription: "api/subscription/update_merchants_subscription",
    fetchFeatures: "features/fetchFeatures",
    app_preference_fetch: "app/preference/fetch",
    app_preference_update: "app/preference/update",
    fetch_wlm_subscription: "api/subscription/fetchWLMSubscription",
    fetch_merchant_enable_features: "features/fetch_merchant_features",
    update_merchant_enable_features: "features/update_merchant_features",
    fetchSubscriptionCategory: "api/subscription/fetchSubscriptionCategory",
    updateSubscriptionCategory: "api/subscription/updateSubscriptionCategory",
    addSubscriptionCategory: "api/subscription/addSubscriptionCategory",
    deleteAllSubscriptionCategory: "api/subscription/deleteAllSubscriptionCategory",

    fetch_cat_single: "api/catsubcat/fetchSingle",

    //promocodes
    savePromoCodes: "promoCodeApi/savePromoCodesData",
    getAllPromoCodes: "promoCodeApi/getAllPromoCodesData",
    deletePromoData: "promoCodeApi/deletePromoCodeData",
    editPromoData: 'promoCodeApi/editPromoCodeData',

    // locationsData 
    saveLocationsData: "locationsApi/saveLocationsData",
    getAllLocationsData: "locationsApi/getAllLocationsData",
    editLocationsData: "locationsApi/editLocationsData",
    deleteLocationsData: "locationsApi/deleteLocationsData",

    //cards
    add_card: "backendapi/cards/add_card",
    getBlockedCards: "backendapi/cards/getBlockedCards",
    blockCard: "backendapi/cards/blockCard",
    unblockCard: "backendapi/cards/unblockCard",
    all_cards: "backendapi/cards/get_all_cards",
    //Merchants
    stripe_account: "backendapi/merchants/stripe_account",

    merchant_update_request: "backendapi/merchants/merchant_update_request",
    merchant_request_list: "backendapi/merchants/merchant_requests",
    merchantTabCount: "backendapi/merchants/merchantTabCount",
    merchantCountByCountry: "backendapi/merchants/merchantCountByCountry",
    merchant_details: "backendapi/merchants/getmerchant_details",
    merchant_by_referral: "api/merchant_api/getmerchant_by_referral",
    merchant_details_store: "api/merchant_api/getmerchant_details",
    merchant_info: "backendapi/merchants/merchant_info",
    getuser_details: "backendapi/merchants/getuser_details",
    update_approval_status: "api/merchant_api/merchant_approval_status_change",
    update_merchant_details: "backendapi/merchants/update_merchant_details",
    merchant_change_ipayPin: "backendapi/merchants/changeIpayPin",
    merchant_change_password: "backendapi/merchants/changePassword",
    get_merchant_cards: "backendapi/merchants/get_merchant_cards",
    get_merchant_visitors: "backendapi/merchants/get_visitors",
    merchant_field_changes: "backendapi/merchants/merchant_field_changes",
    merchant_mobileNo_verify: "backendapi/merchants/merchant_mobileNo_verify",
    merchant_wallet: "backendapi/merchants/merchant_wallet",
    merchant_wallet_data: "backendapi/merchants/merchant_wallet_data",
    fetch_transaction: "backendapi/merchants/fetch_transaction",
    fetch_merchant_users: "backendapi/merchants/fetch_merchant_users",
    get_merchant_documents: "backendapi/merchants/fetchdocuments",
    update_merchant_addInfo: "backendapi/merchants/submit_info_req_form",
    upload_merchant_document: "backendapi/merchants/upload_document",
    merchant_list: "backendapi/merchants/merchant_check_category",
    stores_list: "backendapi/merchants/all_stores_list",
    stores_list_access: "backendapi/merchants/all_stores_list_access",
    stripe_account_user: "api/v3/stripe_account",
    fetchBankAccount: "payoutSetting/fetchBankAccount",
    disable_listing: "backendapi/merchants/disable_listing",

    updateMerchantDetails: "backendapi/merchants/updateMerchantDetails",

    verify_merchant_document: "backendapi/merchants/verify_document",
    request_document: "backendapi/merchants/request_document",
    request_additional_document: "backendapi/merchants/request_additional_document",

    verify_merchant: "backendapi/merchants/verify_customer",
    merchant_billing: "backendapi/billings/fetch_billing_data",

    // withdraw_request: "backendapi/billings/withdraw_request",
    // dueSettlementStatus: "backendapi/billings/dueSettlementStatus",
    dueSettlementStatus:
        "backendapi/transactions/proceed_settlement_transaction",
    refund_request: "backendapi/transactions/add_refund_request",
    merc_customer_list: "backendapi/merchants/customer_list",
    merc_add_customer: "backendapi/merchants/add_customer",
    merc_update_customer: "backendapi/merchants/update_customer",
    merc_transc_customer_list: "backendapi/merchants/tranc_customer_list",
    merc_customer_delete: "backendapi/merchants/delete_customer",

    link_sf_restaurant: "backendapi/merchants/link_sf_restaurant",

    merchant_billing_update: "backendapi/billings/update_billing_data",
    add_mcredit_earning: "backendapi/billings/add_mcredit_earning",
    delete_merchant: "backendapi/merchants/delete_merchant",
    delete_selected_merchant: "backendapi/merchants/delete_select_merchant",
    webMerc_sendCredentials: "backendapi/merchants/webMerc_sendCredentials",
    bank_update_request: "backendapi/merchants/bank_update_request",
    merc_generate_poster: "backendapi/merchants/generate_poster",
    merchant_delete_account: "api/v3/merchant_delete_account",
    getById_visitorRegistry: "api/customers/visitors_details_by_id",

    //transactions
    all_transactions: "backendapi/transactions/all_transactions",
    transactions_list: "backendapi/transactions/transactions_list",
    tab_counts: "backendapi/transactions/tab_counts",
    sub_tab_counts: "backendapi/transactions/sub_tab_counts",

    sidebarCounts: "backendapi/transactions/sidebarCounts",

    cashback_transactions: "backendapi/transactions/cashback_transactions",
    delete_transaction: "backendapi/transactions/delete_transaction",
    update_transaction: "backendapi/transactions/update_transaction",
    delete_selected_transaction:
        "backendapi/transactions/delete_select_transaction",

    transfer_to_wallet: "backendapi/transactions/transfer_to_wallet",

    //Potential Merchants
    all_potential_merchants:
        "backendapi/potentialMerchants/getAllPotentialMerchant",
    add_potential_merchant:
        "backendapi/potentialMerchants/addPotentialMerchant",
    potential_merchant_details:
        "backendapi/potentialMerchants/getPotentialMerchantDetails",
    delete_potential_merchant:
        "backendapi/potentialMerchants/deletePotentialMerchant",
    delete_selected_potential_merchant:
        "backendapi/potentialMerchants/deleteSelectedPotentialMerchant",

    add_potential_merchant_csv:
        "backendapi/potentialMerchants/addPotentialMerchantCSV",
    update_potential_merchant_details:
        "backendapi/potentialMerchants/update_potential_merchant_details",

    generate_merchant_qr_code:
        "backendapi/potentialMerchants/generate_merchant_qr_code",
    update_pot_merch_approval_status:
        "backendapi/potentialMerchants/update_pot_merch_approval_status",

    generate_shape_qr_code: "businessapi/v1/merchant/generate_shape_qr_code",
    generate_shape_qrcode: "businessapi/v1/merchant/generate_shape_qrcode",
    get_qrAdmindata: "businessapi/v1/merchant/getQrAdmin",
    deleteQrAdmin: "businessapi/v1/merchant/deleteQrAdmin",
    getQrImage: "api/qr/preview/getQrImage",

    fetchQrFronside: "businessapi/qr-frontside/fetch_qr_fronside",
    make_default_template: "businessapi/qr-frontside/make_default_template",
    updateQrFronside: "businessapi/qr-frontside/update_qr_fronside",

    //Merchants
    sop_partner_list: "api/sop_api/sop_partner_list",
    getActiveMerchants: "api/merchant_api/getActiveMerchants",

    //visitors
    delete_visitor_registry: "backendapi/visitors/deleteVisitorRegistry",
    get_all_visitors: "backendapi/visitors/getAllVisitors",
    delete_select_visit: "backendapi/visitors/deleteSelectedVisit",
    updateVisitEntry: "backendapi/visitors/updateVisitEntry",
    addVisitorImage: "backendapi/visitors/addVisitorImage",

    //quickBills
    // fetch_features: "api/subscription/fetch_features",

    merchant_fetchbills: "backendapi/quickbills/fetch_bills",
    merchant_deletebill: "backendapi/quickbills/delete_bill",
    merchant_editBill: "backendapi/quickbills/edit_bill",

    //guard settings
    merchant_fetchGuardSetting: "backendapi/guardSetting/fetchguardSetting",
    merchant_updateGuardSetting: "backendapi/guardSetting/updateguardSetting",
    merc_fetchGuardBooking: "backendpai/guardBooking/fetchMercGuardBooking",
    updateAllguardMercPaymentOption:
        "backendapi/guardSetting/updateAllMercPayment",
    getAllguardMercPaymentOption: "backendapi/guardSetting/getAllMercPayment",
    add_GuardBooking: "backendpai/guardBooking/addGuardBooking",
    update_GuardBooking: "backendpai/guardBooking/updateGuardBooking",

    getDefaultPaymentOption: "backendapi/paymentOptions/getAllPayment",
    updateDefaultPaymentOption: "backendapi/paymentOptions/updateAllPayment",

    get_payment_gateway: "backendapi/paymentOptions/get_payment_gateway",
    updateDefaultPaymentGateway: "backendapi/paymentOptions/updateDefaultPaymentGateway",

    //pdf
    createhospsafePdf: "backendapi/pdf/createhospsafePdf",
    createPdf: "backendapi/pdf/createPdf",
    fetchPdf: "backendapi/pdf/fetchPdf",
    createInvoicePdf: "backendapi/pdf/createInvoicePdf",
    shareInvoicePdf: "backendapi/pdf/shareInvoicePdf",

    //search
    mastersearch: "backendapi/search/result",
    getAllData: "backendapi/search/allData",
    getAllDataN: "cron_jobs/admin_dashboard_data",
    getCustMerchData: "backendapi/search/getCustMerchData",
    validateCustData: "backendapi/search/validateCustData",

    filterCustMerchData: "backendapi/search/filterCustMerchData",
    get_level_active_rewards: "api/v2/offers/get_level_active_rewards",
    getMultipleMerchantWallet: "backendapi/search/getMultipleMerchantWallet",

    masterSearchQuery: "backendapi/search/masterSearchResult",

    //topup accounts

    createTopupTransaction: "backendapi/topupAccounts/createTopupTransaction",
    authorizeTopup: "backendapi/topupAccounts/authorizeTopup",
    getAllTopup: "backendapi/topupAccounts/allTopup",
    deleteTopup: "backendapi/topupAccounts/deleteTopup",
    add_cashback: "backendapi/topupAccounts/add_cashback",

    //settings
    cashback_settings: "backendapi/configuration/cashback_settings",
    mbillingconfig: "backendapi/configuration/getmbillingconfig",
    update_billing_settings: "backendapi/configuration/update_billing_settings",
    update_cbilling_data: "backendapi/configuration/update_cbilling_data",
    update_ashback_settings: "backendapi/configuration/update_ashback_settings",
    create_sms_rate: "backendapi/configuration/create_sms_rate",
    find_sms_rate: "backendapi/configuration/find_sms_rate",
    update_sms_data: "backendapi/configuration/update_sms_data",

    //Other Setting
    add_catsubcat: "api/catsubcat/add",
    img_catsubcat: "api/catsubcat/img",
    fetch_catsubcat: "api/catsubcat/fetch",
    edit_catsubcat: "api/catsubcat/edit",
    delete_catsubcat: "api/catsubcat/delete",
    disable_subcat: "api/catsubcat/disable",
    update_desc: "api/catsubcat/update_desc",
    update_cat_feat: "api/catsubcat/update_cat_feat",
    pngImages: "api/catsubcat/pngImages",

    //Venues Setting
    add_cuisines: "api/cuisines/add",
    fetch_cuisines: "api/cuisines/fetch",
    delete_cuisines: "api/cuisines/delete",
    disable_cuisines: "api/cuisines/disable",
    edit_cuisines: "api/cuisines/edit",
    img_cuisines: "api/cuisines/img",

    //help topic
    addHelptopics: "backendpai/helptopics/addHelptopics",
    editHelptopics: "backendpai/helptopics/editHelptopics",
    updateHelpSubtopicsRank: "backendpai/helptopics/updateHelpSubtopicsRank",
    fetchHelptopics: "backendpai/helptopics/fetchHelptopics",
    img_Helptopics: "backendpai/helptopics/img",
    deleteHelptopics: "backendpai/helptopics/deleteHelptopics",
    fetchHelpquestion: "backendpai/helptopics/fetchHelpquestion",
    addHelpquestion: "backendpai/helptopics/addHelpquestion",
    deleteHelpquestion: "backendpai/helptopics/deleteHelpquestion",
    editHelpquestion: "backendpai/helptopics/editHelpquestion",

    //image gallery
    fetchimgfolder: "backendpai/helptopics/getimgfolder",
    createimgfolder: "backendpai/helptopics/createimgfolder",
    deleteimgfolder: "backendpai/helptopics/deleteimgfolder",
    getImages: "backendpai/helptopics/getImages",
    addimage: "backendpai/helptopics/addimage",
    deleteimg: "backendpai/helptopics/deleteimg",

    // Store settings
    fetch_store: "api/storeSlider/fetch",
    add_slider: "api/storeSlider/add",
    edit_slider: "api/storeSlider/edit",
    del_slider: "api/storeSlider/del",
    img_slider: "api/storeSlider/img",

    // offers
    add_offers: "backendapi/settings/add_offers",
    fetch_offers: "backendapi/settings/fetch_offers",
    fetch_giftvouchers: "backendapi/settings/fetch_giftvouchers",
    delete_offers: "backendapi/settings/delete_offer",
    update_offers: "backendapi/settings/update_offers",
    add_gift_vouchers: "backendapi/settings/add_gift_vouchers",

    add_filter_category: "backendapi/settings/add_filter_category",
    edit_filter_category: "backendapi/settings/edit_filter_category",

    edit_gift_vouchers: "backendapi/settings/edit_gift_vouchers",
    delete_gift_vouchers: "backendapi/settings/delete_gift_vouchers",

    update_offers_status: "backendapi/settings/update_offers_status",
    update_offerverifyStatus: "backendapi/settings/update_verifyOffer",

    add_new_mail_template: "backendapi/settings/add_new_mail_template",
    get_all_mail_templates: "backendapi/settings/get_all_mail_template",
    get_mail_templates_details: "backendapi/settings/get_mail_templates_details",
    update_many_template: "backendapi/settings/update_many_templates",
    update_mail_template_details: "backendapi/settings/update_mail_template_details",
    update_mail_template_layout: "backendapi/settings/update_mail_template_layout",
    send_template_test_mail: "backendapi/settings/send_template_test_mail",
    fetchSMSCharges: "api/sms/fetchsmscharges",
    delete_mail_template: "backendapi/settings/delete_mail_template",
    delete_many_mail_template: "backendapi/settings/delete_many_mail_template",
    // add_template_category: "backendapi/settings/add_template_category",
    add_template_category: "backendapi/settings/addTemplateGrouping",
    fetch_template_category: "backendapi/settings/fetchTemplateGrouping",
    update_cate_mail_template: "backendapi/settings/update_template_mail_category",
    sendTemplateTestMail: "backendapi/settings/sendTemplateTestMail",
    get_all_folder: "backendapi/settings/get_all_folder",
    move_to_folder: "backendapi/settings/move_to_folder",
    delete_folder: "backendapi/settings/delete_folder",
    rename_folder: "backendapi/settings/rename_folder",



    addEmaiTemplateImage: "backendapi/settings/add_emailtemplate_image",

    add_new_sms_template: "backendapi/settings/add_new_sms_template",
    get_all_sms_templates: "backendapi/settings/get_all_sms_template",
    update_many_sms_template: "backendapi/settings/update_many_smstemplates",
    delete_sms_template: "backendapi/settings/delete_sms_template",

    add_New_Level_Cust_Crown: "backendapi/settings/addNewLevelCustCrown",
    get_Cust_allCrownLevel: "backendapi/settings/getCustAllCrownLevel",
    edit_Cust_Crown_level: "backendapi/settings/editCustCrownLevel",
    del_Cust_Crown_level: "backendapi/settings/deleteCustCrownLevel",

    level_reward_processed: "backendapi/settings/level_reward_processed",
    get_Merc_allCrownLevel: "backendapi/settings/getMercAllCrownLevel",
    add_New_Level_Merc_Crown: "backendapi/settings/addNewLevelMercCrown",
    edit_Merc_Crown_level: "backendapi/settings/editMercCrownLevel",
    del_Merc_Crown_level: "backendapi/settings/deleteMercCrownLevel",

    //appVersion
    get_all_appVersion: "backendapi/settings/appVersion/getAllAppVersion",
    add_appVersion: "backendapi/settings/appVersion/addAppVersion",

    get_silentapp_version: "backendapi/settings/get_silentapp_version",
    update_silentapp_version: "backendapi/settings/update_silentapp_version",

    get_silentiosapp_version: "backendapi/settings/get_silentiosapp_version",
    update_silentiosapp_version: "backendapi/settings/update_silentiosapp_version",

    // send mail api
    send_mail_to_user: "backendapi/sendmail/send_single_mail_to_user",
    send_sms_to_user: "backendapi/sendmail/send_single_sms_to_user",
    send_bulk_mail: "backendapi/sendmail/send_bulk_mail",
    //admin
    new_adminUser: "adminapi/auth/newAdminUser",
    admin_signin: "adminapi/auth/admin_signin",
    admin_verifyOtp: "adminapi/auth/verify_otp",
    admin_resendOtp: "adminapi/auth/resend_otp",
    admin_resetPassword: "adminapi/auth/reset_password",
    admin_changePassword: "adminapi/auth/change_password",
    admin_logout: "adminapi/auth/logout",
    admin_deviceLogout: "adminapi/auth/logoutDevice",
    admin_logoutAll: "adminapi/auth/logoutAll",
    admin_profile_edit: "adminapi/common/admin_profile_edit",
    update_admin_password: "adminapi/common/update_admin_password",
    get_admin_details: "adminapi/common/getadmindetails",
    get_all_adminusers: "adminapi/common/allAdminusers",
    delete_adminUser: "adminapi/common/delete_adminUser",
    admin_details: "adminapi/common/adminDetails",

    //Enable Maintanance Mode
    fetchMaintananceMode: "adminapi/common/fetch_maintanance_mode",
    enableMaintanance: "adminapi/common/enable_maintanance_mode",


    get_all_restFeature: "backendapi/settings/accessLevel/allRestFeatures",
    add_restFeature: "backendapi/settings/accessLevel/addRestFeature",
    delete_restFeature: "backendapi/settings/accessLevel/deleteRestFeature",

    //Notifications
    get_all_notifications: "backendapi/notifications/getAllNotifications",
    register_pushNotific: "/backendapi/push/register",

    // dart
    add_delivery_charges: "backendapi/dart/add_delivery_charges",
    add_weight_charges: "backendapi/dart/add_weight_charges",
    get_all_charges: "backendapi/dart/get_all_charges",
    delete_dartcharges: "backendapi/dart/delete_dartcharges",

    //Users & roles

    deleteSubUser: "api/user/deletesubuser",


    //contact inquiry
    getAllContactInquiry: "businessapi/v1/contactrequest/getAllContactInquiry",

    // demo contact form data
    getAllContactUsData: "contactApi/getAllContactUsData",

    // get all callus merchant request data
    getAllCallUsData: "callUs/getAllCallUsData",
    saveCallUsData: "callUs/saveCallUs",

    //get contactUs Mail data
    saveContactUsMailData: "contactApi/updateContactData",

    //import google business
    saveGoogleBusiness: "backendapi/googlebusiness/addGoogleBusiness",
    getAllImportedBusiness: "backendapi/googlebusiness/fetchGoogleBusiness",
    updateGoogleBusiness: "backendapi/googlebusiness/updateGoogleBusiness",
    deleteGoogleBusiness: "backendapi/googlebusiness/deleteGoogleBusiness",
    getAllListingEnquiries: "backendapi/googlebusiness/fetchListingEnquiries",
    deleteListingEnquiries: "backendapi/googlebusiness/deleteListingEnquiries",

    //reservation
    fetch_restaurantstructure:
        "backendapi/reservation/fetch_restaurantstructure",

    // country
    add_country: "api/countries/add",
    fetch_country: "api/countries/fetch",
    fetch_legals: "api/countries/legals",
    edit_country: "api/countries/edit",
    countries_list: "api/countries/fetch_app",
    fetch_top_up_details: "api/countries/fetch_top_up_details",
    set_top_up_setting: "api/countries/set_top_up_setting",
    update_other_charges: "api/countries/update_other_charges",


    get_content_page_data: "api/contents/get_content_page_data",

    fetch_footer_page_data: "api/contents/fetch_footer_content_page_data",
    update_footer_page_data: "api/contents/update_footer_content_page_data",

    // Slider

    // post slider request
    customer_slider1: "customerSliderapi/customerSlider",
    merchant_slider1: "merchantSliderapi/merchantSlider",

    updatemerchantSlider1: "merchantSliderapi/updatemerchantSlider",

    // get Slider data request
    getcustomer_slider: "getcustomerSliderapi/getcustomerSlider",
    getmerchant_slider: "getmerchantSliderapi/getmerchantSlider",

    // delete slider data request
    deletecustomer_slider: "deletecustomerSliderapi/deletecustomerSlider",
    deletemerchant_slider: "deletemerchantSliderapi/deletemerchantSlider",
    // Quiz api's
    saveQuizdata: "saveQuizDataApi/saveQuizdata",
    getQuizdata: "getQuizDataApi/getQuizData",
    deleteQuizdata: "deleteQuizApi/deleteQuizData",
    getResultdata: "saveResultApi/getResult",

    generate_temp_password: "api/v3/generate_temp_password",

    // printer_fetch: "api/pos/printers/fetch",
    // printer_add: "api/pos/printers/add",

    // hustlerz api's
    hustlerz_contact_info_all: 'hustlerzapi/getAllContactUsData',

    // white label merchant
    whiteLabelMerchant: 'whiteLabelMerchant/fetchWhiteLabelMerchantAdmin',
    delete_whitelabel_merchant: "whiteLabelMerchant/deleteWhiteLabelMerchant",
    updateStatus: "whiteLabelMerchant/update_white_label",

    // Logo and business type
    fetchBusinessType: 'whiteLabelMerchant/logo_and_business_type_admin',
    updateBusinessType: 'whiteLabelMerchant/update_business_type',
    deleteBusinessType: 'whiteLabelMerchant/delete_business_type',
    fetchPortalCatSubCat: 'whiteLabelMerchant/fetch_portal_catsubcat',
    updatePortalCatSubCat: 'whiteLabelMerchant/update_portal_catsubcat',
    fetch_other_cat: 'whiteLabelMerchant/fetch_other_cat',
    add_image: 'whiteLabelMerchant/add_image',

    // price per store
    fetchPricePerStore: 'whiteLabelMerchant/fetch_price_per_store',
    updatePricePerStore: 'whiteLabelMerchant/update_price_per_store',
    deletePricePerStore: 'whiteLabelMerchant/delete_price_per_store',

    /// fetch store and merchant 
    fetch_all_stores: "api/v4/merchant/fetch_all",
    getAllSubUsers: "api/user/getsubusers",
    getUsersAccess: "api/user/getusersaccess",
    deleteUsersAccess: "api/user/deleteusersaccess",
    getUserLoginHistory: "api/user/getuserloginhistory",



    transaction_details: "api/v2/wallet/transaction_details",

    //newsletter subscribers api
    getAllSubscribers: "newsletterApi/getAllSubscriptionEmail",
    getAllSubscribersN: "newsletterApi/getAllSubscriptionEmailN",

    // Unsubscribed users
    getAllUnsubscribeEmail: "unsubscribedUsers/getAllUnsubscribeEmail",
    deleteUnsubscribeEmail: "unsubscribedUsers/deleteUnsubscribeEmail",
    updateUnsubscribeEmail: "unsubscribedUsers/updateUnsubscribeEmail",


    fetctWidgetPosition: "saveDisplayStateApi/getWidgetPositionState",

    fetch_all_stores_progress_status: "saveDisplayStateApi/fetch_all_store_progress_status",

    saveMerchantAdvertise: "api/merchant/dashboard/saveAdvertise",
    saveMerchantAdvertiseM: "api/merchant/dashboard/saveAdvertiseM",
    saveMerchantSection1: "api/merchant/dashboard/saveSection1",
    saveMerchantSection2: "api/merchant/dashboard/saveSection2",
    saveMerchantSection3: "api/merchant/dashboard/saveSection3",
    saveMerchantSection4: "api/merchant/dashboard/saveSection4",
    getMerchantDashboard: "api/merchant/dashboard/getDashboard",

    //POS Items
    getPOSItems: "api/pos/getitems",

    // billingConfig
    fetch_billing_data: "api/merchant_api/fetch_billing_data",

    // update menu types
    updateMenuTypes: "api/merchant_api/updateMenuTypes",

    // fetch printers
    fetchPrinter: "api/pos/printers/fetch",

    // fetch register
    getPosRegister: "api/pos/getregister",

    // booking_status
    update_booking_status: "businessapi/reservation-settings/update_bookingStatus",
    get_working_time: "businessapi/reservation-settings/get_working_time",
    unblock_user: "backendapi/settings/unblock_user",

    //fetch_bills
    fetch_bills: "api/v2/quickbills/fetch_bills",
    delete_bills: "api/v2/quickbills/delete_bill",

    //requestpayments
    link_requests: "api/merchant_api/linkrequests",
    delete_requests: "api/merchant_api/deleterequests",

    //password and pin changes
    changePin: "api/user/changePin",
    changePassword: "api/user/changePassword",

    userDetailsSave: "api/user/userDetailsSave",
    updateMercDetails: "api/merchant_api/update_profile",
    fetch_refer_details: "api/v3/fetch_refer_details",
    referral_stores: "api/v2/quickbills/referral_stores",

    updateUserProfile: "api/v3/updateUserProfile",

    getTax: "api/tax/gettax",

    order_history: "api/pos-front/filter_order_history",

    booking_details: "businessapi/bookings/fetchCusbookings",

    orders_by_country: "api/pos-front/orders_by_country",

    credit_points: "api/v2/wallet/credit_points",
    wallet_details: "api/wallet/wallet_details",
    downloadInvoice: "api/merchant_api/download_invoice",
    get_tier_request: "app/tier-api/get_tier_request",
    update_tier_level: "app/tier-api/update_tier_level",
    update_comment_tier_request: "app/tier-api/update_comment_tier_request",
    delete_tier_request: "app/tier-api/delete_tier_request",
    d_create_folder: "adminapi/dropbox/create_folder",
    update_ticket_payment_status: "api/merchant_api/update_ticket_payment_status",

    // Minisite Events
    fetchEvents: "api/minisite-events/all",
    addEvents: "api/minisite-events/add",
    updateEvent: "api/minisite-events/update",
    deleteEvent: "api/minisite-events/delete",

    //contact forms
    fetchForms: "api/minisite-contact-form/all",

    //adminAutoLogin
    adminAutoLogin: "api/adminAutoLogin/login",

    fetchSMSCharges: "api/sms/fetchsmscharges",
    sendSMS: "api/sms/sendSMS",
    getSmsData: "api/sms/getAdminSms",
    fetchSmsDetails: "api/sms/fetchsmsdetails",

    shortenUrl: "backendapi/url/shortenUrl",
    getUrl: "backendapi/url/getShortUrl",
    deleteUrl: "backendapi/url/deleteUrl",

    moneyrequest: "api/merchant_api/moneyrequest",

    //functional Bookings
    getEnquiry: "api/functional-bookings/getEnquiry",
    getEnquiryById: "api/functional-bookings/getEnquiryById",
    enquiry_invoice: "api/functional-bookings/enquiry_invoice",

};
export default api;
