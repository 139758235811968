import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { setLayoutData, setMainElement, setStyleElement } from "../../../../store/templateEditorData";


const AddbtnModal = ({ modalOpen, toggleModal, selectedBtn, setSelectedBtn, hoverIndex, setGetIndex }) => {

    const dispatch = useDispatch();
    const { templateLayoutData } = useSelector((state) => state.emailTemplateEditorData);

    const [buttonProps, setButtonProps] = useState({ btnName: "", linkType: "", btnLink: "" });
    const [isURLError, setIsURLError] = useState(false);

    const isValidURL = (url) => {
        try {
            let newUrl = new URL(url);
            return newUrl.protocol === "http:" || newUrl.protocol === "https:";
        } catch (_) {
            return false;
        }
    };

    useEffect(() => {
        if (buttonProps.btnLink.trim() !== "") {
            setIsURLError(!isValidURL(buttonProps.btnLink));
        } else {
            setIsURLError(false);
        }
    }, [buttonProps.btnLink]);

    // ✅ Handle button addition
    const handleAddButton = () => {
        if (isURLError || !buttonProps.btnName || !buttonProps.btnLink) return;

        const newButton = { ...selectedBtn, content: buttonProps.btnName, href: buttonProps.btnLink, id: uuidv4() };
        const index = typeof hoverIndex === "number" ? hoverIndex : templateLayoutData.length;

        const newLayoutData = [
            ...templateLayoutData.slice(0, index),
            newButton,
            ...templateLayoutData.slice(index)
        ];

        dispatch(setLayoutData(newLayoutData));
        // dispatch(setLayoutData([...templateLayoutData, newButton]));
        dispatch(setMainElement(newButton));
        dispatch(setStyleElement(newButton));
        setGetIndex(null);
        setButtonProps({ btnName: "", linkType: "", btnLink: "" });
        setSelectedBtn(null);
        toggleModal();
    };

    return (
        <>
            <Modal centered isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader style={{ borderBottom: "none" }}>Add Button</ModalHeader>
                <ModalBody style={{ paddingTop: "0rem" }}>
                    <div className="d-flex align-items-center my-1 row" style={{ paddingTop: "5px" }}>
                        <div className="col-4">
                            <label
                                className="mb-0"
                                style={{
                                    marginRight: "15px",
                                    lineHeight: "38px",
                                    fontSize: "15px"
                                }}
                            >
                                Button Name
                            </label>
                        </div>
                        <div className="col-8">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Please enter button name"
                                value={buttonProps.btnName}
                                onChange={(e) => setButtonProps((prevData) => ({ ...prevData, btnName: e.target.value }))}
                                style={{
                                    flex: 1,
                                    border: "1px solid gainsboro",
                                    outline: "none",
                                    padding: "5px",
                                    fontSize: "15px",
                                    boxShadow: "none"
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center row my-1" style={{ paddingTop: "5px" }}>
                            <div className="col-4">
                                <label
                                    className="mb-0"
                                    style={{
                                        marginRight: "15px",
                                        lineHeight: "38px",
                                        fontSize: "15px"
                                    }}
                                >
                                    Link Type
                                </label>
                            </div>
                            <div className="col-8">

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter alternative text here..."
                                    value={buttonProps.linkType}
                                    onChange={(e) => setButtonProps((prevData) => ({ ...prevData, linkType: e.target.value }))}
                                    style={{
                                        flex: 1,
                                        border: "1px solid gainsboro",
                                        outline: "none",
                                        padding: "5px",
                                        fontSize: "15px",
                                        boxShadow: "none"
                                    }}
                                />
                            </div>
                        </div> */}
                    <div className="d-flex align-items-center row my-1" style={{ paddingTop: "5px" }}>
                        <div className="col-4">
                            <label
                                className="mb-0"
                                style={{
                                    marginRight: "15px",
                                    lineHeight: "38px",
                                    fontSize: "15px"
                                }}
                            >
                                Button Link
                            </label>
                        </div>
                        <div className="col-8">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Please enter a button link."
                                value={buttonProps.btnLink}
                                onChange={(e) => setButtonProps((prev) => ({ ...prev, btnLink: e.target.value }))}
                                style={{
                                    flex: 1,
                                    border: "1px solid gainsboro",
                                    outline: "none",
                                    padding: "5px",
                                    fontSize: "15px",
                                    boxShadow: "none"
                                }}
                            />
                            {isURLError && <p className='mb-0 mt-1 mx-0' style={{ color: "red" }}>Invalid URL</p>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!buttonProps.btnName || !buttonProps.btnLink ? true : false}
                        onClick={() => { handleAddButton() }}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default AddbtnModal;
