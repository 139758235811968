import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedElement, setLayoutData, setTableProps, updateTemplateLayout } from "../../../../../store/templateEditorData";
import { produce } from "immer";


const EditableTable = ({ elemContent, isEdit }) => {

    const { headers, data } = elemContent.tableData;
    const dispatch = useDispatch();
    const { selectedElementData, templateLayoutData } = useSelector((state) => state.emailTemplateEditorData);

    const handleHeaderBlur = (e, index) => {
        const updatedHeaders = [...headers];
        updatedHeaders[index] = e.target.innerText;
        let updateData = { ...elemContent, tableData: { ...elemContent.tableData, headers: updatedHeaders } };
        dispatch(updateTemplateLayout({ data: updateData }));
        // dispatch(setTableProps(null));

    };

    // Handle cell edits
    const handleCellBlur = (e, rowIndex, colIndex) => {
        const updatedData = produce(data, (draft) => {
            draft[rowIndex][colIndex] = e.target.innerText;
        });
        let updateData = { ...elemContent, tableData: { ...elemContent.tableData, data: updatedData } };
        dispatch(updateTemplateLayout({ data: updateData }));
        // dispatch(setTableProps(null));
    };

    const columnStyle = {
        padding: "10px",
        border: "1px solid #ddd",
        fontWeight: "bold",
        textAlign: "left",
        boxShadow: "none",
        outline: "none",
        overflowWrap: "break-word",
        wordBreak: "break-word"
    };

    const cellStyle = {
        padding: "8px",
        border: "1px solid #ddd",
        textAlign: "left",
        boxShadow: "none",
        outline: "none",
        overflowWrap: "break-word",
        wordBreak: "break-word"
    };

    const calculateWidth = (text) => {
        const minWidth = 80;  // Minimum width in pixels
        const maxWidth = 300; // Maximum width in pixels
        const baseWidth = text.length * 10; // Approximate width calculation

        return `${Math.min(Math.max(baseWidth, minWidth), maxWidth)}px`;
    };

    return (
        <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
            <table
                style={{
                    borderCollapse: "collapse",
                    width: "95%",
                    border: "1px solid #ddd",
                    tableLayout: "fixed",
                }}
            >
                <thead>
                    <tr style={{ background: "#f5f5f5", height: "40px" }}>
                        {headers.map((header, index) => (
                            <th
                                key={index}
                                style={{
                                    ...columnStyle,
                                    maxWidth: calculateWidth(header), // Dynamic width
                                }}
                                contentEditable={isEdit}
                                suppressContentEditableWarning
                                onBlur={(e) => handleHeaderBlur(e, index)}
                                onClick={() => { dispatch(setTableProps({ type: "header", rowIndex: 1, colIndex: index })) }}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, colIndex) => (
                                <td
                                    key={colIndex}
                                    style={cellStyle}
                                    contentEditable={isEdit}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleCellBlur(e, rowIndex, colIndex)}
                                    onClick={() => { dispatch(setTableProps({ type: "data", rowIndex: rowIndex, colIndex: colIndex })) }}
                                >
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EditableTable;


