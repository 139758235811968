import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LuMove } from "react-icons/lu";
import { PiCopySimpleThin } from "react-icons/pi";
import { MdOutlineDelete } from "react-icons/md";
import DragListView from "react-drag-listview";
import { v4 as uuidv4 } from "uuid";
import { TbTableOptions } from "react-icons/tb";
import { RxGear } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { produce } from "immer";
import { Popover, PopoverBody } from "reactstrap";
import { setLayoutData, setSelectedElement, updateTemplateLayout, setMainElement, setStyleElement } from "../../../../store/templateEditorData";
import ColoumnElement from "./LayoutElement/ColumnElement"
import { ToolBarComponent } from "./ToolbarComponent";
import TextElement from "./LayoutElement/TextElement"
import ImageElement from "./LayoutElement/ImageElement"
import { ImageAndTextElement } from "./LayoutElement/ImageAndTextElement"
import ButtonElement from "./LayoutElement/ButtonElements"
import TableElement from "./LayoutElement/TableElement"
import SpacerElement from "./LayoutElement/SpacerElement";
import './EmailTemplate.scss';
import AddbtnModal from './AddbtnModal';
import DropZoneComp from "./DropZone/DropZone";
import LayoutComponentfrom from "./LayoutComponent";

const CanvasComponent = ({ templateLoading, setTemplateLoading }) => {

  const dispatch = useDispatch();
  const popoverRef = useRef(null);

  const {
    templateLayoutData,
    selectedElementData,
    tableProps,
    parentCompStyle,
    childCompStyle,
    mainElement,
    styleElement
  } = useSelector((state) => state.emailTemplateEditorData);


  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [getIndex, setGetIndex] = useState(null);
  const [toIndex, setToindex] = useState(null);
  // console.log(getIndex, "getIndex");
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const handleContextMenu = () => { setPopoverOpen(true) };
  const toggleModal = () => { setModalOpen(!modalOpen) };

  const handleDrop = useCallback(
    (event) => {
      // console.log("main drop function");
      event.preventDefault();
      const { elementDetils, dragType, fromIndex } = JSON.parse(event.dataTransfer.getData("component"));
      const toIndex = typeof getIndex === "number" ? getIndex : templateLayoutData.length;

      if (elementDetils) {

        if (dragType === "listDragDrop") {

          const newArray = [...templateLayoutData]; // Clone array
          const [movedElement] = newArray.splice(fromIndex, 1); // Remove the element
          newArray.splice(toIndex, 0, movedElement); // Insert at new position
          dispatch(setLayoutData(newArray));
          setGetIndex(null);

        } else if (dragType === "sidebar") {

          if (elementDetils.type === "normalbutton" || elementDetils.type === "roundedbutton" || elementDetils.type === "ovalbutton") {
            setSelectedBtn(elementDetils);
            toggleModal();
          } else {

            const newLayoutData = [
              ...templateLayoutData.slice(0, toIndex),
              elementDetils,
              ...templateLayoutData.slice(toIndex)
            ];

            dispatch(setLayoutData(newLayoutData));
            dispatch(setMainElement(elementDetils));
            dispatch(setSelectedElement(elementDetils));
            setGetIndex(null);
            if (["heading1", "heading2", "paragraph"].includes(elementDetils.type) || elementDetils.toolBarRef === "spacer") {
              dispatch(setStyleElement(elementDetils));
            }
          }

        } else { }
      }

      // document.querySelectorAll(".dragClass").forEach((el) => {
      //   el.style.opacity = "1";
      // });
    },
    [dispatch, templateLayoutData, setSelectedBtn, toggleModal]
  );


  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const deleteElement = useCallback(
    (elementData) => {
      const filteredData = templateLayoutData.filter(el => el.id !== elementData.id);
      dispatch(setSelectedElement(null));
      dispatch(setMainElement(null));
      dispatch(setStyleElement(null));
      dispatch(setLayoutData(filteredData));
    },
    [dispatch, templateLayoutData]
  );

  const copyElement = useCallback(
    (id) => {
      const elementToCopy = templateLayoutData.find(el => el.id === id);
      if (elementToCopy) {
        const copiedElement = { ...elementToCopy, id: uuidv4() };
        dispatch(setSelectedElement(copiedElement));
        dispatch(setMainElement(copiedElement));
        dispatch(setLayoutData([...templateLayoutData, copiedElement]));
        if (
          copiedElement.type === "heading1"
          || copiedElement.type === "heading2"
          || copiedElement.type === "paragraph"
          || copiedElement.type === "normalbutton"
          || copiedElement.type === "roundedbutton"
          || copiedElement.type === "ovalbutton"
        ) {
          dispatch(setStyleElement(copiedElement));
        }
      }
    },
    [dispatch, templateLayoutData]
  );

  // const onDragEnd = (fromIndex, toIndex) => {
  //   if (toIndex < 0) return;
  //   const updatedItems = [...templateLayoutData];
  //   const [movedItem] = updatedItems.splice(fromIndex, 1);
  //   updatedItems.splice(toIndex, 0, movedItem);
  //   dispatch(setLayoutData(updatedItems));
  // };

  // const dragProps = {
  //   onDragEnd,
  //   nodeSelector: ".dragClass",
  //   handleSelector: ".drag_handle",
  // };

  const handleAction = (action) => {

    let preTableData = { ...selectedElementData };
    const { headers, data } = preTableData.tableData;

    if (tableProps && tableProps.type === "data" && action == "insertRowBelow") {

      const updatedData = data.map(row => [...row]);
      const newRow = new Array(headers.length || 0).fill("");
      updatedData.splice(tableProps.rowIndex + 1, 0, newRow);
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && tableProps.type === "data" && action == "insertRowAbove") {

      const updatedData = data.map(row => [...row]);
      const newRow = new Array(headers.length || 0).fill("");
      updatedData.splice(tableProps.rowIndex, 0, newRow);
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && action == "insertColumnAfter") {

      const newHeaders = produce(headers, (draft) => {
        draft.splice(tableProps.colIndex + 1, 0, '');
      });

      const newData = produce(data, (draft) => {
        draft.forEach(row => row.splice(tableProps.colIndex + 1, 0, ""));
      });

      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, headers: newHeaders, data: newData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && action == "insertColumnBefore") {

      const newHeaders = produce(headers, (draft) => {
        draft.splice(tableProps.colIndex, 0, '');
      });

      const newData = produce(data, (draft) => {
        draft.forEach(row => row.splice(tableProps.colIndex, 0, ""));
      });

      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, headers: newHeaders, data: newData } };
      dispatch(updateTemplateLayout({ data: preTableData }));
    } else if (tableProps && action == "deleteColumn") {

      const updatedHeaders = headers.filter((_, index) => index !== tableProps.colIndex);
      const updatedData = data.map((row) => row.filter((_, index) => index !== tableProps.colIndex));
      preTableData = {
        ...preTableData,
        tableData: {
          headers: updatedHeaders,
          data: updatedData
        }
      };

      dispatch(updateTemplateLayout({ data: preTableData }));
    } else if (tableProps && action == "deleteRow") {
      const updatedData = produce(data, (draft) => {
        draft.splice(tableProps.rowIndex, 1); // Remove row at index
      });
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));
    }
    setPopoverOpen(false);
  };


  const selectElement = (element) => {
    dispatch(setSelectedElement(element));
    dispatch(setMainElement(element));
    if (
      element.type === "heading1"
      || element.type === "heading2"
      || element.type === "paragraph"
      || element.type === "normalbutton"
      || element.type === "roundedbutton"
      || element.type === "ovalbutton"
    ) {
      dispatch(setStyleElement(element));
    }
  }


  const handleDragStart = (event, element, index) => {
    event.dataTransfer.setData('component', JSON.stringify({ elementDetils: element, dragType: "listDragDrop", fromIndex: index }));

    // event.dataTransfer.effectAllowed = "move";
    // setPrevY(event.clientY);
    // document.addEventListener("dragover", handleGlobalDrag);

  };

  // const [prevY, setPrevY] = useState(null);
  // const [dragDirection, setDragDirection] = useState(null);
  // console.log(dragDirection, "dragDirection");

  // const handleGlobalDrag = (event) => {
  //   event.preventDefault();

  //   const currentY = event.clientY;

  //   if (prevY !== null) {
  //     if (currentY < prevY) {
  //       setDragDirection("up");
  //     } else if (currentY > prevY) {
  //       setDragDirection("down");
  //     }
  //   }

  //   setPrevY(currentY);
  // };

  const handleDragOverFunc = (event, index) => {
    event.preventDefault(); // ✅ Required for drop to work!
    event.dataTransfer.dropEffect = "move";
    setGetIndex(index);
  };

  // const handleListDrop = (event, index) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  // };

  // const handleDragEnd = (index) => {
  //   console.log(index, "index on dragEnd")
  //   const updatedArr = [...templateLayoutData];
  //   const [movedItem] = updatedArr.splice(index, 1);
  //   updatedArr.splice(getIndex, 0, movedItem);
  //   dispatch(setLayoutData(updatedArr));
  //   setGetIndex(null);
  // };

  // useEffect(() => {
  //   const handleDragOver = (event) => {
  //     event.preventDefault(); // This allows dropping
  //     console.log("Dragging over globally");
  //   };

  //   // Attach event listener to the document
  //   document.addEventListener("dragover", handleDragOver);

  //   // Cleanup on unmount
  //   return () => {
  //     document.removeEventListener("dragover", handleDragOver);
  //   };
  // }, []);



  return (
    <>
      <div
        className="canvasLayout col-9 position-relative border"
        style={parentCompStyle}
        id="export_canvas_data"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {(styleElement && !templateLoading) && <ToolBarComponent />}
        <div
          className="child_comp"
          style={childCompStyle}
        >

          {templateLayoutData.length > 0 ? (
            templateLayoutData.map((element, index) => (
              <>
                {index === getIndex && <DropZoneComp />}
                <div
                  key={element.id}
                  className={`position-relative dragClass ${mainElement?.id === element.id ? "selectedElement" : "layoutElement"}`}
                  onClick={() => { selectElement(element) }}
                  style={element?.containerStyle || {}}
                  draggable="true"
                  onDragStart={(event) => handleDragStart(event, element, index)}
                  onDragOver={(event) => handleDragOverFunc(event, index)}
                >
                  <LayoutComponentfrom elemContent={element} viewType={'desktop'} isEdit={true} />

                  {(!templateLoading && mainElement?.id === element.id) ? (
                    <>
                      <span
                        className="elementController"
                        style={{ position: "absolute", top: "-1px", left: "-37px", width: "35px" }}
                        // onMouseDown={(e) => e.stopPropagation()}
                        onDragStart={(event) => handleDragStart(event, element, index)}
                      >
                        <LuMove style={{ fontSize: "16px", color: "#fff" }} />
                      </span>
                      <div className="d-flex flex-column justify-content-center" style={{
                        position: "absolute",
                        top: "-1px",
                        right: "-37px",
                        zIndex: 0,
                        backgroundColor: "#008FE2",
                        width: "35px",
                      }}>
                        {(tableProps && element.type === "basictable") && <>
                          <span
                            ref={popoverRef}
                            className="elementController"
                            style={{ borderBottom: "1px solid #ffffff" }}
                            onClick={(e) => { handleContextMenu(e) }}
                          >
                            <TbTableOptions style={{ fontSize: "16px", color: "#fff" }} />
                          </span>
                          {/* <span className="elementController" style={{ borderBottom: "1px solid #ffffff" }} onClick={() => { }}>
                            <RxGear style={{ fontSize: "16px", color: "#fff" }} />
                          </span> */}
                        </>}
                        <span className="elementController" style={{ borderBottom: "1px solid #ffffff" }} onClick={() => copyElement(element.id)}>
                          <PiCopySimpleThin style={{ fontSize: "16px", color: "#fff" }} />
                        </span>
                        <span className="elementController" onClick={() => deleteElement(element)}>
                          <MdOutlineDelete style={{ fontSize: "18px", color: "#fff" }} />
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span
                        className=""
                        style={{ position: "absolute", top: "-1px", left: "-37px", width: "35px", height: "100%", backgroundColor: "transparent" }}
                      >
                      </span>
                    </>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className="p-4">
              <h4 className="addLayoutSection text-center p-4">Add layout here</h4>
            </div>
          )}
          {/* <DragListView {...dragProps}>           
          </DragListView> */}
        </div>
      </div>

      {modalOpen && <AddbtnModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        selectedBtn={selectedBtn}
        setSelectedBtn={setSelectedBtn}
        hoverIndex={getIndex}
        setGetIndex={setGetIndex}
      />}

      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={popoverRef}
        toggle={togglePopover}
      // style={{ position: "absolute", top: contextMenuPosition.top, left: contextMenuPosition.left, zIndex: 1000 }}
      >
        <PopoverBody style={{ width: "200px" }}>
          <div className="tableColSetting">
            <div className="mb-1" style={{ fontWeight: "600" }} >INSERT ROW</div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <div className="buttonStyle" onClick={() => handleAction("insertRowAbove")}>
                Above
              </div>
              <div className="buttonStyle" onClick={() => handleAction("insertRowBelow")}>
                Below
              </div>
            </div>

            <div className="mt-2 mb-1" style={{ fontWeight: "600" }}>INSERT COLUMN</div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <div className="buttonStyle" onClick={() => handleAction("insertColumnBefore")}>
                Before
              </div>
              <div className="buttonStyle" onClick={() => handleAction("insertColumnAfter")}>
                After
              </div>
            </div>

            <div className="d-flex text-danger mt-2" style={{ justifyContent: "space-evenly" }}>
              <div className="text-danger fw-bold p-0" style={{ cursor: "pointer" }} onClick={() => handleAction("deleteRow")}>
                <MdDeleteOutline /> Row
              </div>
              <div className="text-danger fw-bold p-0" style={{ cursor: "pointer" }} onClick={() => handleAction("deleteColumn")}>
                <MdDeleteOutline /> Column
              </div>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default CanvasComponent;
