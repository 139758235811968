import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link as NavLink } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { toPng } from 'html-to-image';
import { Tooltip } from 'reactstrap';
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import './TemplateCardView.css'; // Import CSS for styling
import DOMPurify from 'dompurify';
import { Hidden } from "@material-ui/core";
import PreviewModal from "./component/TemplatePreview/TemplatePreview";



const GridViewTemplateCard = ({
    item,
    index,
    access_level,
    delete_toggle,
    set_del_id,
    onChangeMultipleTemplate
}) => {

    const elementRef = useRef();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
    const [previewModal, setPreviewModal] = useState(false);

    const togglePreModal = () => { setPreviewModal(!previewModal) }

    const sanitizedHtml = DOMPurify.sanitize(item.templ_layout);
    console.log(item, "item=========")
    return (
        <>
            <div style={{ height: "300px", width: "220px", padding: "0.8rem" }}            >
                <div className='border rounded px-2 pt-2 pb-0 w-80 h-100'>
                    <div
                        className='d-flex align-items-center justify-content-center bg-light rounded w-100 position-relative'
                        style={{ height: "85%", overflow: "hidden" }}
                        onMouseEnter={toggleToolTip}
                        onMouseLeave={toggleToolTip}
                    >
                        <div className="template_test scale_template" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />

                        {tooltipOpen && <div className="tooltip-box" style={{}}>
                            <IoSearchOutline
                                className=""
                                style={{ fontSize: "17px", marginRight: "1rem", color: "#fff" }}
                                onClick={() => {
                                    togglePreModal();
                                    // const newWindow = window.open('', 'Templatelayout', 'width=800,height=600');
                                    // newWindow.document.write(item.templ_layout);
                                    // newWindow.document.close();
                                }}
                            />
                            <NavLink
                                to={`/settings/mail_template_details/${item._id}`}
                                item={item}
                                className="d-flex"
                            >
                                <MdOutlineEdit className="" style={{ fontSize: "17px", marginRight: "1rem", color: "#fff" }} />
                            </NavLink>
                            {access_level == 3 && <RiDeleteBinLine
                                style={{ fontSize: "17px", color: "#fff" }}
                                onClick={() => {
                                    delete_toggle();
                                    set_del_id(item._id);
                                }}
                            />}
                        </div>}
                    </div>
                    <div className='d-flex align-items-center w-100' style={{ minHeight: "15%" }}>
                        <input
                            name="showInPromotionList"
                            className='mr-2'
                            onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                            type="checkbox"
                            checked={item?.showInPromotionList ? true : false}
                        />
                        <span
                            style={{
                                fontSize: "13px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical"
                            }}
                        >
                            {item.templ_name}
                        </span>

                        <NavLink
                            to={`/settings/mail_template_details/${item._id}`}
                            item={item}
                            className="ml-auto"
                        >
                            <span className='ml-auto'                                                        >
                                <CiEdit style={{ height: "18px", width: "18px", color: "grey", verticalAlign: "middle" }} />
                            </span>
                        </NavLink>
                    </div>
                </div>
            </div>
            {previewModal && <PreviewModal
                isOpen={previewModal}
                toggle={togglePreModal}
                templateDetails={item}
                type={'old-template'}
            />}
        </>
    )
}

export default GridViewTemplateCard