import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    templateLayoutData: [],
    selectedElementData: null,
    tableProps: null,
    htmlData: '',
    templateData: null,
    mainElement: null,
    styleElement: null,
    parentCompStyle: {
        padding: "3.5rem 5rem",
        minHeight: "80vh",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        backgroundColor: "#F2F2F2"
    },
    childCompStyle: {
        width: "100%",
        maxWidth: "600px",
        backgroundColor: "#FFFFFF",
        minHeight: "80vh",
        margin: "0 auto"
    }
};

const appSlice = createSlice({
    name: "emailTemplateEditorData",
    initialState,
    reducers: {
        setLayoutData: (state, action) => {
            state.templateLayoutData = action.payload;
        },
        setSelectedElement: (state, action) => {
            state.selectedElementData = action.payload;
        },
        setTableProps: (state, action) => {
            state.tableProps = action.payload;
        },
        updateTemplateLayout: (state, action) => {
            const { data } = action.payload;

            const index = state.templateLayoutData.findIndex(item => item.type.toLowerCase() === data.type.toLowerCase());
            if (index !== -1) {
                state.templateLayoutData[index] = { ...state.templateLayoutData[index], ...data };
            }
        },
        setHtmlData: (state, action) => {
            state.htmlData = action.payload;
        },
        setCompStyleData: (state, action) => {
            const { type, data } = action.payload;
            if (type === 'outercolor') {
                state.parentCompStyle = data;
            }
            if (type === 'innercolor') {
                state.childCompStyle = { ...state.childCompStyle, data };
            }
        },
        setTemlpateData: (state, action) => {
            state.templateData = action.payload;
        },
        setMainElement: (state, action) => {
            state.mainElement = action.payload;
        },
        setStyleElement: (state, action) => {
            state.styleElement = action.payload;
        },
        resetState: () => initialState,

    }
});

export const {
    setLayoutData,
    setSelectedElement,
    setTableProps,
    updateTemplateLayout,
    setHtmlData,
    setCompStyleData,
    setTemlpateData,
    setMainElement,
    setStyleElement,
    resetState
} = appSlice.actions;


export default appSlice.reducer;
