import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from 'react-color';
import { v4 as uuidv4 } from "uuid";
import { setLayoutData, setSelectedElement, setCompStyleData, setMainElement, setStyleElement } from "../../../../store/templateEditorData";
import { componentData } from "./StaticData";
import AddbtnModal from './AddbtnModal';
import './EmailTemplate.scss';


export const SideBarComponent = () => {

    const dispatch = useDispatch();
    const bgColorPickerRef = useRef(null);
    const { templateLayoutData, parentCompStyle, childCompStyle } = useSelector((state) => state.emailTemplateEditorData);
    const [showColorPicker, setShowColorPicker] = useState({ enable: false, type: null });
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBtn, setSelectedBtn] = useState(null);
    const [buttonProps, setButtonProps] = useState({ btnName: "", linkType: "", btnLink: "" });
    const [getIndex, setGetIndex] = useState(null);

    const toggleModal = () => { setModalOpen(!modalOpen) };

    const handleDragStart = (event, component) => {
        let dragCompData = { ...component, id: uuidv4() };
        event.dataTransfer.setData('component', JSON.stringify({ elementDetils: dragCompData, dragType: "sidebar" }));
    };

    const handleComponentClick = (component) => {
        if (component.type === 'innercolor' || component.type === 'outercolor') {
            setShowColorPicker({ enable: true, type: component.type });
        } else if (component.type === "normalbutton" || component.type === "roundedbutton" || component.type === "ovalbutton") {
            setSelectedBtn(component);
            toggleModal()
        } else {
            let setData = { ...component, id: uuidv4() };
            dispatch(setLayoutData([...templateLayoutData, setData]));
            dispatch(setSelectedElement(setData));
            dispatch(setMainElement(setData));
            if (setData.type === "heading1" || setData.type === "heading2" || setData.type === "paragraph" || setData.toolBarRef === 'spacer') {
                dispatch(setStyleElement(setData));
            }
        }
    };

    const handleColorChange = (color, type) => {
        if (type === 'outercolor') {
            let updatedData = { ...parentCompStyle, backgroundColor: color.hex };
            dispatch(setCompStyleData({ type: "outercolor", data: updatedData }));

        } else if (type === 'innercolor') {
            let updatedData = { ...childCompStyle, backgroundColor: color.hex };
            dispatch(setCompStyleData({ type: "innercolor", data: updatedData }));
        }
        setShowColorPicker({ enable: false });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bgColorPickerRef.current && !bgColorPickerRef.current.contains(event.target)) {
                setShowColorPicker((prev) => ({ ...prev, enable: false }));
            }
        };

        if (showColorPicker.enable) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showColorPicker.enable]);

    const presetColors = [
        "#000000", "#808080", "#C0C0C0", "#FFFFFF", "#FF0000", "#800000",
        "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080",
        "#0000FF", "#000080", "#FF00FF", "#800080", "#B0C4DE", "#4682B4",
        "#D2691E", "#8B4513", "#FF4500", "#FFD700", "#32CD32", "#7FFFD4",
        "#1E90FF", "#4B0082", "#FF1493", "#DC143C", "#A52A2A", "#5b0f00",
        "#8A2BE2", "#9932CC", "#9400D3", "#BA55D3", "#9370DB", "#FF69B4",
        "#CD5C5C", "#F08080", "#FA8072", "#E9967A", "#FF7F50", "#FF6347",
        "#FFA07A", "#FFDAB9", "#EEE8AA", "#F0E68C", "#BDB76B", "#556B2F",
        "#6B8E23", "#9ACD32"
    ];

    return (
        <>
            <div className="col-3 bg-white pb-3" style={{ borderRadius: "10px" }}>
                <div className="template_editor_comp">
                    <div className="my-3 d-flex align-items-center justify-content-center w-100">
                        <h5 className='my-1' style={{ fontSize: "19px", fontWeight: "500", color: "#000000" }}>All Components</h5>
                    </div>
                    <div className='submenuleftsideBar'>
                        {componentData.map((component, index) => {
                            const { img, height, width } = component.imgData;
                            return (
                                <div
                                    key={index}
                                    draggable
                                    onDragStart={(event) => {
                                        if (component.type !== 'innercolor' || component.type !== 'outercolor') {
                                            handleDragStart(event, component)
                                        }
                                    }}
                                    className="submenu_boxComp"
                                    style={{ cursor: component.type === 'innercolor' || component.type === 'outercolor' ? "pointer" : "move" }}
                                    onClick={() => handleComponentClick(component)}
                                >
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                        <img className='text-center' src={img} style={{ height, width }} alt="main_img" />
                                        <p className="mx-0 mb-0 mt-1 text-center">{component.label}</p>
                                    </div>

                                    {showColorPicker.enable && showColorPicker.type === component.type && (
                                        <div
                                            ref={bgColorPickerRef}
                                            style={{
                                                position: "absolute",
                                                bottom: "0px",
                                                right: "-250px",
                                                zIndex: 9999,
                                                background: "#fff",
                                                padding: "0px",
                                                borderRadius: "5px",
                                                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                            }}
                                        >
                                            <SketchPicker
                                                color={component.style.backgroundColor}
                                                onChangeComplete={(color) => handleColorChange(color, component.type)}
                                                presetColors={presetColors}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {modalOpen && <AddbtnModal
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                selectedBtn={selectedBtn}
                setSelectedBtn={setSelectedBtn}
                hoverIndex={getIndex}
                setGetIndex={setGetIndex}
            />}
        </>
    );
};

