import React from 'react'

const SpacerElement = ({ elemContent }) => {
    const { style, content, id, containerStyle } = elemContent;

    return (
        <div style={containerStyle}>
            <div style={style}><span>&nbsp;</span></div>
        </div>
    )
}

export default SpacerElement