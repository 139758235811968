import React from "react";

const ButtonElements = ({ elemContent }) => {
    const { style, content = "Click Here", href = "#" } = elemContent;

    return (
        <div style={{ textAlign: style?.textAlign || "center" }}>
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    ...style,
                    display: "inline-block",
                    textDecoration: "none",
                    padding: style?.padding || "10px 20px",
                    borderRadius: style?.borderRadius || "5px",
                    transition: "0.3s ease-in-out",
                }}
                className="button-link"
            >
                {content}
            </a>
        </div>
    );
};

export default ButtonElements;
