import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { AiOutlineDesktop } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import client from "../../../../../Api/HTTPClient";
import api from "../../../../../constant/apilist";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import DOMPurify from 'dompurify';
import LayoutComponentfrom from "../LayoutComponent";
// const minify = require("html-minifier").minify;



const TemplatePreviewModal = ({ isOpen, toggle, templateDetails, type }) => {

    const { templateLayoutData, parentCompStyle, childCompStyle, templateData } = useSelector((state) => state.emailTemplateEditorData);
    const [viewType, setViewType] = useState('desktop');
    const [loadingBtn, setloadingBtn] = useState(false);
    const [isGenerate, setIsGenerate] = useState(false);
    const [htmlCodeData, setHtmlCodeData] = useState('');
    console.log(htmlCodeData, "htmlCodeData");
    let sanitizedHtml = '';

    if (type === "old-template" && templateDetails) {
        sanitizedHtml = DOMPurify.sanitize(templateDetails?.templ_layout);
    }

    const sendTestTemplateEmail = () => {

        try {

            setloadingBtn(true);

            let postData = {};

            if (type === "new-template") {

                postData.templ_layout = htmlCodeData;
                postData.subject = templateData.templ_subject;
                postData.email = templateData.templ_test_mail;

                // const newWindow = window.open('', 'Templatelayout', 'width=800,height=600');
                // newWindow.document.write(htmlCodeData);
                // newWindow.document.close();

            } else if (type === "old-template") {
                postData.templ_layout = templateDetails.templ_layout;
                postData.subject = templateDetails.templ_subject;
                postData.email = templateDetails.templ_test_mail;
            }

            client.post(api.sendTemplateTestMail, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success("Test mail sent successfully");
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                setloadingBtn(false);
            });

        } catch (error) {
            toast.error(`${error.message}`)
        }
    };

    const generateHtmlCode = async () => {
        setIsGenerate(true);

        setTimeout(() => {
            const canvas = document.getElementById("generate-canvas-data");
            const inlineStyle = canvas.getAttribute("style") || "";

            if (!canvas) {
                console.error("Canvas not found!");
                return;
            }

            const htmlContent = `
                    <!DOCTYPE html>
                    <html lang="en">
                        <head>                                       
                            <meta charset="UTF-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <title>Email Template Preview</title>
                            <style>
                                body {
                                    font-family: Arial, sans-serif;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #f5f5f5;
                                }

                                /* #generate-canvas-data {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: center;
                                }

                                .single_column {
                                    display: grid !important;
                                    grid-template-columns: 100% !important;
                                    gap: 0px;
                                }

                                .two_column {
                                    display: grid !important;
                                    grid-template-columns: 50% 50% !important;
                                    gap: 0px;
                                }

                                .three_column {
                                    display: grid !important;
                                    grid-template-columns: repeat(3, 1fr) !important;
                                    gap: 0px;
                                }

                                @media only screen and (max-width: 640px) {
                                    #generate-canvas-data {
                                        width: 100%;
                                        padding: 0;
                                    }
                                    div[style*="grid-template-columns"] {
                                        grid-template-columns: 100% !important;
                                    }
                                } */
                            </style>
                        </head>
                        <body>
                            <div id="generate-canvas-data" class="generated-content" style="${inlineStyle}">
                                ${canvas.innerHTML}
                            </div>
                        </body>
                    </html>
                `;



            // const htmlContent = `
            //                     <!DOCTYPE html>
            //                     <html>
            //                         <head>                                       
            //                             <meta charset="UTF-8">
            //                             <meta name="viewport" content="width=device-width, initial-scale=1.0">
            //                             <meta http-equiv="X-UA-Compatible" content="IE=edge">
            //                             <title>Email Template Preview</title>
            //                             <style>
            //                                 body { font-family: Arial, sans-serif; margin: 0; padding: 0px; background-color: #f5f5f5; }                                            
            //                                  #generate-canvas-data {
            //                                     display: -webkit-flex;  /* Safari */
            //                                     display: -ms-flexbox;   /* IE 10 */
            //                                     display: flex;
            //                                     align-items: start;
            //                                     justify-content: center;
            //                                    }
            //                                 .single_column{
            //                                     display:grid !important;
            //                                     grid-template-columns: 1000% !important;
            //                                     gap:0px !important;
            //                                 }
            //                                 .two_column{
            //                                     display:grid !important;
            //                                     grid-template-columns: 50% 50% !important;
            //                                     gap:0px !important;
            //                                 }
            //                                  .three_column{
            //                                     display:grid !important;
            //                                     grid-template-columns: repeat(3, 33.3%) !important;
            //                                     gap:0px !important;
            //                                 }
            //                                 @media screen and (max-width: 600px) {
            //                                     #generate-canvas-data { width: 100% !important; padding: 0px !important; }
            //                                     div[style*="grid-template-columns"] { grid-template-columns: 100% !important; }
            //                                 }
            //                             </style>
            //                         </head>
            //                         <body>
            //                             <div id="generate-canvas-data" style="${canvas.getAttribute("style")}">
            //                                 ${canvas.innerHTML}
            //                             </div>
            //                         </body>
            //                     </html>`;




            // const optimizedHTML = minify(htmlContent, {
            //     collapseWhitespace: true,
            //     removeComments: true,
            //     minifyCSS: true,
            //     minifyJS: true,
            // });

            // console.log(optimizedHTML, "optimizedHTML");

            setHtmlCodeData(htmlContent);
            setIsGenerate(false);
        }, 1000);
    }

    useEffect(() => {
        if (type && type === "new-template") {
            generateHtmlCode();
        }
    }, [type])



    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>
                <div className="d-flex align-items-center justify-content-center" style={{ width: "720px" }}>
                    <h3 style={{ margin: "0px", fontSize: "22px" }}>Template Preview</h3>
                    <Button
                        style={{ fontSize: "14px" }}
                        onClick={() => { sendTestTemplateEmail() }}
                        className="ml-auto"
                        disabled={loadingBtn ? true : false}
                    >  {loadingBtn ? <BeatLoader color={"#fff"} loading={true} size="7" /> : 'Send Test Email'}</Button>
                </div>
            </ModalHeader>
            <ModalBody style={{ padding: "0px", position: "relative" }}>

                <div
                    className="d-flex align-items-center rounded-pill p-2 bg-white shadow"
                    style={{ position: "absolute", top: "0.7rem", left: "0.7rem", zIndex: 1 }}
                >
                    <div
                        className="rounded-pill"
                        style={{ border: viewType === "desktop" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "desktop" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                        onClick={() => { setViewType('desktop') }}
                    >
                        <AiOutlineDesktop style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Desktop
                    </div>
                    <div
                        className="rounded-pill"
                        style={{ border: viewType === "mobile" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "mobile" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                        onClick={() => { setViewType('mobile') }}
                    >
                        <FaMobileAlt style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Mobile
                    </div>
                </div>

                <div className="w-100" style={{ height: viewType == "desktop" ? "70vh" : "100%", overflowY: "auto", position: "relative" }}>
                    {type === "old-template" ?
                        <>
                            {viewType == "desktop" ? <>
                                <div className="template_test " dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                            </> : <>
                                <div
                                    className="canvas_main_class d-flex align-items-center justify-content-center"
                                    style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}
                                >
                                    <div className="my-4" style={{ height: "600px", width: "390px", border: "2px solid #dde2e6", borderRadius: "1.3rem", backgroundColor: "#fff", padding: "0rem 0.8rem 1.5rem 0.8rem" }}>
                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: "0.9rem" }}>
                                            <div className="rounded-pill" style={{ border: "2px solid #dde2e6", height: "8px", width: "8px" }}></div>
                                            <div className="rounded-pill ml-1" style={{ border: "2px solid #dde2e6", height: "8px", width: "40px" }}></div>
                                        </div>
                                        <div
                                            className="canvas_main_class"
                                            style={{
                                                border: "2px solid #dde2e6",
                                                height: "93%",
                                                width: "100%",
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                                transform: 'translate(0, 0)'
                                            }}
                                        >
                                            <div className="template_test " dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>
                        : <>
                            {
                                viewType == "desktop" ?
                                    <div
                                        className="canvas_main_class"
                                        id="canvas-data"
                                        style={parentCompStyle}
                                    >
                                        <div className="canvas_child_class" style={childCompStyle} >
                                            {templateLayoutData.length > 0 ? (
                                                templateLayoutData.map((element, index) => (
                                                    <div
                                                        key={element.id}
                                                        style={element?.containerStyle || {}}
                                                    >
                                                        <LayoutComponentfrom elemContent={element} viewType={viewType} isEdit={false} />
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="p-4">
                                                    <h4
                                                        className="text-center p-4"
                                                        style={{
                                                            border: '1px solid grey',
                                                            borderStyle: 'dashed',
                                                            backgroundColor: ' #f5f5f5',
                                                            fontSize: '16px'
                                                        }}
                                                    >Add layout here</h4>
                                                </div>
                                            )}
                                        </div>
                                    </div> : <div
                                        className="canvas_main_class"
                                        // id="canvas-data"
                                        style={parentCompStyle}
                                    >
                                        <div className="" style={{ height: "600px", width: "390px", border: "2px solid #dde2e6", borderRadius: "1.3rem", backgroundColor: "#fff", padding: "0rem 0.8rem 1.5rem 0.8rem" }}>
                                            <div className="d-flex align-items-center justify-content-center" style={{ padding: "0.9rem" }}>
                                                <div className="rounded-pill" style={{ border: "2px solid #dde2e6", height: "8px", width: "8px" }}></div>
                                                <div className="rounded-pill ml-1" style={{ border: "2px solid #dde2e6", height: "8px", width: "40px" }}></div>
                                            </div>
                                            <div
                                                className="canvas_main_class"
                                                id="canvas-data"
                                                style={{ backgroundColor: parentCompStyle.backgroundColor, border: "2px solid #dde2e6", height: "93%", width: "100%", overflowY: "auto", overflowX: "hidden" }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                        backgroundColor: childCompStyle.backgroundColor
                                                    }}
                                                    className="canvas_child_class"
                                                >
                                                    {templateLayoutData.length > 0 ? (
                                                        templateLayoutData.map((element, index) => (
                                                            <div
                                                                key={element.id}
                                                                style={element?.containerStyle || {}}
                                                            >
                                                                <LayoutComponentfrom elemContent={element} viewType={viewType} isEdit={false} />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="p-4">
                                                            <h4
                                                                className="text-center p-4"
                                                                style={{
                                                                    border: '1px solid grey',
                                                                    borderStyle: 'dashed',
                                                                    backgroundColor: ' #f5f5f5',
                                                                    fontSize: '16px'
                                                                }}
                                                            >Add layout here</h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>}
                </div>

                {(type === "new-template" && isGenerate) && <div
                    className="canvas_main_class"
                    id="generate-canvas-data"
                    style={parentCompStyle}
                >
                    <div className="canvas_child_class" style={childCompStyle} >
                        {templateLayoutData.length > 0 ? (
                            templateLayoutData.map((element, index) => (
                                <div
                                    key={element.id}
                                    style={element?.containerStyle || {}}
                                >
                                    <LayoutComponentfrom elemContent={element} viewType={viewType} isEdit={false} />
                                </div>
                            ))
                        ) : (
                            <div className="p-4">
                                <h4
                                    className="text-center p-4"
                                    style={{
                                        border: '1px solid grey',
                                        borderStyle: 'dashed',
                                        backgroundColor: ' #f5f5f5',
                                        fontSize: '16px'
                                    }}
                                >Add layout here</h4>
                            </div>
                        )}
                    </div>
                </div>}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default TemplatePreviewModal;
