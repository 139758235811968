// import React, { Component, useEffect, useState } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import client from "../../../Api/HTTPClient";
// import api from "../../../constant/apilist";
// import { ToastContainer, toast } from "react-toastify";
// import { Field, FieldArray, reduxForm } from "redux-form";

// const validate = (values) => {

//     const errors = {};

//     if (!values.items || !values.items.length) {
//         errors.items = { _error: "At least one item is required." };
//     } else {
//         const itemsArrayErrors = [];
//         const names = [];

//         values.items.forEach((item, index) => {
//             if (!item || !item.title_name) {
//                 itemsArrayErrors[index] = { title_name: "This field is required" };
//             } else {
//                 if (names.includes(item.title_name)) {
//                     itemsArrayErrors[index] = { title_name: "The name is already exist." };
//                 } else {
//                     names.push(item.title_name);
//                 }
//             }
//         });
//         if (itemsArrayErrors.length) {
//             errors.items = itemsArrayErrors;
//         }
//     }
//     return errors;
// };

// const renderField = ({ input, label, type, name, meta: { touched, error } }) => (
//     <div className="w-100">
//         <input
//             {...input}
//             placeholder={label}
//             type={type}
//             name={name}
//             className='search_field w-100'
//             style={{
//                 border: "#dfe8f1 solid 1px",
//                 borderRadius: "3px",
//                 padding: "10px",
//                 fontSize: "0.75rem",
//                 outlineColor: '#dfe8f1',
//                 width: "100%",
//                 fontSize: "14px"
//             }}
//         />
//         {touched && error && <p className='ml-1 mt-1' style={{ fontSize: "12px", color: "red" }}>*{error}</p>}
//     </div>
// );

// const renderItems = ({ fields, meta: { error, submitFailed } }) => {

//     if (fields.length === 0) {
//         fields.push({ title_name: '' });
//     }

//     return (
//         <div className="w-100 main_class">
//             {fields.map((item, index) => {

//                 return (
//                     <div key={index} className="space-y-2 d-flex align-items-start w-100 mb-2">
//                         <Field
//                             name={`${item}.title_name`}
//                             type="text"
//                             component={renderField}
//                         />
//                         {
//                             index == fields.length - 1 ? <>
//                                 <button
//                                     className="btn btn-outline-success mb-0 ml-2 d-flex align-items-center justify-content-center"
//                                     onClick={() => fields.push({ title_name: '' })}
//                                     style={{ height: "40px", padding: "17px 10px" }}
//                                 >
//                                     <i id="Add New" title="Add New" className="fa fa-plus"></i>
//                                 </button>
//                             </> :
//                                 <>
//                                     <button
//                                         className="btn btn-outline-danger ml-2 mb-0 d-flex align-items-center justify-content-center"
//                                         onClick={() => fields.remove(index)}
//                                         style={{ height: "40px", padding: "17px 10px" }}
//                                     >
//                                         <i id="Remove" title="Remove title" className="fa fa-minus"></i>
//                                     </button>
//                                 </>
//                         }
//                     </div>
//                 )
//             })}
//         </div>
//     )
// };


// let AddTemplateCategoryModal = (props) => {

//     const { handleSubmit, initialize, catgoryData, isOpen, toggle, fetchCatData } = props;
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {

//         // let getData = [];

//         // if (catgoryData && catgoryData.length > 0) {
//         //     catgoryData.map((currData, index) => {
//         //         getData.push({ title_name: currData.title_name, _id: currData._id });
//         //     })
//         // }

//         initialize({
//             items: catgoryData
//         });
//     }, [catgoryData])


//     const onSubmit = (form_data) => {
//         // console.log(form_data, "form_data");
//         if (form_data) {
//             let postData = { catData: form_data.items };
//             setLoading(true);
//             client.post(api.add_template_category, postData, async function (error, response) {
//                 // console.log(response, "response");
//                 if (!error) {
//                     if (typeof response.error !== "undefined") {
//                         try {
//                             if (!response.error) {
//                                 toast.success("Added Successfully");
//                                 setLoading(false);
//                                 fetchCatData();
//                                 toggle();
//                             } else {
//                                 toast.error(response.message);
//                             }
//                         } catch (e) {
//                             toast.error("Internal error occured. Please contact support 2");
//                         }
//                     } else {
//                         toast.error("Internal error occured. Please contact support 2");
//                     }
//                 } else {
//                     toast.error("Internal error occured. Please contact support 3");
//                 }
//                 setLoading(false);
//             });

//         }
//     }


//     return (
//         <Modal
//             isOpen={isOpen}
//             toggle={toggle}
//             size="lg"
//             className="modal-body"
//             centered={true}
//             style={{ maxWidth: "500px" }}
//         >
//             <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
//                 <ModalHeader toggle={toggle}>Add category</ModalHeader>
//                 <ModalBody>
//                     <FieldArray
//                         name="items"
//                         component={renderItems}
//                     />
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="default" type="button" onClick={toggle}>Close</Button>
//                     <Button color="primary" type="submit" disabled={loading}>{loading ? "Loading..." : 'Save'}</Button>
//                     {/* {!loadingbtn ?
//                         <>
//                             <Button color="default" type="button" onClick={toggle}>Close</Button>
//                             <Button color="primary" type="submit">Save</Button>
//                         </>
//                         :
//                         <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
//                     } */}
//                 </ModalFooter>
//             </form>
//         </Modal>
//     );
// }

// // export default AddTemplateCategoryModal;
// export default reduxForm({
//     form: "addCateForm",
//     initialized: true,
//     destroyOnUnmount: true,
//     validate,
// })(AddTemplateCategoryModal);



import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { toast } from "react-toastify";
import { Field, FieldArray, reduxForm } from "redux-form";

const validate = (values) => {
    const errors = {};
    if (!values.items || !values.items.length) {
        errors.items = { _error: "At least one item is required." };
    } else {
        const itemsArrayErrors = [];
        const names = new Set();

        values.items.forEach((item, index) => {
            if (!item || !item.title_name) {
                itemsArrayErrors[index] = { title_name: "This field is required" };
            } else if (names.has(item.title_name)) {
                itemsArrayErrors[index] = { title_name: "The name already exists." };
            } else {
                names.add(item.title_name);
            }
        });
        if (itemsArrayErrors.some(err => err)) {
            errors.items = itemsArrayErrors;
        }
    }
    return errors;
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="w-100">
        <input
            {...input}
            placeholder={label}
            type={type}
            className="search_field w-100"
            style={{
                border: "#dfe8f1 solid 1px",
                borderRadius: "3px",
                padding: "10px",
                fontSize: "14px",
                outlineColor: "#dfe8f1",
                width: "100%"
            }}
        />
        {touched && error && <p className="ml-1 mt-1 text-danger" style={{ fontSize: "12px" }}>*{error}</p>}
    </div>
);

const renderItems = ({ fields, meta: { error } }) => {
    if (!fields.length) {
        fields.push({ title_name: "" });
    }
    return (
        <div className="w-100 main_class">
            {fields.map((item, index) => (
                <>
                    <div key={index} className="d-flex align-items-start w-100 mb-2">
                        <Field name={`${item}.title_name`} type="text" component={renderField} />
                        <button
                            // className={`btn ${index === fields.length - 1 ? "btn-outline-success" : "btn-outline-danger"} ml-2`}
                            className={`btn ${index !== 0 && 'btn-outline-danger'} ml-2`}
                            onClick={(e) => {
                                e.preventDefault();
                                // index === fields.length - 1 ? fields.push({ title_name: "" }) : fields.remove(index);
                                fields.remove(index);
                            }}
                            style={{ height: "40px", width: "38px", padding: "10px" }}
                        >
                            {/* <i className={`fa fa-${index === fields.length - 1 ? "plus" : "minus"}`} title={index === fields.length - 1 ? "Add New" : "Remove"}></i> */}
                            {index !== 0 && <i className={`fa fa-minus`} title={"Remove"}></i>}
                        </button>
                    </div>
                </>
            ))}
            {error && <p className="text-danger">{error}</p>}
            <button
                className={`btn btn-outline-success ml-2`}
                onClick={(e) => {
                    e.preventDefault();
                    fields.push({ title_name: "" })
                }}
                style={{ height: "40px", width: "auto", padding: "10px" }}
            >
                <i className={`fa fa-plus`} title={"Add New"}></i>&nbsp; Add
            </button>
            {/* <div className="mt-2 d-flex" onClick={() => { fields.push({ title_name: "" }) }}></div> */}
        </div >
    );
};

const AddTemplateCategoryModal = ({ handleSubmit, initialize, catgoryData, isOpen, toggle, fetchCatData, categoryDataId }) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initialize({ items: catgoryData || [{ title_name: "" }] });
    }, [catgoryData, initialize]);


    const onSubmit = (form_data) => {
        console.log(form_data, "form_data");
        if (form_data) {
            let postData = {
                updateId: categoryDataId,
                catData: form_data.items
            };
            setLoading(true);
            client.post(api.add_template_category, postData, async function (error, response) {
                console.log(response, "response");
                if (!error) {
                    if (!response.error) {
                        setTimeout(() => {
                            toast.success("Added Successfully");
                            setLoading(false);
                            fetchCatData();
                            toggle();
                        }, 1000);

                    } else {
                        toast.error(response.message);
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                setLoading(false);
            });

        }
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            centered
            className="modal-custom"
            style={{ maxWidth: "500px" }}
        >
            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}>Add Category</ModalHeader>
                <ModalBody>
                    <FieldArray name="items" component={renderItems} />
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={toggle} disabled={loading}>Close</Button>
                    <Button color="primary" type="submit" disabled={loading}>{loading ? "Loading..." : "Save"}</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default reduxForm({
    form: "addCateForm",
    validate,
    destroyOnUnmount: true,
})(AddTemplateCategoryModal);
