import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";


const validate = (values) => {
    const errors = {};

    if (!values.mailUniqueId) {
        errors.mailUniqueId = "Required!";
    } else if (!/^\d{6}$/.test(values.mailUniqueId)) {
        errors.mailUniqueId = "Only 6 digits allowed!";
    } else if (values.prevTemplates?.some(template => template.templ_unique_id === values.mailUniqueId)) {
        errors.mailUniqueId = "This unique ID already exists!";
    }

    if (!values.categories) {
        errors.categories = "Category is required!";
    }

    if (!values.templ_name) {
        errors.templ_name = "Name is required!";
    }

    return errors;
};

const renderInput = ({ input, label, type, placeholder, meta: { touched, error } }) => (
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className="form-control"
                style={{
                    border: '1px solid #ced4da',
                    outline: "none",
                    boxShadow: "none"
                }}
            />
            {touched && error && <small className="text-danger" style={{ fontSize: "12px" }}>{error}</small>}
        </div>
    </div>
);

const renderSelectField = ({ input, label, options, meta: { touched, error } }) => (
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <Select
                {...input}
                options={options}
                onChange={(option) => input.onChange(option)}
                onBlur={() => input.onBlur(input.value)}
                isClearable
            />
            {touched && error && <small className="text-danger" style={{ fontSize: "12px" }}>{error}</small>}
        </div>
    </div>
);

const CloneTemplateModal = ({ initialize, handleSubmit, isOpen, toggle, reset, template_data, cloneTemplate, setPageNo, refreshPage }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [catgoryData, setCategoryData] = useState([]);
    const [templateLoading, setTemplateLoading] = useState(false);

    const categoryOptions = catgoryData.length > 0 && catgoryData.map(({ _id, title_name }) => ({ value: _id, label: title_name }));

    useEffect(() => {
        initialize({
            mailUniqueId: "",
            templ_name: "",
            templ_subject: "",
            templ_test_mail: "",
            templ_comments: "",
            prevTemplates: template_data,
            categories: null,
        });
    }, [initialize, template_data]);


    const fetchCatData = async () => {
        let postdata = { auto_reminder: false };
        client.post(api.fetch_template_category, postdata, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    setCategoryData(response.result);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    useEffect(() => {
        fetchCatData();
    }, [])


    const onSubmit = (values) => {
        setTemplateLoading(true);
        const postData = {
            ...cloneTemplate,
            templ_unique_id: values.mailUniqueId,
            template_category_ref: values.categories,
            templ_name: values.templ_name,
            templ_test_mail: cloneTemplate.templ_test_mail,
            templ_subject: cloneTemplate.templ_subject,
            templ_comments: cloneTemplate.templ_comments,
            auto_reminder: false,
            templ_user_type: '',
            templ_from: '',
        };

        console.log(postData, "Clone template postdata");
        client.post(api.add_new_mail_template, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            setTemplateLoading(false);
                            refreshPage();
                            toggle();
                            setPageNo(1);
                        } else {
                            toast.error(response.message);
                            setTemplateLoading(false);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setTemplateLoading(false);
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setTemplateLoading(false);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setTemplateLoading(false);
            }

        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <form className="theme-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}>Clone Template</ModalHeader>
                <ModalBody>
                    <Field name="mailUniqueId" type="text" component={renderInput} placeholder="Unique ID" label="Unique ID" />
                    <Field name="templ_name" type="text" component={renderInput} placeholder="Template Name" label="Name" />
                    <Field name="categories" component={renderSelectField} label="Category" options={categoryOptions} />
                    {/* <Field name="templ_test_mail" type="email" component={renderInput} placeholder="Test Mail" label="Test Mail" />
                    <Field name="templ_subject" type="text" component={renderInput} placeholder="Subject" label="Subject" />
                    <Field name="templ_comments" type="textarea" component={renderInput} placeholder="Comments" label="Comments" /> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="primary" type="submit" disabled={templateLoading}>{templateLoading ? "Cloning..." : 'Clone'}</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default reduxForm({
    form: "cloneTemplateModal",
    validate
})(CloneTemplateModal);
