import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './subscriptionCategory.scss'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import DragVerticalIcon from "mdi-react/DragVerticalIcon";
import Switch from "react-switch";
import Icon from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";

const RenderSwitch=({checked,tagName,item_id,updateFunc})=>{
    // console.log(checked,tagName,item_id,updateFunc)
    return(<div className="d-flex justify-content-center">
        <Switch
                                                                                                                                
                                                                                                                                checked={checked}
                                                                                                                                className="react-switch mx-1 "
                                                                                                                                name={`${tagName}_${item_id}`}
                                                                    // id={`${item._id}_featureId`}
                                                                    
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onChange={updateFunc}
                                                                                                                                height={20}
                                                                                                                                width={40}
                                                                                                                            /></div>
    )
}

const AddSubsCategory = (props) => {

    let { setCatLoading,result,categoryItem,fetchSubscriptionCategory,forceReloadFunc,subCatResult,update_subscriprion_cat } = props;
    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    let [selectPlans, setSelectPlans] = useState([]);

    const { handleSubmit, register, errors,reset } = useForm();

    useEffect(()=>{
        if(categoryItem)
        {
            // setSelectPlans()
            let plans=[]
            result.forEach(item=>item?.subscription_category?.forEach(elem=>{

                if(elem?.id==categoryItem?.cat_Id && elem?.enable)
                {
                    // console.log(item)
                     plans.push(item?._id)
                    //  console.log(plans)
                }
                
            }))
            setSelectPlans(plans)
            reset({
                title:categoryItem?.subCatTitle
            })


        }
    },[categoryItem,result])

    console.log(subCatResult)

    useEffect(()=>{

        if(!categoryItem)
        {
            setSelectPlans([])

        }
        
    },[props.isOpen])

    const handleChange = (event) => {
        // console.log(event)
        setSelectPlans(
          // On autofill we get a stringified value.
          typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
        );
      };

    const onSubmit = (form_data) => {

        if (form_data !== '') {

           
            
            // console.log(currnSubCat.subscription_category, "current upadte data")
            // let postData = { subscriptionData: currnSubCat, updateType: 2 }
            // update_subscriprion_cat(postData);
            if(selectPlans?.length>0)
            {
                form_data.select_plans=selectPlans

            }
            if(categoryItem)
            {
                form_data.categoryId=categoryItem._id
                form_data.cat_Id=categoryItem.cat_Id
            }


            setloadingbtn(true);
            setCatLoading(true);
            // console.log(form_data)

            client.post(api.addSubscriptionCategory, form_data, async function (error, response) {

                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {

                                toast.success(response.message);
                                props.setSubCatResult(response.result);
                                if(form_data.modalClose!==false)
                                {
                                    props.toggleModal();

                                }
                                setCatLoading(false);
                                fetchSubscriptionCategory()
                                forceReloadFunc()
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {

                    toast.error("Internal error occured. Please contact support");
                }

                setloadingbtn(false);
                setCatLoading(false);

            });
        }
        else {
            setshowerrorstatus(true);

            errors.showMessages();
        }
    }

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: '200px',
            // width: 250,
          },
        },
      };

    return (

        <Modal
            isOpen={props.isOpen}
            toggle={props.toggleModal}
            className="modal-body"
            centered={true}
            style={{maxWidth:'80vw'}}
        >
                <ModalBody style={{maxHeight:'80vh',overflowY:'auto'}}>

                    <div className="row">
                        <div className="col-md-10">
                        <div className="table-responsive" >
                        <table id="table-subscription" className="table subs_table" >
                        <thead style={{borderBottom:'none'}}>
                        <th>Subscription Plan</th>
                        {
                       subCatResult?.map((elem,i)=>{
                                return(
                                    <th style={{border:'none'}}>
                            <p style={{
                                                        background: `white`,
                                                        color: `black`,
                                                        margin: '0px',
                                                        display:'flex',
                                                        alignItems:'center',
                                                        gap:'10px',
                                                        padding: '2px ',
                                                        borderRadius:'6px',
                                                        textTransform:'capitalize',
                                                        border:'1px solid rgb(170, 170, 170)',
                                                        
                            
                                                        paddingLeft:'10px',
                                                        paddingRight:'10px',
                                                    }}
                                                    >
                                                        <input defaultValue={elem?.subCatTitle} className="flex-grow-1" style={{padding: '5px 10px',border:'none'}}
                                                                                                                onBlur={(event) => {
                                                        
                                                                                                                    let getValue = event.target.value;
                                                                                                                    if (getValue.length == 0) {
                                                                                                                        toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                                                                                    } else if (getValue.length > 0 && getValue != elem.subCatTitle) {
                                                                                                                        let postData = { ...elem, subCatTitle: event.target.value, updateType: 1 }
                                                                                                                        update_subscriprion_cat(postData);
                                                                                                                    }
                                                                                                                }}
                                                        />
                                                        <Icon path={mdiTrashCanOutline} color={'red'} size={0.6} onClick={e=>{
                                e.stopPropagation()
                                let postData = { ...elem, updateType: 3 }
                                update_subscriprion_cat(postData);
                                                                // setDeleteCategory(true);
                            }}/>
                                                     </p>
                                                     </th>
                                )
                            })
                            
                            
                        }
                        </thead>
                            <tbody>
                                {
                                    result?.map(item=>{
                                        // console.log(item)
                                        return(
                                            <tr>
                                                <td className="" style={{fontSize:'14px'}}>
                                                    <div className="py-1 d-flex align-center">
                                                    <div className=''>
                                                                                        <DragVerticalIcon />
                                                                                    </div>
                                                                                    <span>
                                                    {item?.title}
                                                    </span>
                                                    </div>
                                                </td>
                                                {
                                                    subCatResult?.map(elem=>{
                                                        console.log(elem)

                                                        return(
                                                            <td>
                                                            <RenderSwitch
                                    checked={item?.subscription_category?.some(item2=>{
                                        return item2?.id==elem?.cat_Id && item2?.enable
                                        
                                    })}
                                    // tagName={'loyalty_cards'}
                                    item_id={item?._id+elem?.id}
                                    updateFunc={(checked) => {


                                        item.subscription_category=item.subscription_category.filter(row=>row.id!==elem.cat_Id)
                                        item.subscription_category.push({
                                            id:elem.cat_Id,
                                            enable:checked,
                                            catName:elem?.subCatTitle

                                        })
                                        // console.log(currnSubCat.subscription_category, "current upadte data")
                                        let postData = { subscriptionData: item, updateType: 2 }
                                        update_subscriprion_cat(postData);
                                    }}
                                    />
                                    </td>
                                                        )
                                                    })
                                                }

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            </table>
                            </div>
                           
                        </div>
                        <div className="col-md-2 mt-3">
                        <button className="btnCashback custBtnAdmin" onClick={() => {
                        onSubmit({title:'test',modalClose:false})
                    }

                    } ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Category</button>
                        </div>
                    </div>

                </ModalBody>
               
        </Modal>
    );
}

export default AddSubsCategory;