import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutData } from "../../../../../store/templateEditorData";

const TextElement = ({ elemContent, isEdit }) => {
    const { style, content, id } = elemContent;
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const templateLayoutData = useSelector((state) => state.emailTemplateEditorData.templateLayoutData);

    const handleInput = () => {
        const newText = textRef.current?.textContent.trim() || "";

        if (newText !== content) {
            const updatedLayout = templateLayoutData.map((item) =>
                item.id === id ? { ...item, content: newText } : item
            );

            dispatch(setLayoutData(updatedLayout));
        }
    };

    return (
        <div
            ref={textRef}
            contentEditable={isEdit}
            suppressContentEditableWarning={true}
            onBlur={handleInput}
            style={style}
        >
            {content || "Enter text here..."}
        </div>
    );
};

export default TextElement;
