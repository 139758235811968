import React from "react";
import "./DropZone.scss";

const DropZone = () => {
    return (
        <div className="drop-container">
            <div className="drop-zone">
                Drop it here
            </div>
        </div>
    );
};

export default DropZone;
