import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";

const validate = (values) => {
    const errors = {};

    if (!values.mailUniqueId) {
        errors.mailUniqueId = "Required!";
    } else if (!/^\d{6}$/.test(values.mailUniqueId)) {
        errors.mailUniqueId = "Only 6 digits allowed!";
    } else if (values.prevTemplates?.some(template => template.templ_unique_id === values.mailUniqueId)) {
        errors.mailUniqueId = "This unique ID already exists!";
    }

    if (!values.categories) {
        errors.categories = "Category is required!";
    }

    if (!values.templ_name) {
        errors.templ_name = "Name is required!";
    }

    if (!values.templ_test_mail) {
        errors.templ_test_mail = "Required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.templ_test_mail)) {
        errors.templ_test_mail = "Invalid email format!";
    }

    if (!values.templ_subject) {
        errors.templ_subject = "Subject is required!";
    }

    return errors;
};

const renderInput = ({ input, label, type, placeholder, meta: { touched, error } }) => (
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className="form-control"
                style={{
                    border: '1px solid #ced4da',
                    outline: "none",
                    boxShadow: "none"
                }}
            />
            {touched && error && <small className="text-danger" style={{ fontSize: "12px" }}>{error}</small>}
        </div>
    </div>
);

const renderSelectField = ({ input, label, options, meta: { touched, error } }) => (
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">{label}</label>
        <div className="col-sm-9">
            <Select
                {...input}
                options={options}
                onChange={(option) => input.onChange(option)}
                onBlur={() => input.onBlur(input.value)}
                isClearable
            />
            {touched && error && <small className="text-danger" style={{ fontSize: "12px" }}>{error}</small>}
        </div>
    </div>
);

const AddTemplateModal = ({ initialize, handleSubmit, isOpen, toggle, reset, template_data, catgoryData }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        initialize({
            mailUniqueId: "",
            templ_name: "",
            templ_subject: "",
            templ_test_mail: "",
            templ_comments: "",
            prevTemplates: template_data,
            categories: null,
        });
    }, [initialize, template_data]);

    const categoryOptions = catgoryData.map(({ _id, title_name }) => ({ value: _id, label: title_name }));

    const onSubmit = (values) => {
        console.log("Submitted Values:", values);
        const data = {
            templ_name: values.templ_name,
            templ_user_type: '',
            templ_test_mail: values.templ_test_mail,
            templ_subject: values.templ_subject,
            templ_from: '',
            templ_comments: values.templ_comments,
            template_category_ref: values.categories,
            templ_unique_id: values.mailUniqueId,
            auto_reminder: false,

            //  templ_name: templ_name,
            // templ_user_type: templ_user_type,
            // templ_test_mail: templ_test_mail,
            // templ_from: templ_from,
            // templ_subject: templ_subject,
            // templ_layout: '',
            // templ_comments: templ_comments,
            // templ_unique_id: mailUniqueId
        };
        history.push('/settings/add_mail_template', { state: data });
        reset();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <form className="theme-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={toggle}>Add New Template</ModalHeader>
                <ModalBody>
                    <Field name="mailUniqueId" type="text" component={renderInput} placeholder="Unique ID" label="Unique ID" />
                    <Field name="categories" component={renderSelectField} label="Category" options={categoryOptions} />
                    <Field name="templ_name" type="text" component={renderInput} placeholder="Template Name" label="Name" />
                    <Field name="templ_test_mail" type="email" component={renderInput} placeholder="Test Mail" label="Test Mail" />
                    <Field name="templ_subject" type="text" component={renderInput} placeholder="Subject" label="Subject" />
                    <Field name="templ_comments" type="textarea" component={renderInput} placeholder="Comments" label="Comments" />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Add</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default reduxForm({
    form: "addNewTemplateModal",
    validate
})(AddTemplateModal);
