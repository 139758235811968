import React, { useEffect, useState } from 'react';
import './SpeedMail.scss';
import { FaRegStar } from "react-icons/fa6";
import { MdOutlineEdit, MdOutlineContentCopy } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FaList, FaStar } from "react-icons/fa";
import { AiOutlineDesktop } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import LayoutComponent from "./component/LayoutComponent";
import { IoMdClose } from "react-icons/io";
import { setLayoutData, setCompStyleData, setTemlpateData, resetState } from "../../../store/templateEditorData";
import CloneTemplateModal from "./component/CloneTemplateModal";


const SpeedMailSecPage = ({ template_data, activeTableRow, setActiveTableRow, setPageNo, refreshPage }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { templateLayoutData, parentCompStyle, templateData, childCompStyle } = useSelector((state) => state.emailTemplateEditorData);

    const [checked, setChecked] = useState(false);
    const [selcectedItems, setselcectedItems] = useState([]);
    // const [activeTableRow, setActiveTableRow] = useState(null);
    const [viewType, setViewType] = useState('desktop');
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        if (template_data && template_data.length > 0) {
            let data = template_data.find((findData) => findData._id === activeTableRow);
            console.log(data, "data");

            setSelectedData(data);
        }
    }, [activeTableRow])
    console.log(activeTableRow, "activeTableRow");
    console.log(selectedData, "selectedData");



    const updateTemplate = (templateDetails) => {

        // let postData = {
        //     template_id: templateDetails._id,
        //     templ_layout: templateDetails.templ_layout,
        //     rawLayoutData: templateDetails.rawLayoutData,
        //     isFavourite: !templateDetails.isFavourite,
        //     lastModifiedBy: userData.full_name,
        // };

        // console.log(postData, "postData");

        // client.post(api.update_mail_template_layout, postData, async function (error, response) {
        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {
        //                     toast.success("Updated Successfully");
        //                     refreshPage();
        //                 } else {
        //                     toast.error(response.message);
        //                 }
        //             } catch (e) {
        //                 toast.error("Internal error occured. Please contact support");
        //             }
        //         } else {
        //             toast.error("Internal error occured. Please contact support");
        //         }
        //     } else {
        //         toast.error("Internal error occured. Please contact support");
        //     }
        //     //   setTemplateLoading(false);
        // });
    }

    const [copyModal, setCopyModal] = useState(false);

    const toggleTemplateCopy = () => setCopyModal(!copyModal);



    return (
        <>
            <div className='row mx-0 my-3 w-100 previewWithClone' style={{ backgroundColor: "#f1f1f1", padding: "1rem" }}>
                <div className="col-4 bg-white border px-0" style={{ borderTopLeftRadius: "0.7rem", borderBottomLeftRadius: "0.7rem" }}>
                    <h2 className='mb-0' style={{ padding: "0.9rem", fontSize: "1.5rem", borderBottom: "1px solid #dee2e6" }}>Templates</h2>
                    <div className="d-flex align-items-center" style={{ padding: "0.9rem", gap: "10px", borderBottom: "1px solid #dee2e6" }}>
                        <input
                            type="checkbox"
                            name={'select-all'}
                            style={{ height: "15px", width: "15px", border: "1px solid #C0C6CC" }}
                            checked={checked}
                            onChange={(e) => {
                                let isChecked = e.target.checked;
                                setChecked(isChecked)
                                // setchecking(true)
                                //checkAll(isChecked)
                            }}
                        /> <span style={{ fontSize: "15px" }}>Template Name</span>
                    </div>
                    <div>
                        {template_data && template_data.length > 0 && template_data.map((item) => {
                            return (
                                <div
                                    className={`d-flex align-items-center justify-content-between activeClass `}
                                    onClick={() => {
                                        setActiveTableRow(item);
                                        dispatch(setLayoutData(item.rawLayoutData.templateLayoutData));
                                        dispatch(setCompStyleData({ type: "outercolor", data: item.rawLayoutData.parentCompStyle }));
                                        dispatch(setCompStyleData({ type: "innercolor", data: item.rawLayoutData.childCompStyle }));
                                    }}
                                    style={{ cursor: "pointer", padding: "0.9rem", borderBottom: "1px solid #dee2e6", backgroundColor: item._id === activeTableRow?._id ? "#f5f5f5" : "#fff" }}
                                >
                                    <div className='d-flex' style={{ gap: "10px" }}>
                                        <div className='mt-1 d-flex align-items-start' style={{ gap: "7px" }}>
                                            {activeTableRow?._id == item._id || selcectedItems.includes(`${item._id}`) ?
                                                <>
                                                    {/* <Field
                                                    name={`${item._id}-checkitem`}
                                                    component={renderCheckbox}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        setselcectedItems((prevData) => {
                                                            if (e.target.checked) {
                                                                return [...prevData, item._id];
                                                            } else {
                                                                return prevData.filter((currFil) => currFil !== item._id);
                                                            }
                                                        });
                                                    }}
                                                /> */}
                                                    <input
                                                        type="checkbox"
                                                        name={'select-all'}
                                                        className='mt-1'
                                                        style={{ height: "15px", width: "15px", border: "1px solid #C0C6CC" }}
                                                        checked={false}
                                                        onChange={(e) => {
                                                            let isChecked = e.target.checked;
                                                            // setChecked(isChecked)
                                                            // setchecking(true)
                                                            //checkAll(isChecked)
                                                        }}
                                                    />

                                                </> :
                                                <span style={{ display: "inline-block", width: "15px", height: "15px" }}></span>
                                            }
                                            <span
                                                style={{ marginTop: "-3px" }}
                                                onClick={() => { updateTemplate(item) }}
                                            >
                                                {
                                                    item?.isFavourite ?
                                                        <FaStar style={{ fontSize: "17px" }} color='#FAA81A' />
                                                        : <FaRegStar style={{ fontSize: "17px" }} color='#DCDCDC' />
                                                }
                                            </span>
                                        </div>
                                        <div>
                                            <p className='mb-0 templateNameStyle' style={{ fontSize: "15px" }}>{item.templ_name}</p>
                                            <div className='d-flex align-items-center' style={{ gap: "4px" }}>
                                                <p
                                                    className='text-capitalize mb-0'
                                                    style={{ color: "#327ebc", fontWeight: "600" }}
                                                // onClick={() => { setPageNo(2) }}
                                                >{item?.template_category_ref?.label}</p>
                                                {/* <p className='mb-0' style={{ color: "#8b9ab9" }}>{item.templ_subject}</p> */}
                                                <span style={{ fontWeight: "800", color: "#8b9ab9" }}>.</span>
                                                <p className='mb-0' style={{ color: "#8b9ab9" }}>{item.templ_subject}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="col-8 bg-white border px-0 h-100 w-100" style={{ borderTopRightRadius: "0.7rem", borderBottomRightRadius: "0.7rem" }}>
                    <div className='position-relative' style={{ padding: '1rem' }}>
                        <p className='mb-0 templateNameStyle' style={{ fontSize: "15px", fontWeight: "600" }}>{activeTableRow?.templ_name}</p>
                        <p className='mb-0' style={{ color: "#8b9ab9" }}>{activeTableRow?.templ_subject}</p>
                        <div style={{ position: "absolute", top: "15px", right: "15px", cursor: "pointer" }} onClick={() => { setPageNo(1) }}>
                            <IoMdClose style={{ fontSize: "24px", color: "#000000" }} />
                        </div>
                    </div>

                    <div className='position-relative h-100 w-100'>
                        <div
                            className="d-flex align-items-center rounded-pill p-2 bg-white shadow"
                            style={{ position: "absolute", top: "0.7rem", left: "0.7rem", zIndex: 1 }}
                        >
                            <div
                                className="rounded-pill"
                                style={{ border: viewType === "desktop" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "desktop" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                                onClick={() => { setViewType('desktop') }}
                            >
                                <AiOutlineDesktop style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Desktop
                            </div>
                            <div
                                className="rounded-pill"
                                style={{ border: viewType === "mobile" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "mobile" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                                onClick={() => { setViewType('mobile') }}
                            >
                                <FaMobileAlt style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Mobile
                            </div>
                        </div>
                        <div style={{ position: "absolute", top: "5rem", right: "1rem", zIndex: 1 }}>
                            <div
                                className='d-flex align-items-center justify-content-center mb-2'
                                style={{ height: "40px", width: "40px", backgroundColor: "#434D5F", cursor: "pointer", borderRadius: "50%" }}
                                onClick={() => { history.push(`/settings/update_template_details/${activeTableRow?._id}`) }}
                            ><MdOutlineEdit style={{ fontSize: "20px", color: "#fff" }} /></div>
                            <div
                                className='d-flex align-items-center justify-content-center mb-2'
                                style={{ height: "40px", width: "40px", backgroundColor: "#434D5F", cursor: "pointer", borderRadius: "50%" }}
                                onClick={() => { toggleTemplateCopy() }}
                            ><MdOutlineContentCopy style={{ fontSize: "20px", color: "#fff" }} /></div>

                        </div>
                        {
                            viewType == "desktop" ?
                                <div
                                    className="canvas_main_class"
                                    id="canvas-data"
                                    style={parentCompStyle}
                                >
                                    <div className="canvas_child_class" style={childCompStyle} >
                                        {templateLayoutData.length > 0 ? (
                                            templateLayoutData.map((element, index) => (
                                                <div key={element.id} style={element?.containerStyle || {}} >
                                                    <LayoutComponent elemContent={element} viewType={viewType} isEdit={false} />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="p-4">
                                                <h4
                                                    className="text-center p-4"
                                                    style={{
                                                        border: '1px solid grey',
                                                        borderStyle: 'dashed',
                                                        backgroundColor: ' #f5f5f5',
                                                        fontSize: '16px'
                                                    }}
                                                >Add layout here</h4>
                                            </div>
                                        )}
                                    </div>
                                </div> : <div
                                    className="canvas_main_class"
                                    style={parentCompStyle}
                                >
                                    <div className="" style={{ height: "600px", width: "390px", border: "2px solid #dde2e6", borderRadius: "1.3rem", backgroundColor: "#fff", padding: "0rem 0.8rem 1.5rem 0.8rem" }}>
                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: "0.9rem" }}>
                                            <div className="rounded-pill" style={{ border: "2px solid #dde2e6", height: "8px", width: "8px" }}></div>
                                            <div className="rounded-pill ml-1" style={{ border: "2px solid #dde2e6", height: "8px", width: "40px" }}></div>
                                        </div>
                                        <div
                                            className="canvas_main_class"
                                            id="canvas-data"
                                            style={{ backgroundColor: parentCompStyle.backgroundColor, border: "2px solid #dde2e6", height: "93%", width: "100%", overflowY: "auto", overflowX: "hidden" }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    backgroundColor: childCompStyle.backgroundColor
                                                }}
                                                className="canvas_child_class"
                                            >
                                                {templateLayoutData.length > 0 ? (
                                                    templateLayoutData.map((element, index) => (
                                                        <div key={element.id} style={element?.containerStyle || {}} >
                                                            <LayoutComponent elemContent={element} viewType={viewType} isEdit={false} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="p-4">
                                                        <h4
                                                            className="text-center p-4"
                                                            style={{
                                                                border: '1px solid grey',
                                                                borderStyle: 'dashed',
                                                                backgroundColor: ' #f5f5f5',
                                                                fontSize: '16px'
                                                            }}
                                                        >Add layout here</h4>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {copyModal && <CloneTemplateModal
                isOpen={copyModal}
                toggle={toggleTemplateCopy}
                template_data={template_data}
                cloneTemplate={activeTableRow}
                setPageNo={setPageNo}
                refreshPage={refreshPage}
            />}
        </>
    )
}

export default SpeedMailSecPage